import { extendTheme } from '@chakra-ui/react'
import { breakpoints } from './breakpoints'
import { sizes } from './sizes'
import { semanticColors } from './colors'
import { globalStyles } from './globalStyles'
import { textStyles } from './components/Text.theme'
import { Container } from './components/Container.theme'
import { Button } from './components/Button.theme'
import { Input } from './components/Input.theme'
import { Textarea } from './components/Textarea.theme'
import { FormLabel } from './components/FormLabel.theme'
import { FormControl } from './components/FormControl.theme'
import { FormError } from './components/FormError.theme'
import { Menu } from './components/Menu.theme'
import { Drawer } from './components/Drawer.theme'
import { Checkbox } from './components/Checkbox.theme'
import { Link } from './components/Link.theme'
import { Slider } from './components/Slider.theme'
import { Divider } from './components/Divider.theme'
import { Modal } from './components/Modal.theme'

export const theme = extendTheme({
  breakpoints,
  sizes,
  fonts: {
    heading: 'Helvetica, sans-serif',
    body: 'Inter, sans-serif',
  },
  components: {
    Container,
    Button,
    Input,
    Textarea,
    FormLabel,
    Form: FormControl,
    FormError,
    Drawer,
    Menu,
    Checkbox,
    Link,
    Slider,
    Divider,
    Modal,
  },
  styles: {
    global: globalStyles,
  },
  semanticTokens: {
    colors: semanticColors,
  },
  textStyles,
})
