import React from 'react'
import { useForm } from 'react-hook-form'
import { useValidationSchema } from 'hooks/useValidationSchema'
import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Input } from 'components/input/Input'
import { Button } from 'components/button/Button'
import { useCreateArtist } from 'services/artists/artists.hooks'
import { useNavigate } from 'react-router-dom'
import { handleSuccess } from 'utils/handleSuccess.utlis'
import { CreateArtistFormValues, createArtistValidationSchema, defaultValues } from './CreateArtistForm.utils'

export const CreateArtistForm = () => {
  const { t } = useTranslation(['common', 'artist'])
  const { mutate: createArtist } = useCreateArtist()
  const navigate = useNavigate()
  const schema = useValidationSchema(createArtistValidationSchema)
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateArtistFormValues>({ defaultValues, resolver: schema })

  const onSubmit = (values: CreateArtistFormValues) => {
    createArtist(values, {
      onSuccess: ({ data }) => {
        handleSuccess(t('artist:createSuccessMessage', { name: data.name }))
        navigate(data.id)
      },
    })
  }

  return (
    <Flex as='form' onSubmit={handleSubmit(onSubmit)} direction='column'>
      <Input
        mb='20px'
        error={errors.name}
        label={t('artist:details.name.label')}
        inputProps={{
          placeholder: t('artist:details.name.placeholder'),
          ...register('name'),
        }}
      />
      <Button type='submit' isLoading={isSubmitting}>
        {t('common:save')}
      </Button>
    </Flex>
  )
}
