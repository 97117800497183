import { AxiosInstance } from 'axios'

import { GetFiltersRequestParams, GetFiltersResponse, Resources } from './filters.types'

export class FiltersService {
  private apiVersion = 'v1'
  private urlPrefix = `/${this.apiVersion}/filters`
  constructor(private fetchService: AxiosInstance) {}

  getAll = async <Resource extends Resources>(params: GetFiltersRequestParams<Resource>) => {
    const { data } = await this.fetchService.get<GetFiltersResponse<Resource>>(this.urlPrefix, {
      params,
      baseURL: process.env.REACT_APP_API_URL,
    })

    return data
  }
}
