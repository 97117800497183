import { createStandaloneToast } from '@chakra-ui/react'
import { AxiosError } from 'axios'

import i18n from 'locales/i18n'
import { theme } from 'theme/theme'

export const toast = createStandaloneToast({ theme })

export const chooseErrorMessage = (errorMessage: string | undefined) => {
  switch (errorMessage) {
    case 'must be active':
      return '.notActive'
    case 'must exists':
      return '.exist'
    case 'has already been taken':
      return '.alreadyTaken'
    default:
      return null
  }
}

export const getErrorMessage = (error: unknown) => {
  if (error instanceof AxiosError) {
    const errorMessageKey = `errors.${Object.keys(error?.response?.data?.errors)[0]}`
    const errorMessages = Object.values(error?.response?.data?.errors)[0] as string[] | undefined
    const errorMessageValue = chooseErrorMessage(errorMessages?.[0])

    const errotTranslationKey = errorMessageValue ? errorMessageKey + errorMessageValue : errorMessageKey

    return i18n.exists(errotTranslationKey) && i18n.t(errotTranslationKey)
  }

  return undefined
}

// basic version; will be developed
export const handleError = (errorMessage?: string) => {
  toast({
    title: errorMessage || i18n.t('common:errors.genericError'),
    status: 'error',
    position: 'top',
  })
}
