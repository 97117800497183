import React, { forwardRef } from 'react'
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker'
import { DATE_FORMAT_WITH_DOTS, DEFAULT_LANG, HOURS_MINUTES_FORMAT } from 'utils/consts'
import 'react-datepicker/dist/react-datepicker.css'
import { Input, InputComponentProps } from 'components/input/Input'
import pl from 'date-fns/locale/pl'
import { useTranslation } from 'react-i18next'
import { DatePickerWrapper } from './DateTimePicker.styles'

type DatePickerInputProps = {
  inputProps: InputComponentProps
} & ReactDatePickerProps

type CustomInputProps = {
  value: string
  onClick: () => void
  inputProps: DatePickerInputProps['inputProps']
}

registerLocale('pl', pl)

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(({ value, onClick, inputProps }, ref) => {
  return <Input ref={ref} onClick={onClick} inputProps={{ value }} {...inputProps} />
})

export const DatePickerInput = ({ inputProps, timeCaption, ...datePickerProps }: DatePickerInputProps) => {
  const { t } = useTranslation('common')

  return (
    <DatePickerWrapper>
      <DatePicker
        // @ts-ignore
        customInput={<CustomInput inputProps={inputProps} />}
        locale={DEFAULT_LANG}
        dateFormat={`${DATE_FORMAT_WITH_DOTS} ${HOURS_MINUTES_FORMAT}`}
        shouldCloseOnSelect={false}
        showTimeSelect
        timeIntervals={60}
        timeFormat={HOURS_MINUTES_FORMAT}
        timeCaption={timeCaption || t('datePicker.timeCaption')}
        popperModifiers={[
          {
            name: 'arrow',
            options: {
              padding: 24, // 24px from the edges of the popper if the centre of the reference input is beyond the edge of the popper
            },
          },
        ]}
        {...datePickerProps}
      />
    </DatePickerWrapper>
  )
}
