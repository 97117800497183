import { ComponentStyleConfig } from '@chakra-ui/react'

import { textStyles } from './Text.theme'

export const Checkbox: ComponentStyleConfig = {
  parts: ['container', 'control', 'icon', 'label'],
  baseStyle: {
    control: {
      borderRadius: 'none',
      borderWidth: '1px',
      borderColor: 'borderLight',
      transition: 'background-color .3s ease-in-out, border-color .3s ease-in-out',
      _checked: {
        bgColor: 'bgDark',
        borderColor: 'bgDark',
        color: 'bgLight',
        _hover: {
          borderColor: 'bgDarkHover',
          bgColor: 'bgDarkHover',
        },
      },
      _indeterminate: {
        bgColor: 'bgDark',
        borderColor: 'bgDark',
        color: 'bgLight',
        _hover: {
          borderColor: 'bgDarkHover',
          bgColor: 'bgDarkHover',
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
  sizes: {
    md: {
      control: {
        width: '24px',
        height: '24px',
      },
      label: {
        ...textStyles.labelM,
        marginLeft: '12px',
      },
    },
    sm: {
      control: {
        width: '24px',
        height: '24px',
      },
      label: {
        ...textStyles.labelS,
      },
    },
  },
  variants: {
    filters: {
      container: {
        alignItems: 'flex-start',
        p: '10px 20px',
        _hover: {
          bgColor: 'bgLight',
        },
        _focusWithin: {
          bgColor: 'bgLight',
        },
      },
      control: {
        _hover: {
          borderColor: 'borderLight',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
    },
  },
}
