import { ComponentStyleConfig } from '@chakra-ui/react'

export const Slider: ComponentStyleConfig = {
  parts: ['container', 'track', 'thumb', 'filledTrack'],
  defaultProps: {
    size: 'lg',
  },
  baseStyle: {
    track: {
      bgColor: 'separator',
    },
    filledTrack: {
      bgColor: 'bgDark',
    },
    thumb: {
      borderWidth: '1px',
      borderColor: 'textMain',
      boxShadow: 'none',
    },
  },
  sizes: {
    lg: {
      track: {
        height: '1px',
      },
      filledTrack: {
        height: '1px',
      },
    },
  },
}
