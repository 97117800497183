import { CellProps } from 'react-table'
import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { BasicGallery } from 'services/galleries/galleries.types'

export const GalleryStatusCell = ({ value }: CellProps<BasicGallery>) => {
  const { t } = useTranslation('galleries')

  return <Text textStyle='textMain'>{value ? t('isActive.active') : t('isActive.inactive')}</Text>
}
