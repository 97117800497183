import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { useState } from 'react'

import { PageTemplate } from 'components/PageTemplate'
import { GalleriesTable } from 'pages/galleries/galleriesTable/GalleriesTable'
import { Button } from 'components/button/Button'
import { IconPlus } from 'components/icons'
import { Modal } from 'components/modal/Modal'
import { CreateGalleryForm } from './CreateGalleryForm/CreateGalleryForm'

export const Galleries = () => {
  const { t } = useTranslation(['common', 'gallery'])

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  return (
    <>
      <PageTemplate
        title={t('common:navigation.galleries')}
        headerRightElement={
          <Button variant='outline' onClick={() => setIsCreateModalOpen(true)} rightIcon={<IconPlus />}>
            {t('common:add')}
          </Button>
        }
      >
        <GalleriesTable />
      </PageTemplate>
      <Outlet />
      <Modal
        closeButtonAriaLabel={t('gallery:closeCreateGalleryModalAriaLabel')}
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        title={t('gallery:createGallery')}
      >
        <CreateGalleryForm />
      </Modal>
    </>
  )
}
