import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

type ConfirmationMessageProps = {
  activeStatus: boolean
}

export const ConfirmationMessage = ({ activeStatus }: ConfirmationMessageProps) => {
  const { t } = useTranslation(['upsell', 'common'])

  return (
    <Flex flexDir='column'>
      <Text fontSize='15px'>
        {t('upsell:changeUpsellStatusMessage', {
          status: activeStatus ? t('upsell:activityStatus.disabled') : t('upsell:activityStatus.active'),
        })}
      </Text>
      <Text fontSize='15px' mb='30px'>
        {activeStatus ? t('upsell:changeUpsellSubMessage.disable') : t('upsell:changeUpsellSubMessage.activate')}
      </Text>
    </Flex>
  )
}
