import { Outlet, Route, Routes } from 'react-router-dom'

import { Galleries } from 'pages/galleries/Galleries'
import { Gallery } from 'pages/gallery/Gallery'

export const GALLERIES_BASE_ROUTE = '/galerie'

export const GalleriesRouter = () => (
  <>
    <Routes>
      <Route index element={<Galleries />} />
      <Route path=':id' element={<Gallery />} />
    </Routes>
    <Outlet />
  </>
)
