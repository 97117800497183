import { Outlet, Route, Routes } from 'react-router-dom'

import { Artists } from 'pages/artists/Artists'
import { Artist } from 'pages/artist/Artist'

export const ARTISTS_BASE_ROUTE = '/artysci'

export const ArtistsRouter = () => (
  <>
    <Routes>
      <Route index element={<Artists />} />
      <Route path=':id' element={<Artist />} />
    </Routes>
    <Outlet />
  </>
)
