import { MutationCache, QueryCache, QueryClient } from 'react-query'

import { REFRESH_TOKEN_ERROR } from 'utils/consts'
import { handleError } from 'utils/handleError.utils'

export const createClient = () => {
  let client: QueryClient

  const queryCache = new QueryCache({
    onError: (error, query) => {
      if (error instanceof Error && error.message === REFRESH_TOKEN_ERROR) {
        if (process.env.REACT_APP_BASE_APP_URL) {
          window.location.href = process.env.REACT_APP_BASE_APP_URL
        } else {
          handleError(REFRESH_TOKEN_ERROR)
          client.resetQueries()
        }
      } else {
        // @ts-ignore typings in react-query do not include options.onError while in reality it's available
        const hasQueryHooksErrorHandler = query.options.onError

        !hasQueryHooksErrorHandler && handleError()
      }
    },
  })

  const mutationCache = new MutationCache({
    onError: (error, _1, _2, mutation) => {
      if (error instanceof Error && error.message === REFRESH_TOKEN_ERROR) {
        if (process.env.REACT_APP_BASE_APP_URL) {
          window.location.href = process.env.REACT_APP_BASE_APP_URL
        } else {
          handleError(REFRESH_TOKEN_ERROR)
          client.resetQueries()
        }
      } else {
        // @ts-ignore mutation.observers is a private property
        const hasMutateErrorHandler = mutation.observers?.[0]?.mutateOptions?.onError
        const hasMutationHooksErrorHandler = mutation.options.onError

        !hasMutateErrorHandler && !hasMutationHooksErrorHandler && handleError()
      }
    },
  })

  client = new QueryClient({
    queryCache,
    mutationCache,
    defaultOptions: {
      queries: {
        retryDelay: 500,
        staleTime: 2000,
      },
    },
  })

  return client
}
