import { Box, Stack, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { ArtworkItem, UpsellItem } from 'services/orders/orders.types'

type OrderItemProps = {
  itemWrapper: ArtworkItem | UpsellItem
}

export const OrderItem = ({ itemWrapper }: OrderItemProps) => {
  const { t } = useTranslation('common')
  const { item, type, name } = itemWrapper

  return (
    <Box borderWidth='1px' borderColor='borderLight' padding='8px'>
      <Stack direction='row' spacing='8px'>
        {type === 'upsell' && <OrderItemImage src={item.image?.url} />}
        {type === 'artwork' && item.images?.map((img) => <OrderItemImage key={img.small?.url} src={img.small?.url} />)}
        <Box>
          <Text color='textDescription' textTransform='uppercase' textStyle='h4'>
            {t(`orderItemType.${type}`)}
          </Text>
          <Text>{name}</Text>
        </Box>
      </Stack>
    </Box>
  )
}

const OrderItemImage = ({ src }: { src: string }) => (
  <Image src={src} alt='' width='48px' height='48px' objectFit='contain' />
)
