const headingStyles = {
  h1: {
    fontSize: {
      base: '36px',
      lg: '48px',
    },
    lineHeight: {
      base: '56px',
      lg: '64px',
    },
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  h2: {
    fontSize: {
      base: '20px',
      lg: '28px,',
    },
    lineHeight: {
      base: '32px',
      lg: '40px',
    },
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  h3: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
  },
  h4: {
    fontSize: '13px',
    lineHeight: '24px',
    fontWeight: 500,
  },
} as const

const labelStyles = {
  labelXS: {
    fontSize: '10px',
    lineHeight: '12px',
  },
  labelS: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  labelM: {
    fontSize: '14px',
    lineHeight: '24px',
  },
  labelL: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
  },
  labelXL: {
    fontSize: '28px',
    lineHeight: '40px',
  },
}

const paragraphStyles = {
  textMain: {
    fontSize: '13px',
    lineHeight: '24px',
  },
}

export const textStyles = {
  ...headingStyles,
  ...labelStyles,
  ...paragraphStyles,
}
