import { Button as ChakraButton, ButtonProps as ChakraButtonProps, SpaceProps, useStyleConfig } from '@chakra-ui/react'
import { forwardRef } from 'react'

type AriaLabelProps =
  | {
      children?: never
      'aria-label': string
    }
  | {
      children: ChakraButtonProps['children']
      'aria-label'?: string
    }

export type ButtonProps = ChakraButtonProps & AriaLabelProps

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, leftIcon, rightIcon, iconSpacing, size, variant, ...props }, ref) => {
    const hasOnlyIcon = (leftIcon ? !rightIcon : rightIcon) && !children
    const { px } = useStyleConfig('Button', { size, variant })

    return (
      <ChakraButton
        ref={ref}
        {...props}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        iconSpacing={hasOnlyIcon ? 0 : iconSpacing}
        size={size}
        variant={variant}
        paddingX={hasOnlyIcon ? `calc(${px} - 4px)` : (px as SpaceProps['paddingX'])} // chakra has wrong typings for px returned from hook
      >
        {children}
      </ChakraButton>
    )
  }
)

Button.displayName = 'Button'

export { Button }
