import { CustomTypeOptions } from 'react-i18next'
import { GALLERIES_BASE_ROUTE } from 'routing/GalleriesRouter'
import { ARTWORKS_BASE_ROUTE } from './ArtworksRouter'
import { ACCOUNTS_BASE_ROUTE } from './AccountsRouter'
import { INQUIRIES_BASE_ROUTE } from './InquiriesRouter'
import { ORDERS_BASE_ROUTE } from './OrdersRouter'
import { ARTISTS_BASE_ROUTE } from './ArtistsRouter'
import { UPSELLS_BASE_ROUTE } from './UpsellsRouter'

type RouteName =
  | 'galleries'
  | 'gallery'
  | 'artworks'
  | 'artwork'
  | 'accounts'
  | 'inquiries'
  | 'inquiry'
  | 'orders'
  | 'order'
  | 'artists'
  | 'account'
  | 'upsells'
  | 'upsell'

type Route = {
  path: string
  navigationTranslationKey?: `navigation.${keyof CustomTypeOptions['resources']['common']['navigation']}`
  needAdminAccess?: boolean
  // access modifiers and per-route stuff like this
}

type NavbarRoute = Omit<Route, 'navigationTranslationKey'> & {
  navigationTranslationKey: NonNullable<Route['navigationTranslationKey']>
}

export const isNavbarRoute = (route: Route): route is NavbarRoute => !!route.navigationTranslationKey

export const routes: Record<RouteName, Route> = {
  galleries: {
    path: GALLERIES_BASE_ROUTE,
    navigationTranslationKey: 'navigation.galleries',
  },
  gallery: {
    path: `${GALLERIES_BASE_ROUTE}/:id`,
  },
  artworks: {
    path: ARTWORKS_BASE_ROUTE,
    navigationTranslationKey: 'navigation.artworks',
  },
  artwork: {
    path: `${ARTWORKS_BASE_ROUTE}/:id`,
  },
  artists: {
    path: ARTISTS_BASE_ROUTE,
    navigationTranslationKey: 'navigation.artists',
  },
  accounts: {
    path: ACCOUNTS_BASE_ROUTE,
    navigationTranslationKey: 'navigation.accounts',
    needAdminAccess: true,
  },
  account: {
    path: `${ACCOUNTS_BASE_ROUTE}/:id`,
  },
  inquiries: {
    path: INQUIRIES_BASE_ROUTE,
    navigationTranslationKey: 'navigation.inquiries',
  },
  inquiry: {
    path: `${INQUIRIES_BASE_ROUTE}/:id`,
  },
  orders: {
    path: ORDERS_BASE_ROUTE,
    navigationTranslationKey: 'navigation.orders',
  },
  order: {
    path: `${ORDERS_BASE_ROUTE}/:id`,
  },
  upsells: {
    path: UPSELLS_BASE_ROUTE,
    navigationTranslationKey: 'navigation.upsells',
  },
  upsell: {
    path: `${UPSELLS_BASE_ROUTE}/:id`,
  },
}
