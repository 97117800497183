import { Outlet, Route, Routes } from 'react-router-dom'

import { Inquiries } from 'pages/inquiries/Inquiries'
import { Inquiry } from 'pages/inquiry/Inquiry'

export const INQUIRIES_BASE_ROUTE = '/zapytania'

export const InquiriesRouter = () => (
  <>
    <Routes>
      <Route index element={<Inquiries />} />
      <Route path=':id' element={<Inquiry />} />
    </Routes>
    <Outlet />
  </>
)
