import { useInfiniteQuery, UseQueryOptions } from 'react-query'
import { FetchService } from 'services/fetch/fetch.service'
import { PublicArtistsService } from './publicArtists.service'
import { GetPublicArtistsRequestParams, GetPublicArtistsResponse } from './publicArtists.types'

const publicArtistsService = new PublicArtistsService(FetchService)

export const publicArtistsQueryKeys = {
  all: ['publicArtists'] as const,
  paginated: (params?: GetPublicArtistsRequestParams) => [...publicArtistsQueryKeys.all, params || ''],
}

export const usePublicArtists = (
  params: GetPublicArtistsRequestParams,
  options: Pick<
    UseQueryOptions<GetPublicArtistsResponse, GetPublicArtistsRequestParams>,
    'enabled' | 'keepPreviousData'
  > = {
    enabled: true,
    keepPreviousData: true,
  }
) =>
  useInfiniteQuery(
    publicArtistsQueryKeys.paginated(params),
    ({ pageParam = 0 }) =>
      publicArtistsService.getAll({
        ...params,
        offset: pageParam * params.limit,
      }),
    {
      ...options,
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = lastPage.meta.total / params.limit
        const nextPage = allPages.length

        return nextPage <= maxPages ? nextPage : undefined
      },
    }
  )
