import { AxiosInstance, AxiosResponse } from 'axios'

import { BASE_API_URL } from 'services/fetch/fetch.service'

import { cookies } from 'utils/cookies.utils'
import { GetUserResponse, RefreshTokenPayload, TokenResponse } from './auth.types'

export class AuthService {
  static ACCESS_TOKEN_COOKIE_NAME = 'accessToken'

  static REFRESH_TOKEN_COOKIE_NAME = 'refreshToken'

  static AUTH_HEADER_NAME = 'X-Authorization'

  private url = `${BASE_API_URL}/sessions`

  constructor(private fetchService: AxiosInstance) {}

  static getAuthHeader = (accessToken: string) => `Bearer ${accessToken}`

  getUser = async () => {
    const { data } = await this.fetchService.get<GetUserResponse>(this.url)

    return data
  }

  removeCookies = () => {
    cookies.delete(AuthService.ACCESS_TOKEN_COOKIE_NAME)
    cookies.delete(AuthService.REFRESH_TOKEN_COOKIE_NAME)
  }

  logout = async () => {
    try {
      // @ts-ignore Configuration of axios-auth-refresh so AxiosRequestConfig types doesn't know
      await this.fetchService.delete<void>(this.url, { skipAuthRefresh: true })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while invalidating token: ', error)
    } finally {
      this.removeCookies()
    }
  }

  refreshToken = async (refreshToken: RefreshTokenPayload['refreshToken']) => {
    const { data } = await this.fetchService.patch<TokenResponse, AxiosResponse<TokenResponse>, RefreshTokenPayload>(
      this.url,
      {
        refreshToken,
      },
      {
        // @ts-ignore Configuration of axios-auth-refresh so AxiosRequestConfig types doesn't know
        skipAuthRefresh: true,
      }
    )

    if (data.data.accessToken) {
      cookies.set(AuthService.ACCESS_TOKEN_COOKIE_NAME, data.data.accessToken)
      cookies.set(AuthService.REFRESH_TOKEN_COOKIE_NAME, data.data.refreshToken)
    }

    return data.data
  }
}
