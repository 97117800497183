import { AxiosInstance } from 'axios'
import { GetPublicArtistsRequestParams, GetPublicArtistsResponse } from './publicArtists.types'

export class PublicArtistsService {
  private urlPrefix = '/public_artists'
  constructor(private fetchService: AxiosInstance) {}

  getAll = async (params?: GetPublicArtistsRequestParams) => {
    const { data } = await this.fetchService.get<GetPublicArtistsResponse>(this.urlPrefix, { params })

    return data
  }
}
