import { ComponentStyleConfig } from '@chakra-ui/react'
import { textStyles } from './Text.theme'

export const Menu: ComponentStyleConfig = {
  parts: ['button', 'list', 'item', 'groupTitle'],
  defaultProps: {
    variant: 'naked',
    size: 'lg',
  },

  baseStyle: {
    button: {
      span: {
        display: 'flex',
        alignItems: 'center',
        svg: {
          transition: 'transform .2s',
        },
      },
      _expanded: {
        span: {
          'svg[data-chevron="true"]': {
            transform: 'rotate(-180deg)',
          },
        },
      },
    },
    list: {
      // Workaround as lib removes shadow when menu is not focused
      '&:focus:not([data-focus-visible-added])': {
        boxShadow: '0px 8px 36px rgba(0, 0, 0, 0.08)',
      },
      border: 'none',
      borderRadius: 0,
      boxShadow: '0px 8px 36px rgba(0, 0, 0, 0.08)',
      p: '40px 20px 20px',
    },
    item: {
      ...textStyles.labelM,
      transition: 'color .2s',
      p: 0,
      _focus: {
        bg: 'none',
      },
      _selected: {
        bg: 'none',
      },
      _active: {
        bg: 'none',
      },
      _disabled: {
        cursor: 'not-allowed',
      },
    },
  },

  sizes: {
    lg: {
      button: {
        px: '16px',
        py: '12px',
      },
    },
  },

  variants: {
    naked: {
      button: {
        color: 'textPlaceholder',
        _hover: {
          bg: 'bgLight',
        },
        _expanded: {
          bg: 'bgLight',
          color: 'textMain',
        },
      },
    },
    filters: {
      list: {
        width: { base: '100vw', md: '360px' },
        maxW: '100%',
        p: 0,
      },
      divider: {
        color: 'borderLight',
        opacity: 1,
      },
    },
  },
}
