import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

type ConfirmationMessageProps = {
  activeStatus: boolean
}

export const ConfirmationMessage = ({ activeStatus }: ConfirmationMessageProps) => {
  const { t } = useTranslation(['artwork', 'common'])

  return (
    <Flex flexDir='column'>
      <Text fontSize='15px'>
        {t('artwork:changeArtworkStatusMessage', {
          status: activeStatus ? t('artwork:activityStatus.disabled') : t('artwork:activityStatus.active'),
        })}
      </Text>
      <Text fontSize='15px' mb='30px'>
        {activeStatus ? t('artwork:changeArtworkSubMessage.disable') : t('artwork:changeArtworkSubMessage.activate')}
      </Text>
    </Flex>
  )
}
