import { Icon, IconProps } from '@chakra-ui/react'

const SvgIconPlus = (props: IconProps) => {
  return (
    // @ts-ignore Needed because of Emotion versions mismatch
    <Icon viewBox='0 0 24 24' height='24px' width='24px' data-testid='SvgIconPlus' {...props}>
      <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill='currentColor' d='M11 4h1v15h-1z' />
        <path fill='currentColor' d='M19 11v1H4v-1z' />
      </svg>
    </Icon>
  )
}

export default SvgIconPlus
