import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'

import { VisuallyHidden } from 'components/visuallyHidden/VisuallyHidden'

interface TextareaComponentProps extends FormControlProps {
  error?: FieldError
  // Required for a11y reasons, if it's not on the designs use with `hasVisuallyHiddenLabel` prop.
  label: string
  hasVisuallyHiddenLabel?: boolean
  helperText?: string
  inputProps: ChakraTextareaProps
  // pass a placeholder in inputProps instead
  placeholder?: never
}

export const Textarea = ({
  label,
  helperText,
  isInvalid,
  isDisabled,
  size,
  variant,
  error,
  inputProps,
  hasVisuallyHiddenLabel = false,
  ...rest
}: TextareaComponentProps) => {
  const invalid = !!error || isInvalid
  const isErrorMessageHidden = error?.type === 'hidden'
  const hasErrorMessage = error?.message && !isErrorMessageHidden

  return (
    <FormControl isInvalid={invalid} isDisabled={isDisabled} position='relative' id={inputProps.id} {...rest}>
      {label && (
        <VisuallyHidden enabled={hasVisuallyHiddenLabel}>
          <FormLabel variant='textInput' size={size}>
            {label}
          </FormLabel>
        </VisuallyHidden>
      )}
      <ChakraTextarea size={size} variant={variant} {...inputProps} />
      {(hasErrorMessage || helperText) && (
        <HStack spacing='8px' alignItems='flex-start' mt='8px'>
          {hasErrorMessage && (
            <FormErrorMessage variant='textInput' size={size} flex='1' display='box'>
              {error.message}
            </FormErrorMessage>
          )}
          {helperText && (
            <FormHelperText width='100%' textAlign='right' flex='1'>
              {helperText}
            </FormHelperText>
          )}
        </HStack>
      )}
    </FormControl>
  )
}
