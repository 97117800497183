import { TFunction } from 'react-i18next'
import { object, string } from 'yup'
import { Price } from 'types/common'

export type ReservationDetailsFormValues = {
  price: Price
  validUntil: Date | undefined
}

export const reservationDetailsValidationSchema = (t: TFunction) =>
  object({
    price: string().required(t('validation.required')),
    validUntil: string().required(t('validation.required')),
  })
