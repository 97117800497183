import { useReducer, Reducer } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Venue } from 'services/galleries/galleries.types'
import { Modal } from 'components/modal/Modal'
import { AddNewVenue, GalleryVenueTile } from './GalleryVenueTile'
import { VenueForm } from './VenueForm'

type GalleryVenuesSectionProps = {
  venues: Venue[]
}

type State =
  | {
      isModalOpen: false
      activeVenue: null
    }
  | {
      isModalOpen: true
      activeVenue: Venue | null
    }

type Action =
  | {
      type: 'openCreateModal'
    }
  | {
      type: 'openUpdateModal'
      payload: Venue
    }
  | {
      type: 'closeModal'
    }

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'openCreateModal':
      return { isModalOpen: true, activeVenue: null }
    case 'openUpdateModal':
      return { isModalOpen: true, activeVenue: action.payload }
    case 'closeModal':
    default:
      return { isModalOpen: false, activeVenue: null }
  }
}

export const GalleryVenuesSection = ({ venues }: GalleryVenuesSectionProps) => {
  const { t } = useTranslation('gallery')

  const [state, dispatch] = useReducer(reducer, { isModalOpen: false, activeVenue: null })

  const closeModal = () => dispatch({ type: 'closeModal' })

  return (
    <>
      <Flex direction='column' w={{ base: '100%', '2xl': '75%', '3xl': '50%' }}>
        <Text textStyle='h3' mb='32px'>
          {t('venues.heading')}
        </Text>
        {venues.map((venue) => {
          return (
            <GalleryVenueTile
              key={venue.id}
              venue={venue}
              onClick={() => dispatch({ type: 'openUpdateModal', payload: venue })}
            />
          )
        })}
        <AddNewVenue onClick={() => dispatch({ type: 'openCreateModal' })} />
      </Flex>
      <Modal
        isOpen={state.isModalOpen}
        onClose={closeModal}
        title={t(`venues.${state.activeVenue ? 'editVenue' : 'createVenue'}`)}
        closeButtonAriaLabel={t('venues.closeModalAriaLabel')}
      >
        <VenueForm venue={state.activeVenue} onSubmitForm={closeModal} />
      </Modal>
    </>
  )
}
