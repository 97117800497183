import { CellProps } from 'react-table'
import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { BasicUpsell } from 'services/galleries/galleries.types'

export const UpsellStatusCell = ({ value }: CellProps<BasicUpsell>) => {
  const { t } = useTranslation('upsells')

  return <Text textStyle='textMain'>{value ? t('isActive.active') : t('isActive.inactive')}</Text>
}
