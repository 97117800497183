import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

type ConfirmationMessageProps = {
  activeStatus: boolean
}

export const ConfirmationMessage = ({ activeStatus }: ConfirmationMessageProps) => {
  const { t } = useTranslation(['gallery', 'common'])

  return (
    <Flex flexDir='column'>
      <Text fontSize='15px'>
        {t('gallery:changeGalleryStatusMessage', {
          status: activeStatus ? t('gallery:activityStatus.disabled') : t('gallery:activityStatus.active'),
        })}
      </Text>
      <Text fontSize='15px' mb='30px'>
        {activeStatus ? t('gallery:changeGallerySubMessage.disable') : t('gallery:changeGallerySubMessage.activate')}
      </Text>
    </Flex>
  )
}
