import { CellProps } from 'react-table'
import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Account } from 'services/accounts/accounts.types'

export const AccessCell = ({ value }: CellProps<Account>) => {
  const { t } = useTranslation('accounts')

  return (
    <Text textStyle='textMain' textAlign='center'>
      {value ? t('hasAccess.true') : t('hasAccess.false')}
    </Text>
  )
}
