import { ComponentStyleConfig } from '@chakra-ui/react'

export const Drawer: ComponentStyleConfig = {
  defaultProps: {
    size: 'md',
    placement: 'right',
  },
  baseStyle: {
    overlay: {
      bg: 'rgba(33, 33, 33, 0.4)',
    },
    dialog: {
      overflowY: 'auto',
    },
  },
  sizes: {
    md: {
      dialog: {
        maxW: { base: 'full', md: '520px' },
        p: { base: '30px 20px 34px', md: '80px' },
      },
    },
    sm: {
      dialog: {
        maxW: { base: 'full', md: '520px' },
        p: { base: '16px 20px 20px', md: '80px' },
      },
    },
  },
}
