import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { PageTemplate } from 'components/PageTemplate'
import { UpsellsTable } from 'pages/upsells/upsellsTable/UpsellsTable'
import { usePaginatedGalleries } from 'services/galleries/galleries.hooks'
import { Button } from 'components/button/Button'
import { IconPlus } from 'components/icons'
import { Modal } from 'components/modal/Modal'
import { useCallback, useState } from 'react'
import { Select } from 'components/select/Select'
import { DEFAULT_PAGE_SIZE } from 'utils/consts'
import { debounce } from 'lodash'
import { Flex } from '@chakra-ui/react'
import { SelectOption } from 'types/common'
import { CreateUpsellForm } from './createUpsellForm/CreateUpsellForm'

const PAGE_SIZE = DEFAULT_PAGE_SIZE

export const Upsells = () => {
  const { t } = useTranslation(['common', 'upsells'])
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [searchInputValue, setSearchInputValue] = useState('')
  const [selectedGallery, setSelectedGallery] = useState<SelectOption | undefined>(undefined)

  const {
    data: galleriesData,
    isFetching: isFetchingGalleriesData,
    isLoading: isLoadingGalleriesData,
    hasNextPage,
    fetchNextPage,
  } = usePaginatedGalleries(
    {
      sort: 'name',
      search: searchInputValue,
      limit: PAGE_SIZE,
    },
    {
      onSuccess: (data) => {
        if (!selectedGallery) {
          setSelectedGallery({ value: data.pages[0].data[0].id, label: data.pages[0].data[0].name })
        }
      },
    }
  )

  const handleOnMenuScrollToBottom = () => {
    if (!isFetchingGalleriesData && hasNextPage) fetchNextPage()
  }
  const galleriesOptions = galleriesData?.pages
    .map((page) =>
      page.data.map(({ name, id }) => {
        return { label: name, value: id }
      })
    )
    .flat()

  const debouncedInputChange = useCallback(debounce(setSearchInputValue, 500), [])

  return (
    <>
      <PageTemplate
        headerRightElement={
          <Flex gap='40px'>
            <Select
              label={t('upsells:gallery.name')}
              hasVisuallyHiddenLabel
              options={galleriesOptions}
              isLoading={isLoadingGalleriesData || isFetchingGalleriesData}
              onChange={(newValue) => newValue && setSelectedGallery(newValue)}
              onInputChange={debouncedInputChange}
              placeholder={t('upsells:gallery.placeholder')}
              value={selectedGallery}
              formControlProps={{
                mb: '20px',
                width: '300px',
              }}
              defaultValue={galleriesOptions ? galleriesOptions[0] : undefined}
              onMenuScrollToBottom={handleOnMenuScrollToBottom}
            />
            <Button variant='outline' onClick={() => setIsCreateModalOpen(true)} rightIcon={<IconPlus />}>
              {t('common:add')}
            </Button>
          </Flex>
        }
        title={t('common:navigation.upsells')}
      >
        {selectedGallery && <UpsellsTable galleryId={selectedGallery.value} />}
      </PageTemplate>
      <Outlet />
      <Modal
        closeButtonAriaLabel={t('upsells:closeCreateUpsellModalAriaLabel')}
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        title={t('upsells:createUpsell')}
      >
        <CreateUpsellForm />
      </Modal>
    </>
  )
}
