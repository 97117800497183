import { ComponentStyleConfig } from '@chakra-ui/react'

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'none',
    fontWeight: 400,
  },

  defaultProps: {
    variant: 'primary',
    size: 'lg',
  },

  sizes: {
    sm: {
      px: '10px',
      fontSize: '12px',
    },
    md: {
      px: '12px',
      fontSize: '14px',
    },
    lg: {
      px: '16px',
      fontSize: '14px',
    },
  },

  variants: {
    primary: {
      bgColor: 'bgDark',
      color: 'textLight',
      // Stronger selector for the button in menu item
      '&[role="menuitem"]': {
        color: 'textLight',
      },
      _hover: {
        bgColor: 'bgDarkHover',
        // Stronger selector for the button in menu item
        '&[role="menuitem"]': {
          bgColor: 'bgDarkHover',
        },
        _disabled: {
          bgColor: 'bgLight',
          // Stronger selector for the button in menu item
          '&[role="menuitem"]': {
            bgColor: 'bgLight',
          },
        },
      },
      _focus: {
        bgColor: 'bgDarkHover',
        // Stronger selector for focused state, when menu item is as={Button}
        '&[role="menuitem"]:focus': {
          bgColor: 'bgDarkHover',
        },
      },
      _active: {
        bgColor: 'bgDarkHover',
      },
      _disabled: {
        bgColor: 'bgLight',
        color: 'textSub',
        // Stronger selector for the button in menu item
        '&[role="menuitem"]': {
          opacity: 1,
          color: 'textSub',
        },
      },
    },

    secondary: {
      bgColor: 'bgLight',
      _hover: {
        bgColor: '#EFEFEF',
        _disabled: {
          bgColor: '#F3F3F3',
        },
      },
      _focus: {
        bgColor: '#EFEFEF',
        // Stronger selector for focused state, when menu item is as={Button}
        '&[role="menuitem"]:focus': {
          bgColor: '#EFEFEF',
        },
      },
      _active: {
        bgColor: '#EFEFEF',
      },
      _disabled: {
        bgColor: '#F3F3F3',
        color: 'textSub',
      },
    },

    outline: {
      borderColor: 'borderLight',
      transition: 'background .2s',
      _hover: {
        bgColor: 'bgLight',
        _disabled: {
          bgColor: '#F3F3F3',
        },
      },
      _focus: {
        // Stronger selector for focused state, when menu item is as={Button}
        '&[role="menuitem"]:focus': {
          bgColor: 'bgLight',
        },
      },
      _active: {
        bgColor: '#EFEFEF',
      },
      _disabled: {
        color: 'textSub',
        borderColor: 'borderGray',
      },
    },

    ghost: {
      color: 'textMain',
      textTransform: 'uppercase',
      _hover: {
        color: 'textMain',
        bgColor: 'transparent',
        _disabled: {
          color: 'textSub',
        },
      },
      _active: {
        color: 'textMain',
        bgColor: 'transparent',
      },
      _disabled: {
        color: 'textSub',
      },
    },

    filterTrigger: {
      color: 'textMain',
      borderBottomWidth: '1px',
      borderBottomColor: 'borderLight',
      _hover: {
        bgColor: 'bgLight',
        _disabled: {
          color: 'textSub',
        },
      },
      _active: {
        borderBottomColor: 'textMain',
        bgColor: 'bgLight',
      },
      _disabled: {
        color: 'textSub',
        cursor: 'not-allowed',
      },
    },
  },
}
