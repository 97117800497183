import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Flex, Grid } from '@chakra-ui/react'

import { Input } from 'components/input/Input'
import { Textarea } from 'components/textarea/Textarea'
import { Button } from 'components/button/Button'
import { Artwork } from 'services/artworks/artworks.types'

import { useValidationSchema } from 'hooks/useValidationSchema'
import { useUpdateArtwork } from 'services/artworks/artworks.hooks'
import { Checkbox } from 'components/checkbox/Checkbox'
import debounce from 'lodash/debounce'
import { useCreateArtist } from 'services/artists/artists.hooks'
import { Select } from 'components/select/Select'
import { handleSuccess } from 'utils/handleSuccess.utlis'
import { useFetchFilters } from 'services/filters/filters.hooks'
import { DEFAULT_PAGE_SIZE } from 'utils/consts'
import { useQueryClient } from 'react-query'
import { publicArtistsQueryKeys, usePublicArtists } from 'services/publicArtists/publicArtists.hooks'
import {
  ArtworkDetailsFormValues,
  activeArtworkDetailsValidationSchema,
  disabledArtworkDetailsValidationSchema,
  getDefaultValues,
  getFeaturedValue,
} from './Artwork.utils'

export type ArtworkDetailsSectionProps = {
  artwork: Partial<Artwork> & { name: Artwork['name']; id: Artwork['id'] }
  setImageError: (message?: string) => void
}

const PAGE_SIZE = DEFAULT_PAGE_SIZE

export const ArtworkDetailsSection = ({ artwork, setImageError }: ArtworkDetailsSectionProps) => {
  const { t } = useTranslation(['artwork', 'common', 'artist'])

  const [searchInputValue, setSearchInputValue] = useState('')

  const { mutate: createArtist, isLoading: isArtistCreating } = useCreateArtist()
  const { data: artMovementData, isLoading: isArtMovementLoading } = useFetchFilters({ resource: 'art_movement' })
  const { data: artworkTypeData, isLoading: isArtworkTypesLoading } = useFetchFilters({ resource: 'artwork_type' })
  const queryClient = useQueryClient()

  const { mutate: updateArtwork } = useUpdateArtwork()

  const {
    data: artistsData,
    isFetching: isFetchingPublicArtists,
    isLoading: isLoadingPublicArtists,
    hasNextPage,
    fetchNextPage,
  } = usePublicArtists({
    search: searchInputValue,
    limit: PAGE_SIZE,
    sort: 'name',
  })

  const defaultValues = getDefaultValues(artwork)

  const validationSchema = artwork.active
    ? activeArtworkDetailsValidationSchema
    : disabledArtworkDetailsValidationSchema

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
  } = useForm<ArtworkDetailsFormValues>({
    defaultValues,
    resolver: useValidationSchema(validationSchema),
  })

  const artistOptions = artistsData?.pages
    .map((page) => page.data.map((artist) => ({ value: artist.id, label: artist.name })))
    .flat()
  const artMovementOptions = artMovementData?.data[0].options.map((movement) => ({ ...movement }))
  const artworkTypeOptions = artworkTypeData?.data[0].options.map((type) => ({ ...type }))
  const debouncedInputChange = useCallback(debounce(setSearchInputValue, 500), [])

  const handleCreateArtist = (name: string) => {
    createArtist(
      { name },
      {
        onSuccess: ({ data }) => {
          handleSuccess(t('artist:createSuccessMessage', { name: data.name }))
          queryClient.invalidateQueries(publicArtistsQueryKeys.all)
          setValue('artist', { label: data.name, value: data.id })
        },
      }
    )
  }

  const onSubmit = ({ artMovement, artworkType, artist, featured, ...values }: ArtworkDetailsFormValues) => {
    updateArtwork(
      {
        ...values,
        year: +values.year,
        price: +values.price,
        dimensions: {
          width: +values.dimensions.width,
          height: +values.dimensions.height,
        },
        artMovementId: artMovement?.value || '',
        artworkTypeId: artworkType?.value || '',
        id: artwork.id,
        artistId: artist?.value || '',
        ...(defaultValues.featured !== featured ? { featured: getFeaturedValue(featured) } : {}),
      },
      {
        onSuccess: ({ data }) => {
          handleSuccess(t('artwork:updateSuccessMessage', { name: data.name }))
        },
      }
    )
  }

  const handleOnMenuScrollToBottom = () => {
    if (!isFetchingPublicArtists && hasNextPage) fetchNextPage()
  }

  useEffect(() => {
    if (isSubmitting && artwork.active) {
      setImageError(!artwork.images?.length ? t('common:validation.required') : undefined)
    }
  }, [isSubmitting, artwork.images])

  return (
    <Flex as='form' direction='column' onSubmit={handleSubmit(onSubmit)}>
      <Input
        mb='20px'
        error={errors.name}
        label={t('artwork:details.name.label')}
        inputProps={{
          placeholder: t('artwork:details.name.placeholder'),
          ...register('name'),
        }}
      />
      <Textarea
        mb='20px'
        error={errors.description}
        label={t('artwork:details.description.label')}
        inputProps={{
          placeholder: t('artwork:details.description.placeholder'),
          ...register('description'),
        }}
      />
      <Input
        mb='20px'
        error={errors.year}
        label={t('artwork:details.year.label')}
        inputProps={{
          placeholder: t('artwork:details.year.placeholder'),
          ...register('year'),
        }}
      />
      <Input
        mb='20px'
        error={errors.technique}
        label={t('artwork:details.technique.label')}
        inputProps={{
          placeholder: t('artwork:details.technique.placeholder'),
          ...register('technique'),
        }}
      />
      <Grid templateColumns='repeat(2, 1fr)' columnGap='8px'>
        <Input
          mb='20px'
          error={errors.dimensions?.width}
          label={t('artwork:details.width.label')}
          inputProps={{
            placeholder: t('artwork:details.width.placeholder'),
            ...register('dimensions.width'),
          }}
        />
        <Input
          mb='20px'
          error={errors.dimensions?.height}
          label={t('artwork:details.height.label')}
          inputProps={{
            placeholder: t('artwork:details.height.placeholder'),
            ...register('dimensions.height'),
          }}
        />
      </Grid>
      <Input
        mb='20px'
        error={errors.price}
        label={t('artwork:details.price.label')}
        inputProps={{
          placeholder: t('artwork:details.price.placeholder'),
          ...register('price'),
        }}
      />
      <Controller
        control={control}
        name='artMovement'
        render={({ fieldState: { error }, field: { onChange, value } }) => {
          return (
            <Select
              isDisabled={!artMovementOptions || isArtMovementLoading}
              isLoading={isArtworkTypesLoading}
              error={error}
              onChange={onChange}
              value={value}
              label={t('artwork:details.artMovement.label')}
              placeholder={t('artwork:details.artMovement.placeholder')}
              options={artMovementOptions}
            />
          )
        }}
      />
      <Controller
        control={control}
        name='artworkType'
        render={({ fieldState: { error }, field: { onChange, value } }) => {
          return (
            <Select
              isDisabled={!artworkTypeOptions || isArtworkTypesLoading}
              isLoading={isArtworkTypesLoading}
              error={error}
              onChange={onChange}
              value={value}
              label={t('artwork:details.artworkType.label')}
              placeholder={t('artwork:details.artworkType.placeholder')}
              options={artworkTypeOptions}
            />
          )
        }}
      />
      <Controller
        control={control}
        defaultValue={null}
        name='artist'
        render={({ fieldState: { error }, field: { onChange, value } }) => {
          return (
            <Select
              isCreatable
              isDisabled={isArtistCreating || isLoadingPublicArtists || !artistOptions}
              isLoading={isArtistCreating || isFetchingPublicArtists || isLoadingPublicArtists}
              createOptionPosition='first'
              formatCreateLabel={(inputValue) => t('artwork:details.artist.createText', { name: inputValue })}
              onCreateOption={(inputValue) => handleCreateArtist(inputValue)}
              error={error}
              onChange={onChange}
              value={value}
              label={t('artwork:details.artist.label')}
              onInputChange={debouncedInputChange}
              placeholder={t('artwork:details.artist.placeholder')}
              options={artistOptions}
              onMenuScrollToBottom={handleOnMenuScrollToBottom}
            />
          )
        }}
      />
      <Checkbox mb='20px' {...register('featured')}>
        {t('artwork:details.featured.label')}
      </Checkbox>
      <Button type='submit' alignSelf='flex-end' ml='8px' isLoading={isSubmitting}>
        {t('common:save')}
      </Button>
    </Flex>
  )
}
