import type { FocusEvent } from 'react'

import { Flex, Grid, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import dayjs from 'dayjs'

import { Input } from 'components/input/Input'
import { WeekdayCodeAlpha3 } from 'types/common'
import { weekdays } from 'utils/consts'
import { formatTime } from 'utils/string/string.utils'
import { VenueFormValues } from './Gallery.utils'
import { CopyButton } from './CopyButton'

type OpeningHoursRowProps = {
  dayIndex: number
  dayAbbreviation: WeekdayCodeAlpha3
}

export const OpeningHoursRow = ({ dayIndex, dayAbbreviation }: OpeningHoursRowProps) => {
  const { t } = useTranslation('gallery')
  const {
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<VenueFormValues>()

  const dayName = dayjs()
    .day(dayIndex + 1)
    .format('dddd')

  const formatAndSetTime = (timeInput: string, weekday: WeekdayCodeAlpha3, dateDestination: 'to' | 'from') => {
    const formattedTime = formatTime(timeInput)

    if (formattedTime) {
      setValue(`openingHours.${weekday}.${dateDestination}`, formattedTime)
      trigger(`openingHours.${weekday}.${dateDestination}`)
    }
  }

  return (
    <>
      <Text textStyle='labelS' fontWeight='600' textTransform='capitalize'>
        {dayName}
      </Text>
      <input {...register(`openingHours.${dayAbbreviation}.day`)} value={dayAbbreviation} type='hidden' />
      <Input
        error={errors.openingHours?.[dayAbbreviation]?.from}
        label={t('venues.openingHours.from', { day: dayName })}
        hasVisuallyHiddenLabel
        inputProps={{
          placeholder: t('venues.openingHours.openingHour'),
          ...register(`openingHours.${dayAbbreviation}.from`, {
            onBlur: (e: FocusEvent<HTMLInputElement>) => formatAndSetTime(e.target.value, dayAbbreviation, 'from'),
          }),
        }}
      />
      <Input
        error={errors.openingHours?.[dayAbbreviation]?.to}
        label={t('venues.openingHours.to', { day: dayName })}
        hasVisuallyHiddenLabel
        inputProps={{
          placeholder: t('venues.openingHours.closingHour'),
          ...register(`openingHours.${dayAbbreviation}.to`, {
            onBlur: (e: FocusEvent<HTMLInputElement>) => formatAndSetTime(e.target.value, dayAbbreviation, 'to'),
          }),
        }}
      />
    </>
  )
}

type OpeningHoursProps = {
  error?: string
}

export const OpeningHours = ({ error }: OpeningHoursProps) => {
  const { t } = useTranslation('gallery')
  const { setValue, getValues, trigger } = useFormContext<VenueFormValues>()

  const duplicateRows = () => {
    const firstDayFromValue = getValues(`openingHours.${weekdays[0]}.from`)
    const firstDayToValue = getValues(`openingHours.${weekdays[0]}.to`)

    weekdays.forEach((day) => {
      setValue(`openingHours.${day}.from`, firstDayFromValue)
      setValue(`openingHours.${day}.to`, firstDayToValue)
      trigger(`openingHours.${day}.from`)
      trigger(`openingHours.${day}.to`)
    })
  }

  return (
    <Flex>
      <Grid templateColumns='repeat(3, 1fr)' columnGap='8px' rowGap='20px' alignItems='center' flexGrow={1}>
        {weekdays.map((dayAbbreviation, i) => (
          <OpeningHoursRow key={dayAbbreviation} dayIndex={i} dayAbbreviation={dayAbbreviation} />
        ))}
        {error && (
          <Text textStyle='labelS' color='error' gridColumn='1 / -1'>
            {error}
          </Text>
        )}
      </Grid>
      <CopyButton
        aria-label={t('venues.openingHours.duplicateAll')}
        title={t('venues.openingHours.duplicateAll')}
        alignSelf='baseline'
        mt='8px'
        ml='8px'
        onClick={duplicateRows}
      />
    </Flex>
  )
}
