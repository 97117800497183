import { Flex, Image, Stack, Link as ChakraLink } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'
import { routes } from 'routing/routing.utils'

import { Artwork } from 'services/artworks/artworks.types'
import { InfoRow } from 'components/InfoRow'
import { formatCurrency } from 'utils/string/string.utils'
import { IconArrow } from 'components/icons'

type ArtworkDetailsSectionProps = {
  artwork: Artwork
}

export const ArtworkDetailsSection = ({ artwork }: ArtworkDetailsSectionProps) => {
  const { t } = useTranslation('inquiry')

  if (!artwork) return null

  const path = generatePath(routes.artwork.path, { id: artwork.id })

  return (
    <Flex flexDirection='column'>
      <Image src={artwork.images[0].large.url} alt='' width='320px' height='100%' objectFit='contain' />
      <Stack marginTop='24px' spacing='8px'>
        <InfoRow label={t('artwork.artist')} value={artwork.artist.name} />
        <InfoRow label={t('artwork.nameAndYear')} value={`${artwork.name}, ${artwork.year}`} />
        <InfoRow label={t('artwork.price')} value={formatCurrency(artwork.price)} />
      </Stack>
      <ChakraLink target='_blank' alignSelf='flex-start' mt='32px' as={Link} to={path}>
        {t('artwork.goToArtwork')}
        <IconArrow ml='auto' opacity={1} transform='rotate(-90deg)' />
      </ChakraLink>
    </Flex>
  )
}
