import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query'

import { FetchService } from 'services/fetch/fetch.service'
import { InquiriesService } from './inquiries.service'
import {
  GetInquiriesRequestParams,
  GetInquiryParams,
  GetInquiryRequestParams,
  GetInquiryResponse,
} from './inquiries.types'

const inquiriesService = new InquiriesService(FetchService)

export const inquiriesQueryKeys = {
  all: ['inquiries'] as const,
  one: (slug: GetInquiryParams['slug']) => [...inquiriesQueryKeys.all, slug],
  filtered: (params?: GetInquiriesRequestParams) => [...inquiriesQueryKeys.all, params || ''],
}

export const useInquiries = (
  params?: GetInquiriesRequestParams,
  options: Pick<UseQueryOptions<GetInquiryResponse, GetInquiriesRequestParams>, 'enabled' | 'keepPreviousData'> = {
    keepPreviousData: true,
    enabled: true,
  }
) => useQuery(inquiriesQueryKeys.filtered(params), () => inquiriesService.getAll(params), options)

export const useInquiry = (
  slug: GetInquiryParams['slug'],
  options?: Pick<
    UseQueryOptions<GetInquiryResponse, GetInquiryRequestParams, GetInquiryResponse, string[]>,
    'refetchInterval'
  >
) => useQuery(inquiriesQueryKeys.one(slug), () => inquiriesService.getOne({ slug }), options)

export const useCreateReservation = () => {
  const queryClient = useQueryClient()

  return useMutation(inquiriesService.createReservation, {
    onSuccess: (_, { inquiryId }) => {
      queryClient.invalidateQueries(inquiriesQueryKeys.one(inquiryId))
    },
  })
}
