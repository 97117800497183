import { Flex, IconProps, Text } from '@chakra-ui/react'
import { formatDate } from 'utils/string/string.utils'

import { Message } from 'services/inquiries/inquiries.types'

type ChatMessageProps = {
  message: Message
  messagerName: string
  messagerIcon: (props: IconProps) => JSX.Element
}

export const ChatMessage = ({ message, messagerName, messagerIcon: MessagerIcon }: ChatMessageProps) => {
  return (
    <Flex direction='row' alignItems='flex-start' gap='20px'>
      <MessagerIcon width='40px' height='40px' viewBox='0 0 40 40' />
      <Flex direction='column' m='0' alignItems='flex-start'>
        <Flex direction='row' gap='5px'>
          <Text textStyle='labelXS' noOfLines={{ md: 1 }}>
            {messagerName}
          </Text>
          <Text textStyle='labelXS'>{formatDate(message.createdAt)}</Text>
        </Flex>
        <Text whiteSpace='unset' wordBreak='break-word' textStyle='textMain'>
          {message.content}
        </Text>
      </Flex>
    </Flex>
  )
}
