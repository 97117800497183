import { CellProps } from 'react-table'
import { Image as ChakraImage } from '@chakra-ui/react'
import placeholderImage from 'assets/images/placeholder-image.png'

import { Image } from 'types/common'

export const LogoCell = <D extends object, V extends Image>({ value }: CellProps<D, V>) => (
  <ChakraImage
    src={value ? value.url : placeholderImage}
    backgroundColor='bgGrey'
    alt=''
    width='80px'
    height='80px'
    objectFit='cover'
  />
)
