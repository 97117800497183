import { CellProps } from 'react-table'
import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { BasicArtwork } from 'services/artworks/artworks.types'

export const ArtworkStatusCell = ({ value }: CellProps<BasicArtwork>) => {
  const { t } = useTranslation('artworks')

  return <Text textStyle='textMain'>{value ? t('isActive.active') : t('isActive.inactive')}</Text>
}
