import { ComponentStyleConfig } from '@chakra-ui/react'
import { textStyles } from './Text.theme'

export const Input: ComponentStyleConfig = {
  parts: ['field', 'element'],
  defaultProps: {
    variant: 'outline',
    size: 'lg',
  },

  baseStyle: {
    element: {
      _groupFocusWithin: {
        color: 'textMain',
      },
      transition: 'color .2s',
      color: 'textPlaceholder',
    },
    field: {
      _placeholder: {
        ...textStyles.labelM,
        color: 'textPlaceholder',
      },
    },
  },

  sizes: {
    lg: {
      field: {
        px: '16px',
        borderRadius: 'none',
        ...textStyles.labelM,
      },
    },
  },

  variants: {
    outline: {
      field: {
        color: 'textMain',
        borderColor: 'borderLight',
        _hover: {
          borderColor: 'borderGray',
        },
        _focus: {
          boxShadow: 'none',
          borderColor: 'textMain',
        },
        _disabled: {
          opacity: 1,
          _invalid: {
            borderColor: 'borderLight',
          },
          _hover: {
            borderColor: 'borderLight',
          },
          borderColor: 'borderLight',
          _placeholder: {
            color: 'borderLight',
          },
        },
        _invalid: {
          borderColor: 'error',
          boxShadow: 'none',
          _focus: {
            borderColor: 'error',
          },
        },
      },
    },
    filtersUnderlined: {
      field: {
        color: 'textMain',
        borderWidth: 0,
        bgColor: 'transparent',
        pr: { base: '10px', md: '16px' },
        cursor: { base: 'pointer', md: 'text' },
        _focus: {
          cursor: 'text',
          pr: { base: '16px' },
          borderColor: 'separator',
        },
        _disabled: {
          opacity: 1,
          border: 'none',
          _placeholder: {
            color: 'borderLight',
          },
        },
      },
      element: {
        width: '58px',
      },
    },
  },
}
