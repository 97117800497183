import { useTranslation } from 'react-i18next'
import React, { useRef } from 'react'
import { Inquiry } from 'services/inquiries/inquiries.types'
import { Flex } from '@chakra-ui/react'
import { IconGallery, IconUserChat } from 'components/icons'
import { ChatMessage } from 'components/chatMessage/ChatMessage'
import { useUser } from 'services/auth/auth.hooks'
import { ChatInput } from 'components/chatInput/ChatInput'
import { useSendMessage } from 'services/messages/messages.hooks'
import { useScrollToBottomOnNewMessage } from './useScrolltoBottomOnNewMessage'

type InquiryChatProps = {
  inquiry: Inquiry
  inquirerName: Inquiry['inquirerName']
}

export const InquiryChat = ({ inquiry, inquirerName }: InquiryChatProps) => {
  const { data: userData } = useUser({ retry: false })
  const { t } = useTranslation('inquiry')

  const sendMessage = useSendMessage()

  const onSubmit = (value: string) => {
    if (!!value.trim() && inquiry.id) {
      sendMessage.mutate({ content: value, inquiryId: inquiry.id })
    }
  }

  const messagesEndRef = useRef<HTMLDivElement | null>(null)

  useScrollToBottomOnNewMessage(messagesEndRef, inquiry.messages)

  return (
    <Flex direction='column' mt='20px' alignItems='flex-start' gap='32px' overflowY='scroll' maxHeight='100vh'>
      <Flex ref={messagesEndRef} w='100%' alignItems='flex-start' direction='column' gap='32px' overflowY='auto'>
        {inquiry.messages?.map((message) => {
          const isUser = message.accountId === userData?.data.id

          return (
            <ChatMessage
              messagerName={isUser ? t('chat.me') : inquirerName}
              messagerIcon={isUser ? IconGallery : IconUserChat}
              key={message.createdAt}
              message={message}
            />
          )
        })}
      </Flex>
      <ChatInput onSubmit={onSubmit} />
    </Flex>
  )
}
