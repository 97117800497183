import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { ArtistsService } from 'services/artists/artists.service'
import { GetArtistParams, GetArtistsRequestParams, GetArtistsResponse } from 'services/artists/artists.types'
import { FetchService } from 'services/fetch/fetch.service'

const artistsService = new ArtistsService(FetchService)

export const artistsQueryKeys = {
  all: ['artists'] as const,
  one: (slug: GetArtistParams['slug']) => [...artistsQueryKeys.all, slug],
  filtered: (params?: GetArtistsRequestParams) => [...artistsQueryKeys.all, params || ''],
}

export const useArtists = (
  params?: GetArtistsRequestParams,
  options: Pick<UseQueryOptions<GetArtistsResponse, GetArtistsRequestParams>, 'enabled' | 'keepPreviousData'> = {
    enabled: true,
    keepPreviousData: true,
  }
) => useQuery(artistsQueryKeys.filtered(params), () => artistsService.getAll(params), options)

export const useArtist = (slug: GetArtistParams['slug']) =>
  useQuery(artistsQueryKeys.one(slug || ''), () => artistsService.getOne({ slug }), {
    enabled: !!slug,
  })

export const useCreateArtist = () => {
  const queryClient = useQueryClient()

  return useMutation(artistsService.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(artistsQueryKeys.all)
    },
  })
}

export const useUpdateArtist = () => {
  const queryClient = useQueryClient()

  return useMutation(artistsService.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(artistsQueryKeys.all)
    },
  })
}
