import { useEffect, useMemo, useState } from 'react'
import { Flex, Grid, Box, Text } from '@chakra-ui/react'
import { useTable, usePagination, Column } from 'react-table'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Pagination } from 'components/pagination/Pagination'
import { Account } from 'services/accounts/accounts.types'
import { DEFAULT_INITIAL_PAGE, DEFAULT_PAGE_SIZE, HEADER_HEIGHT } from 'utils/consts'

import { NameCell } from 'components/tableCells/NameCell'
import { useAccounts } from 'services/accounts/accounts.hooks'
import { TextCell } from 'components/tableCells/TextCell'
import { AccessCell } from './AccessCell'

const gridStyles = {
  templateColumns: '1fr 1fr 1fr 150px',
  columnGap: '24px',
  px: '24px',
  alignItems: 'center',
  borderBottomWidth: '1px',
  borderColor: 'black',
}

const INITIAL_PAGE = DEFAULT_INITIAL_PAGE
const PAGE_SIZE = DEFAULT_PAGE_SIZE

export const AccountsTable = () => {
  const { t } = useTranslation('accounts')
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE)
  const { data: accountsData } = useAccounts({
    offset: currentPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    sort: 'created_at_desc',
  })
  const navigate = useNavigate()

  const columns: Column<Account>[] = useMemo(
    () => [
      {
        Header: t('headers.user'),
        accessor: 'name',
        Cell: NameCell,
      },
      {
        Header: t('headers.email'),
        accessor: 'email',
        Cell: TextCell,
      },
      {
        Header: t('headers.id'),
        accessor: 'id',
        Cell: TextCell,
      },
      {
        Header: t('headers.accessToAdminPanel'),
        accessor: 'accessToAdminPanel',
        Cell: AccessCell,
      },
    ],
    [t]
  )

  const accounts = useMemo(() => accountsData?.data || [], [accountsData?.data])
  const total = accountsData?.meta.total || 0

  const pageCount = Math.ceil(total / PAGE_SIZE)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { pageIndex },
  } = useTable(
    { columns, data: accounts, initialState: { pageIndex: currentPage }, pageCount, manualPagination: true },
    usePagination
  )

  useEffect(() => {
    setCurrentPage(pageIndex)
  }, [pageIndex])

  return (
    <Flex height='100%' direction='column' justifyContent='space-between' flexGrow={1}>
      <Flex direction='column' {...getTableProps()}>
        <Box position='sticky' top={HEADER_HEIGHT} bgColor='bg'>
          {headerGroups.map((headerGroup) => {
            const headerGroupProps = headerGroup.getHeaderGroupProps()

            return (
              <Grid {...headerGroupProps} key={headerGroupProps.key} {...gridStyles} py='12px'>
                {headerGroup.headers.map((column) => {
                  const headerProps = column.getHeaderProps()

                  return (
                    <Text {...headerProps} key={headerProps.key} textStyle='labelS' textTransform='uppercase'>
                      {column.render('Header')}
                    </Text>
                  )
                })}
              </Grid>
            )
          })}
        </Box>
        <Box {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const rowProps = row.getRowProps()

            return (
              <Grid
                {...rowProps}
                key={rowProps.key}
                {...gridStyles}
                py='32px'
                cursor='pointer'
                onClick={() => {
                  navigate(row.original.id)
                }}
              >
                {row.cells.map((cell) => {
                  const cellProps = cell.getCellProps()

                  return (
                    <Box {...cellProps} key={cellProps.key}>
                      {cell.render('Cell')}
                    </Box>
                  )
                })}
              </Grid>
            )
          })}
        </Box>
      </Flex>
      <Flex justifyContent='flex-end' width='100%' mt='24px'>
        <Pagination
          currentPage={currentPage}
          pageCount={pageCount}
          setPreviousPage={() => setCurrentPage(currentPage - 1)}
          previousEnabled={currentPage >= 1}
          setNextPage={() => setCurrentPage(currentPage + 1)}
          nextEnabled={currentPage + 1 < pageCount}
        />
      </Flex>
    </Flex>
  )
}
