import React, { useCallback } from 'react'
import { Gallery } from 'services/galleries/galleries.types'
import { PreviewTile } from 'components/imagePreviewTile/ImagePreviewTile'
import { ImageSelector } from 'components/imageSelector/ImageSelector'
import { useCreateGalleryLogo, useDeleteGalleryLogo, useGalleryLogo } from 'services/galleries/galleries.hooks'
import { Box, Grid, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

type GalleryLogoSectionProps = {
  gallery: Gallery
}

export const GalleryLogoSection = ({ gallery }: GalleryLogoSectionProps) => {
  const { t } = useTranslation('gallery')
  const { data: galleryLogoData } = useGalleryLogo(gallery.id || '')
  const { mutate: createLogo, isLoading: isLogoUploading } = useCreateGalleryLogo()
  const { mutate: deleteLogo } = useDeleteGalleryLogo()

  const uploadGalleryLogo = useCallback(
    async (acceptedFiles: File[]) => {
      if (gallery) {
        await createLogo({ slug: gallery.id, file: acceptedFiles[0] })
      }
    },
    [gallery, createLogo]
  )

  return (
    <Box>
      {galleryLogoData?.data ? (
        <>
          <Text marginTop='20px' marginBottom='8px' fontWeight='600' textStyle='labelS'>
            {t('logo.label')}
          </Text>
          <Grid
            templateColumns={{ base: '1fr 1fr', lg: 'repeat(4, 1fr)' }}
            justifyItems='center'
            alignItems='center'
            gap='24px'
            sx={{
              '& > *': {
                aspectRatio: '1',
              },
            }}
          >
            <PreviewTile previewSrc={galleryLogoData.data.url} onDelete={() => deleteLogo({ slug: gallery.id })} />
          </Grid>
        </>
      ) : (
        <ImageSelector
          marginTop='20px'
          label={t('logo.label')}
          isLoading={isLogoUploading}
          onDrop={uploadGalleryLogo}
          onDelete={() => {}}
        />
      )}
    </Box>
  )
}
