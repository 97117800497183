import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Grid, Divider, Flex } from '@chakra-ui/react'

import { PageTemplate } from 'components/PageTemplate'
import { useInquiry } from 'services/inquiries/inquiries.hooks'

import { InquiryChat } from 'components/inquiryChat/InquiryChat'
import { ArtworkDetailsSection } from './ArtworkDetailsSection'
import { InquirerDetailsSection } from './InquirerDetailsSection'
import { CurrentReservation } from './CurrentReservation'
import { CreatingReservation } from './CreatingReservation'

const CHAT_REFETCH_TIME = 60 * 1000 // 60 seconds

export const Inquiry = () => {
  const { t } = useTranslation('inquiry')

  const { id } = useParams()

  const { data: inquiry } = useInquiry(id || '', { refetchInterval: CHAT_REFETCH_TIME })

  if (!inquiry) return null

  return (
    <PageTemplate title={t('heading')}>
      <Grid templateColumns='1fr 1fr' columnGap='60px' py='58px'>
        <Flex flexDirection='column'>
          <ArtworkDetailsSection artwork={inquiry.data.artwork} />
          <Divider mt='32px' mb='42px' />
        </Flex>
        <Flex flexDirection='column'>
          <InquirerDetailsSection inquirerName={inquiry.data.inquirerName} phoneNumber={inquiry.data.phoneNumber} />
          <Divider mt='32px' mb='42px' />
          {inquiry.data.currentReservation ? (
            <CurrentReservation reservation={inquiry.data.currentReservation} />
          ) : (
            <CreatingReservation defaultPrice={inquiry.data.artwork.price} inquiryId={inquiry.data.id} />
          )}
          <Divider mt='32px' mb='64px' />
          <InquiryChat inquirerName={inquiry.data.inquirerName} inquiry={inquiry.data} />
        </Flex>
      </Grid>
    </PageTemplate>
  )
}
