import { useQuery, UseQueryOptions } from 'react-query'

import { FetchService } from 'services/fetch/fetch.service'
import { OrdersService } from './orders.service'
import { GetOrderParams, GetOrderResponse, GetOrdersRequestParams } from './orders.types'

const ordersService = new OrdersService(FetchService)

export const ordersQueryKeys = {
  all: ['orders'] as const,
  one: (slug: GetOrderParams['slug']) => [...ordersQueryKeys.all, slug],
  filtered: (params?: GetOrdersRequestParams) => [...ordersQueryKeys.all, params || ''],
}

export const useOrders = (
  params?: GetOrdersRequestParams,
  options: Pick<UseQueryOptions<GetOrderResponse>, 'keepPreviousData' | 'enabled'> = {
    keepPreviousData: true,
    enabled: true,
  }
) => useQuery(ordersQueryKeys.filtered(params), () => ordersService.getAll(params), options)

export const useOrder = (slug: GetOrderParams['slug']) =>
  useQuery(ordersQueryKeys.one(slug || ''), () => ordersService.getOne({ slug }), {
    enabled: !!slug,
  })
