import { WeekdayCodeAlpha3 } from 'types/common'

export const REQUEST_TIMEOUT = 30000

export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR'

export const DEFAULT_LANG = 'pl'
export const DEFAULT_CURRENCY = 'PLN'

export const HEADER_HEIGHT = '97px'
export const TOP_BAR_HEIGHT = '64px'

export const weekdays: WeekdayCodeAlpha3[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']

export const DATE_TIME_FORMAT = 'HH:mm:ss DD/MM/YYYY'
export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_FORMAT_WITH_DOTS = 'dd.MM.yyyy'
export const HOURS_MINUTES_FORMAT = 'HH:mm'

export const FILE_UPLOAD_MAX_SIZE = 10 * 1000 * 1000 // in bytes

export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_INITIAL_PAGE = 0
