import { DropzoneOptions, ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import { Box, Grid, Spinner, Text, FormLabel, BoxProps } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { ImageFile } from 'types/common'
import { useState } from 'react'
import { FILE_UPLOAD_MAX_SIZE } from 'utils/consts'
import { PreviewTile } from 'components/imagePreviewTile/ImagePreviewTile'

type ImageSelectorProps = {
  label?: string
  images?: ImageFile[]
  isLoading: boolean
  onDrop: (acceptedFiles: File[]) => void
  onDelete: (imageId: ImageFile['id']) => void
  maxSize?: DropzoneOptions['maxSize'] // in bytes
  error?: string
  clearError?: () => void
} & Omit<BoxProps, 'onDrop'>

export const ImageSelector = ({
  images,
  isLoading,
  onDrop,
  onDelete,
  label,
  error: errorProp,
  maxSize = FILE_UPLOAD_MAX_SIZE,
  ...props
}: ImageSelectorProps) => {
  const { t } = useTranslation('common')
  const [dropZoneError, setDropZoneError] = useState<string | null>(null)
  const maxSizeInMegabytes = maxSize / 1000 / 1000
  const error = dropZoneError || errorProp

  const getErrorMessage = (code: ErrorCode) => {
    switch (code) {
      case 'file-invalid-type':
        return t(`errors.${code}`)
      case 'file-too-large':
        return t(`errors.${code}`, {
          max: t('MB', { count: maxSizeInMegabytes }),
        })
      case 'too-many-files':
        return t(`errors.${code}`, {
          count: 1,
        })
      default:
        return t('errors.genericError')
    }
  }

  const handleReject = (rejections: FileRejection[]) => {
    const errorCode = rejections[0].errors[0].code as ErrorCode
    errorCode && setDropZoneError(getErrorMessage(errorCode))
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg'],
    },
    onDrop: () => setDropZoneError(null),
    onDropAccepted: onDrop,
    disabled: isLoading,
    maxFiles: 1,
    maxSize,
    multiple: false,
    onDropRejected: handleReject,
  })

  const getBorderColor = () => {
    if (error) {
      return 'error'
    }

    if (isDragActive) {
      return 'borderGray'
    }

    return 'borderLight'
  }

  return (
    <Box as='section' {...props}>
      <FormLabel htmlFor='fileInput'>{label || t('fields.photos')}</FormLabel>
      <Box
        {...getRootProps()}
        borderWidth='1px'
        borderColor={getBorderColor()}
        display='flex'
        borderStyle='dashed'
        justifyContent='center'
        alignItems='center'
        transition='border-color .2s'
        _hover={{
          borderColor: error ? 'error' : 'borderGray',
        }}
        py='46px'
        mb='0px'
        px='16px'
        cursor='pointer'
      >
        <input {...getInputProps()} id='fileInput' />
        <Text textAlign='center' textStyle='textMain'>
          {t(isDragActive ? 'placeholders.dropFile' : 'placeholders.files')}
        </Text>
      </Box>
      <Text color={error ? 'error' : 'textMain'} textStyle='labelXS' mb='24px' mt='4px'>
        {error || t('maxImageSizeMB', { count: maxSizeInMegabytes })}
      </Text>
      <Grid
        templateColumns={{ base: '1fr 1fr', lg: 'repeat(4, 1fr)' }}
        justifyItems='center'
        alignItems='center'
        gap='24px'
        sx={{
          '& > *': {
            aspectRatio: '1',
          },
        }}
      >
        {images?.map((image) => (
          <PreviewTile key={image.id} previewSrc={image.url} onDelete={() => onDelete(image.id)} />
        ))}
        {isLoading && <Spinner size='xl' />}
      </Grid>
    </Box>
  )
}
