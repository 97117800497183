import { useQuery } from 'react-query'
import { FetchService } from 'services/fetch/fetch.service'
import { FiltersService } from './filters.service'
import { GetFiltersRequestParams, Resources } from './filters.types'

const filtersService = new FiltersService(FetchService)

export const galleriesQueryKeys = {
  all: ['filters'] as const,
  oneResource: (resource: Resources) => [...galleriesQueryKeys.all, resource],
}

export const useFetchFilters = <Resource extends Resources>({ resource }: GetFiltersRequestParams<Resource>) =>
  useQuery(galleriesQueryKeys.oneResource(resource), () => filtersService.getAll({ resource }))
