import { useTranslation } from 'react-i18next'
import { PageTemplate } from 'components/PageTemplate'
import { Outlet } from 'react-router-dom'
import { Button } from 'components/button/Button'
import { IconPlus } from 'components/icons'
import { useState } from 'react'
import { Modal } from 'components/modal/Modal'
import { ArtistsTable } from './ArtistsTable/ArtistsTable'
import { CreateArtistForm } from './CreateArtistForm/CreateArtistForm'

export const Artists = () => {
  const { t } = useTranslation(['common', 'artist'])
  const [isCreateModalOpen, setCreateModalOpen] = useState(false)

  return (
    <>
      <PageTemplate
        headerRightElement={
          <Button variant='outline' onClick={() => setCreateModalOpen(true)} rightIcon={<IconPlus />}>
            {t('common:add')}
          </Button>
        }
        title={t('common:navigation.artists')}
      >
        <ArtistsTable />
      </PageTemplate>
      <Outlet />
      <Modal
        closeButtonAriaLabel={t('artist:closeCreateArtistModalAriaLabel')}
        isOpen={isCreateModalOpen}
        onClose={() => setCreateModalOpen(false)}
        title={t('artist:createArtist')}
      >
        <CreateArtistForm />
      </Modal>
    </>
  )
}
