import { ReactNode } from 'react'
import { VisuallyHidden as ChakraVisuallyHidden } from '@chakra-ui/react'

type VisuallyHiddenProps = {
  enabled: boolean
  children: ReactNode
}

/**
 * Simple wrapper for Chakra UI's Visually Hidden.
 * It's children's visibility is controlled through `enabled` props.
 */
export const VisuallyHidden = ({ children, enabled }: VisuallyHiddenProps) => {
  if (enabled) {
    return <ChakraVisuallyHidden>{children}</ChakraVisuallyHidden>
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
