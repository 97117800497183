import { useTranslation } from 'react-i18next'
import { PageTemplate } from 'components/PageTemplate'
import { Outlet } from 'react-router-dom'
import { InquiriesTable } from './inquiriesTable/InquiriesTable'

export const Inquiries = () => {
  const { t } = useTranslation('common')

  return (
    <>
      <PageTemplate title={t('navigation.inquiries')}>
        <InquiriesTable />
      </PageTemplate>
      <Outlet />
    </>
  )
}
