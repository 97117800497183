import { AxiosInstance } from 'axios'

import {
  CreateGalleryImagePayload,
  CreateGalleryImageResponse,
  CreateGalleryLogoPayload,
  CreateGalleryPayload,
  GetGalleryResponse,
  CreateVenuePayload,
  DeleteGalleryImagePayload,
  DeleteGalleryLogoPayload,
  DeleteVenuePayload,
  GetGalleriesRequestParams,
  GetGalleriesResponse,
  GetGalleryImagesParams,
  GetGalleryImagesResponse,
  GetGalleryLogoParams,
  GetGalleryLogoResponse,
  GetGalleryParams,
  GetVenueResponse,
  UpdateGalleryPayload,
  UpdateVenuePayload,
  CreateGalleryResponse,
  UpdateGalleryActiveStatus,
  GetPaginatedGalleriesRequestParams,
  GetGalleryUpsellsParams,
  GetGalleryUpsellsResponse,
  CreateUpsellImagePayload,
  DeleteUpsellImagePayload,
  GetUpsellImageResponse,
  GetUpsellImageParams,
  GetUpsellResponse,
  UpdateUpsellPayload,
  GetUpsellParams,
  CreateUpsellPayload,
  UpdateUpsellActiveStatus,
} from './galleries.types'

export class GalleriesService {
  private urlPrefix = '/galleries'
  constructor(private fetchService: AxiosInstance) {}

  getAll = async (params?: GetGalleriesRequestParams) => {
    const { data } = await this.fetchService.get<GetGalleriesResponse>(this.urlPrefix, { params })

    return data
  }

  getAllPaginated = async (params?: GetPaginatedGalleriesRequestParams) => {
    const { data } = await this.fetchService.get<GetGalleriesResponse>(this.urlPrefix, { params })

    return data
  }

  getOne = async ({ slug }: GetGalleryParams) => {
    const { data } = await this.fetchService.get<GetGalleryResponse>(`${this.urlPrefix}/${slug}`)

    return data
  }

  update = async (payload: UpdateGalleryPayload) => {
    const { data } = await this.fetchService.patch<GetGalleryResponse>(`${this.urlPrefix}/${payload.id}`, payload)

    return data
  }

  create = async (payload: CreateGalleryPayload) => {
    const { data } = await this.fetchService.post<CreateGalleryResponse>(this.urlPrefix, payload)

    return data
  }

  createVenue = async ({ galleryId, venue }: CreateVenuePayload) => {
    const { data } = await this.fetchService.post<GetVenueResponse>(`${this.urlPrefix}/${galleryId}/venues`, venue)

    return data
  }

  updateVenue = async ({ galleryId, venue }: UpdateVenuePayload) => {
    const { data } = await this.fetchService.patch<GetVenueResponse>(
      `${this.urlPrefix}/${galleryId}/venues/${venue.id}`,
      venue
    )

    return data
  }

  deleteVenue = async ({ galleryId, venue }: DeleteVenuePayload) => {
    await this.fetchService.delete<void>(`${this.urlPrefix}/${galleryId}/venues/${venue.id}`)
  }

  getAllImages = async ({ slug }: GetGalleryImagesParams) => {
    const { data } = await this.fetchService.get<GetGalleryImagesResponse>(`${this.urlPrefix}/${slug}/images`)

    return data
  }

  createImage = async ({ slug, file, position }: CreateGalleryImagePayload) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('position', `${position}`)

    await this.fetchService.post<CreateGalleryImageResponse>(`${this.urlPrefix}/${slug}/images`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  }

  deleteImage = async ({ slug, imageId }: DeleteGalleryImagePayload) => {
    await this.fetchService.delete(`${this.urlPrefix}/${slug}/images/${imageId}`)
  }

  getLogo = async ({ slug }: GetGalleryLogoParams) => {
    const { data } = await this.fetchService.get<GetGalleryLogoResponse>(`${this.urlPrefix}/${slug}/logo`)

    return data
  }

  createLogo = async ({ slug, file }: CreateGalleryLogoPayload) => {
    const formData = new FormData()
    formData.append('file', file)

    await this.fetchService.post<CreateGalleryImageResponse>(`${this.urlPrefix}/${slug}/logo`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  }

  deleteLogo = async ({ slug }: DeleteGalleryLogoPayload) => {
    await this.fetchService.delete(`${this.urlPrefix}/${slug}/logo`)
  }

  updateActiveStatus = async ({ slug, active }: UpdateGalleryActiveStatus) => {
    await this.fetchService.patch(`${this.urlPrefix}/${slug}`, { active })
  }

  getAllUpsells = async ({ slug, ...params }: GetGalleryUpsellsParams) => {
    const { data } = await this.fetchService.get<GetGalleryUpsellsResponse>(`${this.urlPrefix}/${slug}/upsells`, {
      params,
    })

    return data
  }

  createUpsell = async ({ name, galleryId }: CreateUpsellPayload) => {
    const { data } = await this.fetchService.post<GetUpsellResponse>(`${this.urlPrefix}/${galleryId}/upsells`, {
      name,
    })

    return data
  }
  getOneUpsell = async ({ slug, galleryId }: GetUpsellParams) => {
    const { data } = await this.fetchService.get<GetUpsellResponse>(`${this.urlPrefix}/${galleryId}/upsells/${slug}`)

    return data
  }
  updateUpsell = async ({ galleryId, payload }: UpdateUpsellPayload) => {
    const { data } = await this.fetchService.patch<GetUpsellResponse>(
      `${this.urlPrefix}/${galleryId}/upsells/${payload.id}`,
      payload
    )

    return data
  }

  getUpsellImage = async ({ slug, galleryId }: GetUpsellImageParams) => {
    const { data } = await this.fetchService.get<GetUpsellImageResponse>(
      `${this.urlPrefix}/${galleryId}/upsells/${slug}/image`
    )

    return data
  }

  createUpsellImage = async ({ slug, galleryId, file }: CreateUpsellImagePayload) => {
    const formData = new FormData()
    formData.append('file', file)

    await this.fetchService.post<CreateGalleryImageResponse>(
      `${this.urlPrefix}/${galleryId}/upsells/${slug}/image`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    )
  }

  deleteUpsellImage = async ({ slug, galleryId }: DeleteUpsellImagePayload) => {
    await this.fetchService.delete(`${this.urlPrefix}/${galleryId}/upsells/${slug}/image`)
  }

  updateUpsellActiveStatus = async ({ galleryId, slug, active }: UpdateUpsellActiveStatus) => {
    await this.fetchService.patch(`${this.urlPrefix}/${galleryId}/upsells/${slug}`, { active })
  }
}
