import { useCallback, useState } from 'react'
import { Grid, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { ImageSelector } from 'components/imageSelector/ImageSelector'
import { PageTemplate } from 'components/PageTemplate'
import {
  useArtwork,
  useArtworkImages,
  useCreateArtworkImage,
  useDeleteArtworkImage,
  useToggleStatus,
} from 'services/artworks/artworks.hooks'
import { QRCodeGeneration } from 'components/QRCodeGeneration/QRCodeGeneration'
import { Button } from 'components/button/Button'
import { ConfirmModal } from 'components/confirmModal/ConfirmModal'
import { getErrorMessage, handleError } from 'utils/handleError.utils'
import { handleSuccess } from 'utils/handleSuccess.utlis'
import { ArtworkDetailsSection } from './ArtworkDetailsSection'
import { ConfirmationMessage } from './ConfirmationMessage/ConfirmationMessage'
import { RecognitionLabelInput } from './RecognitionLabelInput'
import { RecognitionImages } from './RecognitionImages'

export const Artwork = () => {
  const { t } = useTranslation(['artwork', 'common'])
  const [imageError, setImageError] = useState<string | undefined>(undefined)

  const { id } = useParams()

  const { data: artwork } = useArtwork(id || '')
  const { data: artworkImages, isLoading: isImagesLoading } = useArtworkImages(id || '')
  const { mutate: createImage, isLoading: isImagesUploading } = useCreateArtworkImage()
  const { mutate: deleteImage } = useDeleteArtworkImage()
  const { mutate: updateStatus } = useToggleStatus()

  const [isArtworkStatusConfirmationModalOpen, setIsArtworkStatusConfirmationModalOpen] = useState(false)
  const [isImageDeletionWarningModalOpen, setIsImageDeletionWarningModalOpen] = useState(false)

  const uploadArtworkImage = useCallback(
    async (acceptedFiles: File[]) => {
      if (artwork && artworkImages) {
        await createImage({ slug: artwork.data.id, file: acceptedFiles[0], position: artworkImages.data.length })
        setImageError(undefined)
      }
    },
    [artwork, createImage, artworkImages]
  )

  const updateActivityStatus = () => {
    if (!id || !artwork?.data) return

    updateStatus(
      { slug: id, active: !artwork.data.active },
      {
        onSuccess: () => {
          handleSuccess(
            artwork.data.active ? t('artwork:onStatusChange.disabled') : t('artwork:onStatusChange.activated')
          )
        },
        onError: (err) => {
          const errorMessage = getErrorMessage(err)

          if (errorMessage) {
            handleError(errorMessage)
          } else {
            handleError(t('artwork:updateError'))
          }
        },
      }
    )
  }

  if (!artwork) return null

  const onImageDelete = (imageId: string) => {
    if (artworkImages?.data.length === 1 && artwork.data.active) {
      setIsImageDeletionWarningModalOpen(true)
    } else {
      deleteImage({ slug: artwork.data.id, imageId })
    }
  }

  return (
    <>
      <PageTemplate title={t('artwork:heading')}>
        <Grid templateColumns='1fr 1fr' columnGap='60px' py='58px'>
          <ArtworkDetailsSection artwork={artwork.data} setImageError={setImageError} />
          <div>
            <ImageSelector
              error={imageError}
              onDrop={uploadArtworkImage}
              isLoading={isImagesLoading || isImagesUploading}
              images={artworkImages?.data}
              onDelete={onImageDelete}
            />
            <Button mt='24px' onClick={() => setIsArtworkStatusConfirmationModalOpen(true)}>
              {artwork.data.active
                ? t('artwork:changeArtworkActivityStatus.disable')
                : t('artwork:changeArtworkActivityStatus.active')}
            </Button>
            <QRCodeGeneration
              name={`${artwork.data.name} ${artwork.data.gallery.name}`}
              qrDescription={t('artwork:downloadQR')}
              value={`${process.env.REACT_APP_BASE_APP_URL}/dziela/${artwork.data.id}`}
            />
            <RecognitionLabelInput artwork={artwork.data} />
            <RecognitionImages recognitionLabel={artwork.data?.recognitionLabel} />
          </div>
        </Grid>
      </PageTemplate>
      <ConfirmModal
        closeButtonAriaLabel={t('artwork:closeConfirmationArtworkModalAriaLabel')}
        isOpen={isArtworkStatusConfirmationModalOpen}
        onClose={() => setIsArtworkStatusConfirmationModalOpen(false)}
        title={t('artwork:activityStatus.title')}
        onConfirm={updateActivityStatus}
      >
        <ConfirmationMessage activeStatus={artwork.data.active} />
      </ConfirmModal>
      <ConfirmModal
        closeButtonAriaLabel={t('common:closeModalButtonAriaLabel')}
        isOpen={isImageDeletionWarningModalOpen}
        onClose={() => setIsImageDeletionWarningModalOpen(false)}
        title={t('artwork:lastImageInformation.title')}
        onConfirm={() => setIsImageDeletionWarningModalOpen(false)}
      >
        <Text>{t('artwork:lastImageInformation.description')}</Text>
      </ConfirmModal>
    </>
  )
}
