import { Icon, IconProps } from '@chakra-ui/react'

const SvgIconEnvelopePaper = (props: IconProps) => {
  return (
    // @ts-ignore Needed because of Emotion versions mismatch
    <Icon viewBox='0 0 24 24' height='24px' width='24px' data-testid='SvgIconEnvelopePaper' {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={16}
        height={16}
        fill='currentColor'
        className='IconEnvelopePaper_svg__bi IconEnvelopePaper_svg__bi-envelope-paper'
      >
        <path d='M4 0a2 2 0 0 0-2 2v1.133l-.941.502A2 2 0 0 0 0 5.4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5.4a2 2 0 0 0-1.059-1.765L14 3.133V2a2 2 0 0 0-2-2H4Zm10 4.267.47.25A1 1 0 0 1 15 5.4v.817l-1 .6v-2.55Zm-1 3.15-3.75 2.25L8 8.917l-1.25.75L3 7.417V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v5.417Zm-11-.6-1-.6V5.4a1 1 0 0 1 .53-.882L2 4.267v2.55Zm13 .566v5.734l-4.778-2.867L15 7.383Zm-.035 6.88A1 1 0 0 1 14 15H2a1 1 0 0 1-.965-.738L8 10.083l6.965 4.18ZM1 13.116V7.383l4.778 2.867L1 13.117Z' />
      </svg>
    </Icon>
  )
}

export default SvgIconEnvelopePaper
