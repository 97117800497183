import { TFunction } from 'react-i18next'
import { object, string, number, ObjectSchema, StringSchema } from 'yup'

import { Gallery, VenueOpeningHours } from 'services/galleries/galleries.types'
import { WeekdayCodeAlpha3 } from 'types/common'
import { weekdays } from 'utils/consts'

export type OpeningHoursObj = Record<WeekdayCodeAlpha3, VenueOpeningHours>

const timeRegex = /(?:[01]\d|2[0123]):(?:[012345]\d)/g

export const galleryDetailsValidationSchema = (t: TFunction) =>
  object({
    name: string().required(t('validation.required')),
  })

export type GalleryDetailsFormValues = {
  name: string
  description: string
  deliveryPrice: Gallery['deliveryPrice']
}

const openingHoursValidation = (t: TFunction) =>
  weekdays.reduce((acc, day) => {
    acc[day] = object({
      from: string().test('isValidTime', t('validation.invalidTime'), (value) => !value || !!value.match(timeRegex)),
      to: string().test('isValidTime', t('validation.invalidTime'), (value) => !value || !!value.match(timeRegex)),
    })

    return acc
  }, {} as Record<WeekdayCodeAlpha3, ObjectSchema<{ from: StringSchema; to: StringSchema }>>)

export const galleryVenuesValidationSchema = (t: TFunction) =>
  object({
    name: string().required(t('validation.required')),
    phoneNumber: number().typeError(t('validation.invalidFormat')).required(t('validation.required')),
    email: string().email(t('validation.invalidFormat')).required(t('validation.required')),
    address: object({
      street: string().required(t('validation.required')),
      number: string().required(t('validation.required')),
      apartment: string(),
      postalCode: string().required(t('validation.required')),
      city: string().required(t('validation.required')),
      instructions: string(),
    }),
    openingHours: object()
      .shape(openingHoursValidation(t))
      .test('openingHours required', t('validation.openingHoursRequired'), (value) =>
        Object.values(value).some((day) => day.from && day.to)
      ),
  })

export type VenueFormValues = {
  name: string
  phoneNumber: string
  email: string
  address: {
    street: string
    number: string
    apartment: string | null
    postalCode: string
    city: string
    instructions: string
  }
  openingHours: OpeningHoursObj
}
