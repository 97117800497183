import { ComponentStyleConfig } from '@chakra-ui/react'
import { textStyles } from './Text.theme'

export const FormError: ComponentStyleConfig = {
  parts: ['text'],
  sizes: {
    lg: {
      text: {
        ...textStyles.labelS,
      },
    },
  },
  variants: {
    textInput: {
      text: {
        color: 'error',
        mt: 0,
      },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'textInput',
  },
}
