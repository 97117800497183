import { TFunction } from 'react-i18next'
import { object, string } from 'yup'

export const artistDetailsValidationSchema = (t: TFunction) =>
  object({
    name: string().required(t('validation.required')),
    description: string(),
  })

export type ArtistDetailsFormValues = {
  name: string
  description: string
}
