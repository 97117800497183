import { Icon, IconProps } from '@chakra-ui/react'

const SvgIconUserChat = (props: IconProps) => {
  return (
    // @ts-ignore Needed because of Emotion versions mismatch
    <Icon viewBox='0 0 24 24' height='24px' width='24px' data-testid='SvgIconUserChat' {...props}>
      <svg width={40} height={40} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#IconUserChat_svg__a)'>
          <path fill='#000' d='M0 0h40v40H0z' />
          <path
            d='M30.5 6.5c0 6.096-4.722 11-10.5 11S9.5 12.596 9.5 6.5s4.722-11 10.5-11S30.5.404 30.5 6.5ZM5.5 29a7.5 7.5 0 0 1 7.5-7.5h14a7.5 7.5 0 0 1 7.5 7.5v13.5h-29V29Z'
            fill='#EEF3EE'
            stroke='#000'
          />
        </g>
        <path stroke='#000' d='M.5.5h39v39H.5z' />
        <defs>
          <clipPath id='IconUserChat_svg__a'>
            <path fill='#fff' d='M0 0h40v40H0z' />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  )
}

export default SvgIconUserChat
