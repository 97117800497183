import { useInfiniteQuery, useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { ArtworksService } from 'services/artworks/artworks.service'
import {
  GetArtworkImagesParams,
  GetArtworkParams,
  GetArtworkRecognitionDatasetParams,
  GetArtworksRequestParams,
  GetArtworksResponse,
  GetPaginatedArtworksRequestParams,
} from 'services/artworks/artworks.types'
import { FetchService } from 'services/fetch/fetch.service'

const artworksService = new ArtworksService(FetchService)

export const artworksQueryKeys = {
  all: ['artworks'] as const,
  one: (slug: GetArtworkParams['slug']) => [...artworksQueryKeys.all, slug],
  filtered: (params?: GetArtworksRequestParams) => [...artworksQueryKeys.all, params],
  images: (slug: GetArtworkImagesParams['slug']) => [...artworksQueryKeys.one(slug), 'images'],
  recognitionDataset: (slug: GetArtworkRecognitionDatasetParams['slug']) => [
    ...artworksQueryKeys.one(slug),
    'recognitionDataset',
  ],
}

export const useArtworks = (
  params?: GetArtworksRequestParams,
  options: Pick<UseQueryOptions<GetArtworksResponse, GetArtworksRequestParams>, 'enabled' | 'keepPreviousData'> = {
    enabled: true,
    keepPreviousData: true,
  }
) => useQuery(artworksQueryKeys.filtered(params), () => artworksService.getAll(params), options)

export const useArtwork = (slug: GetArtworkParams['slug']) =>
  useQuery(artworksQueryKeys.one(slug || ''), () => artworksService.getOne({ slug }), {
    enabled: !!slug,
  })

export const useUpdateArtwork = () => {
  const queryClient = useQueryClient()

  return useMutation(artworksService.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(artworksQueryKeys.all)
    },
  })
}

export const useArtworkImages = (slug: GetArtworkImagesParams['slug']) => {
  return useQuery(artworksQueryKeys.images(slug), () => artworksService.getAllImages({ slug }))
}

export const useCreateArtworkImage = () => {
  const queryClient = useQueryClient()

  return useMutation(artworksService.createImage, {
    onSuccess: (_, { slug }) => {
      queryClient.invalidateQueries(artworksQueryKeys.images(slug))
      queryClient.invalidateQueries(artworksQueryKeys.one(slug))
    },
  })
}

export const useDeleteArtworkImage = () => {
  const queryClient = useQueryClient()

  return useMutation(artworksService.deleteImage, {
    onSuccess: (_, { slug }) => {
      queryClient.invalidateQueries(artworksQueryKeys.images(slug))
    },
  })
}

export const useCreateArtwork = () => {
  const queryClient = useQueryClient()

  return useMutation(artworksService.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(artworksQueryKeys.all)
    },
  })
}

export const useToggleStatus = () => {
  const queryClient = useQueryClient()

  return useMutation(artworksService.updateActiveStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(artworksQueryKeys.all)
    },
  })
}

export const usePaginatedArtworks = (params: GetPaginatedArtworksRequestParams) =>
  useInfiniteQuery(
    artworksQueryKeys.filtered(params),
    ({ pageParam = 0 }) =>
      artworksService.getAllPaginated({
        ...params,
        offset: pageParam * params.limit,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = lastPage.meta.total / params.limit
        const nextPage = allPages.length

        return nextPage <= maxPages ? nextPage : undefined
      },
    }
  )

export const useRecognitionDataset = (
  slug: GetArtworkParams['slug'],
  options: Pick<UseQueryOptions<GetArtworksResponse, GetArtworksRequestParams>, 'enabled' | 'keepPreviousData'> = {
    enabled: false,
    keepPreviousData: true,
  }
) =>
  useQuery(artworksQueryKeys.recognitionDataset(slug), () => artworksService.getOneRecognitionDataset({ slug }), {
    ...options,
    onError: () => {},
  })
