import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { DEFAULT_LANG } from 'utils/consts'
import commonPL from './pl/common.json'
import galleriesPL from './pl/galleries.json'
import galleryPL from './pl/gallery.json'
import artworksPL from './pl/artworks.json'
import artworkPL from './pl/artwork.json'
import accountsPL from './pl/accounts.json'
import accountPL from './pl/account.json'
import inquiriesPL from './pl/inquiries.json'
import inquiryPL from './pl/inquiry.json'
import orderPl from './pl/order.json'
import ordersPL from './pl/orders.json'
import artistsPL from './pl/artists.json'
import artistPL from './pl/artist.json'
import upsellsPL from './pl/upsells.json'
import upsellPL from './pl/upsell.json'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  pl: {
    common: commonPL,
    galleries: galleriesPL,
    gallery: galleryPL,
    artworks: artworksPL,
    artwork: artworkPL,
    accounts: accountsPL,
    account: accountPL,
    inquiries: inquiriesPL,
    inquiry: inquiryPL,
    order: orderPl,
    orders: ordersPL,
    artists: artistsPL,
    artist: artistPL,
    upsells: upsellsPL,
    upsell: upsellPL,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: process.env.REACT_APP_LOCALE || DEFAULT_LANG, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
