import { useTranslation } from 'react-i18next'
import { Divider, Grid } from '@chakra-ui/react'
import { PageTemplate } from 'components/PageTemplate'
import { useAccount } from 'services/accounts/accounts.hooks'
import { useParams } from 'react-router-dom'
import { AccountDetailsSection } from './AccountDetailsSection'

export const Account = () => {
  const { t } = useTranslation(['account'])
  const { id } = useParams()

  const { data: accountData } = useAccount(id || '')

  if (!accountData) return null

  return (
    <PageTemplate title={t('account:heading')}>
      <Grid py='58px' templateColumns='1fr 1fr'>
        <AccountDetailsSection account={accountData.data} />
      </Grid>
      <Divider />
    </PageTemplate>
  )
}
