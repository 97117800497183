import { Box, Grid, Spinner, Text } from '@chakra-ui/react'
import { useIsAdmin } from 'hooks/useIsAdmin'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useRecognitionDataset } from 'services/artworks/artworks.hooks'
import { ImageWithBoundingBox } from './ImageWithBoundingBox'

type RecognitionImagesProps = {
  recognitionLabel: string | undefined
}

export const RecognitionImages = ({ recognitionLabel }: RecognitionImagesProps) => {
  const { t } = useTranslation(['artwork', 'common'])
  const { id } = useParams()

  const { isAdmin } = useIsAdmin()

  const { data: dataset, isLoading } = useRecognitionDataset(id || '', { enabled: isAdmin && !!recognitionLabel })
  const entries = dataset?.data.datasetEntries

  if (!isAdmin || !recognitionLabel) return null

  return (
    <Box mt='64px'>
      <Text textStyle='h3'>{t('artwork:recognition.title')}</Text>
      {!entries?.length && <Text textStyle='p'>{t('artwork:recognition.noEntries')}</Text>}
      <Grid
        mt='32px'
        templateColumns={{ base: '1fr 1fr', lg: 'repeat(4, 1fr)' }}
        justifyItems='center'
        alignItems='center'
        gap='24px'
        sx={{
          '& > *': {
            aspectRatio: '1',
          },
        }}
      >
        {entries?.map((entry) => (
          <ImageWithBoundingBox
            key={entry.imageUrl}
            src={entry.imageUrl}
            imageSize={entry.imageSize}
            boundingBox={entry.annotations}
          />
        ))}
        {isLoading && <Spinner size='xl' />}
      </Grid>
    </Box>
  )
}
