import { useTranslation } from 'react-i18next'
import { Grid, Divider, Text, Stack } from '@chakra-ui/react'
import { PageTemplate } from 'components/PageTemplate'
import { InfoRow } from 'components/InfoRow'
import { formatCurrency, formatDate } from 'utils/string/string.utils'
import { OrderItem } from 'components/OrderItem'
import { useParams } from 'react-router-dom'
import { useOrder } from 'services/orders/orders.hooks'

export const Order = () => {
  const { t } = useTranslation(['order', 'common'])
  const { id } = useParams()
  const { data: order } = useOrder(id || '')

  if (!order) return null

  const { positions, orderNumber, createdAt, totalPrice, paymentStatus, deliveryDetails } = order.data

  return (
    <PageTemplate title={t('order:heading')}>
      <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} columnGap='60px' py='58px'>
        <div>
          <Text textStyle='h3' mb='32px'>
            {t('order:orderDetails.heading')}
          </Text>
          <Stack spacing='8px'>
            <InfoRow label={t('order:orderDetails.orderNumber')} value={orderNumber} />
            <InfoRow label={t('order:orderDetails.createdAt')} value={formatDate(createdAt)} />
            <InfoRow label={t('order:orderDetails.price')} value={formatCurrency(totalPrice)} />
            <InfoRow label={t('order:orderDetails.paymentStatus')} value={t(`common:paymentStatus.${paymentStatus}`)} />
          </Stack>
          <Divider mt='32px' mb='64px' />
          <Text textStyle='h3' mb='32px'>
            {t('order:userDetails.heading')}
          </Text>
          {deliveryDetails && (
            <Stack spacing='8px'>
              <InfoRow label={t('order:userDetails.name')} value={deliveryDetails.name} />
              <InfoRow
                label={t('order:userDetails.address')}
                value={`${deliveryDetails.address.street} ${deliveryDetails.address.number}${
                  deliveryDetails.address.apartment ? `/${deliveryDetails.address.apartment}` : ''
                }\n${deliveryDetails.address.postalCode}, ${deliveryDetails.address.city}`}
              />
            </Stack>
          )}
        </div>
        <Divider display={{ base: 'block', lg: 'none' }} mt='32px' mb='64px' />
        <div>
          <Text textStyle='h3' mb='32px'>
            {t('order:items.heading')}
          </Text>
          <Stack spacing='8px'>
            {positions.map(({ items }) =>
              items.map((itemWrapper) => <OrderItem itemWrapper={itemWrapper} key={itemWrapper.item.id} />)
            )}
          </Stack>
        </div>
      </Grid>
    </PageTemplate>
  )
}
