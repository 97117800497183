import { Outlet, Route, Routes } from 'react-router-dom'

import { Upsells } from 'pages/upsells/Upsells'
import { Upsell } from 'pages/upsell/Upsell'

export const UPSELLS_BASE_ROUTE = '/merchandise'

export const UpsellsRouter = () => (
  <>
    <Routes>
      <Route index element={<Upsells />} />
      <Route path=':id' element={<Upsell />} />
    </Routes>
    <Outlet />
  </>
)
