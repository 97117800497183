import { useEffect } from 'react'
import { Message } from 'services/inquiries/inquiries.types'

export const useScrollToBottomOnNewMessage = (
  containerRef: React.MutableRefObject<HTMLDivElement | null>,
  messages: Message[] | undefined
) => {
  const scrollToBottom = () => {
    const refCurrent = containerRef.current

    if (refCurrent) {
      refCurrent.scrollTop = refCurrent.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])
}
