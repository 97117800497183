import { Checkbox as ChakraCheckbox, CheckboxProps as ChakraCheckboxProps, Icon, forwardRef } from '@chakra-ui/react'

type CustomIconProps = {
  isIndeterminate: ChakraCheckboxProps['isIndeterminate']
  isChecked: ChakraCheckboxProps['isChecked']
}

const CustomIcon = ({ isIndeterminate, isChecked }: CustomIconProps) => (
  <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    {isIndeterminate && <path stroke='currentColor' d='M18 12H6' />}
    {isChecked && <path stroke='currentColor' d='M18.5 8L9.28824 17L5 12.6753' />}
  </Icon>
)

export const Checkbox = forwardRef<ChakraCheckboxProps, 'input'>((props, ref) => (
  <ChakraCheckbox
    ref={ref}
    {...props}
    icon={<CustomIcon isIndeterminate={props.isIndeterminate} isChecked={props.isChecked} />}
  />
))
