import React, { useCallback } from 'react'
import { Gallery, BasicUpsell } from 'services/galleries/galleries.types'
import { PreviewTile } from 'components/imagePreviewTile/ImagePreviewTile'
import { ImageSelector } from 'components/imageSelector/ImageSelector'
import { Box, Grid, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useCreateUpsellImage, useDeleteUpsellImage, useUpsellImage } from 'services/galleries/galleries.hooks'

type UpsellImageSectionProps = {
  upsell: BasicUpsell
  galleryId: Gallery['id']
  showLastImageWarningModal: (x: boolean) => void
}

export const UpsellImageSection = ({ upsell, galleryId, showLastImageWarningModal }: UpsellImageSectionProps) => {
  const { t } = useTranslation('upsell')
  const { data: upsellImageData } = useUpsellImage({ slug: upsell.id, galleryId })
  const { mutate: deleteImage } = useDeleteUpsellImage()
  const { mutate: createImage, isLoading: isImageUploading } = useCreateUpsellImage()

  const uploadUpsellImage = useCallback(
    async (acceptedFiles: File[]) => {
      if (upsell) {
        await createImage({ slug: upsell.id, galleryId, file: acceptedFiles[0] })
      }
    },
    [upsell, createImage, galleryId]
  )

  const onImageDelete = () => {
    if (upsell.active) {
      showLastImageWarningModal(true)
    } else {
      deleteImage({ slug: upsell.id, galleryId })
    }
  }

  return (
    <Box>
      {upsellImageData?.data ? (
        <>
          <Text marginTop='20px' marginBottom='8px' fontWeight='600' textStyle='labelS'>
            {t('image.label')}
          </Text>
          <Grid
            templateColumns={{ base: '1fr 1fr', lg: 'repeat(4, 1fr)' }}
            justifyItems='center'
            alignItems='center'
            gap='24px'
            sx={{
              '& > *': {
                aspectRatio: '1',
              },
            }}
          >
            <PreviewTile previewSrc={upsellImageData.data.url} onDelete={onImageDelete} />
          </Grid>
        </>
      ) : (
        <ImageSelector
          marginTop='20px'
          label={t('image.label')}
          isLoading={isImageUploading}
          onDrop={uploadUpsellImage}
          onDelete={onImageDelete}
        />
      )}
    </Box>
  )
}
