import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import { FetchService } from 'services/fetch/fetch.service'
import {
  GetGalleriesRequestParams,
  GetGalleriesResponse,
  GetGalleryImagesParams,
  GetGalleryLogoParams,
  GetGalleryParams,
  GetGalleryResponse,
  GetGalleryUpsellsParams,
  GetPaginatedGalleriesRequestParams,
  GetUpsellImageParams,
  GetUpsellParams,
} from './galleries.types'
import { GalleriesService } from './galleries.service'

const galleriesService = new GalleriesService(FetchService)

export const galleriesQueryKeys = {
  all: ['galleries'] as const,
  one: (slug: GetGalleryParams['slug']) => [...galleriesQueryKeys.all, slug],
  images: (slug: GetGalleryImagesParams['slug']) => [...galleriesQueryKeys.one(slug), 'images'],
  logo: (slug: GetGalleryImagesParams['slug']) => [...galleriesQueryKeys.one(slug), 'logo'],
  filtered: (params?: GetGalleriesRequestParams) => [...galleriesQueryKeys.all, params || ''],
  upsells: ({ slug, ...params }: GetGalleryUpsellsParams) => [...galleriesQueryKeys.one(slug), 'upsells', params || ''],
  upsell: (params: GetUpsellParams) => [...galleriesQueryKeys.upsells({ slug: params.slug }), params.galleryId],
  upsellImage: (params: GetUpsellImageParams) => [
    ...galleriesQueryKeys.upsell({ slug: params.slug, galleryId: params.galleryId }),
    'image',
  ],
}

export const useGalleries = (
  params?: GetGalleriesRequestParams,
  options: Pick<UseQueryOptions<GetGalleryResponse, GetGalleriesRequestParams>, 'enabled' | 'keepPreviousData'> = {
    enabled: true,
    keepPreviousData: true,
  }
) => useQuery(galleriesQueryKeys.filtered(params), () => galleriesService.getAll(params), options)

export const useGallery = (slug: GetGalleryParams['slug']) =>
  useQuery(galleriesQueryKeys.one(slug || ''), () => galleriesService.getOne({ slug }), {
    enabled: !!slug,
  })

export const useUpdateGallery = () => {
  const queryClient = useQueryClient()

  return useMutation(galleriesService.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(galleriesQueryKeys.all)
    },
  })
}

export const useCreateVenue = () => {
  const queryClient = useQueryClient()

  return useMutation(galleriesService.createVenue, {
    onSuccess: (_, { galleryId }) => {
      queryClient.invalidateQueries(galleriesQueryKeys.one(galleryId))
    },
  })
}

export const useUpdateVenue = () => {
  const queryClient = useQueryClient()

  return useMutation(galleriesService.updateVenue, {
    onSuccess: (_, { galleryId }) => {
      queryClient.invalidateQueries(galleriesQueryKeys.one(galleryId))
    },
  })
}

export const useDeleteVenue = () => {
  const queryClient = useQueryClient()

  return useMutation(galleriesService.deleteVenue, {
    onSuccess: (_, { galleryId }) => {
      queryClient.invalidateQueries(galleriesQueryKeys.one(galleryId))
    },
  })
}

export const useGalleryImages = (slug: GetGalleryImagesParams['slug']) => {
  return useQuery(galleriesQueryKeys.images(slug), () => galleriesService.getAllImages({ slug }))
}

export const useCreateGalleryImage = () => {
  const queryClient = useQueryClient()

  return useMutation(galleriesService.createImage, {
    onSuccess: (_, { slug }) => {
      queryClient.invalidateQueries(galleriesQueryKeys.images(slug))
    },
  })
}

export const useDeleteGalleryImage = () => {
  const queryClient = useQueryClient()

  return useMutation(galleriesService.deleteImage, {
    onSuccess: (_, { slug }) => {
      queryClient.invalidateQueries(galleriesQueryKeys.images(slug))
    },
  })
}

export const useGalleryLogo = (slug: GetGalleryLogoParams['slug']) => {
  return useQuery(galleriesQueryKeys.logo(slug), () => galleriesService.getLogo({ slug }))
}

export const useCreateGalleryLogo = () => {
  const queryClient = useQueryClient()

  return useMutation(galleriesService.createLogo, {
    onSuccess: (_, { slug }) => {
      queryClient.invalidateQueries(galleriesQueryKeys.logo(slug))
    },
  })
}

export const useDeleteGalleryLogo = () => {
  const queryClient = useQueryClient()

  return useMutation(galleriesService.deleteLogo, {
    onSuccess: (_, { slug }) => {
      queryClient.invalidateQueries(galleriesQueryKeys.logo(slug))
    },
  })
}

export const useCreateGallery = () => {
  const queryClient = useQueryClient()

  return useMutation(galleriesService.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(galleriesQueryKeys.all)
    },
  })
}

export const useToggleStatus = () => {
  const queryClient = useQueryClient()

  return useMutation(galleriesService.updateActiveStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(galleriesQueryKeys.all)
    },
  })
}

export const usePaginatedGalleries = (
  params: GetPaginatedGalleriesRequestParams,
  options: Pick<
    UseInfiniteQueryOptions<GetGalleriesResponse, GetPaginatedGalleriesRequestParams>,
    'keepPreviousData' | 'onSuccess'
  > = {
    keepPreviousData: true,
  }
) =>
  useInfiniteQuery(
    galleriesQueryKeys.filtered(params),
    ({ pageParam = 0 }) =>
      galleriesService.getAllPaginated({
        ...params,
        offset: pageParam * params.limit,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = lastPage.meta.total / params.limit
        const nextPage = allPages.length

        return nextPage <= maxPages ? nextPage : undefined
      },
      ...options,
    }
  )

export const useGalleryUpsells = (params: GetGalleryUpsellsParams) =>
  useQuery(galleriesQueryKeys.upsells(params), () => galleriesService.getAllUpsells(params))

export const useCreateUpsell = () => {
  const queryClient = useQueryClient()

  return useMutation(galleriesService.createUpsell, {
    onSuccess: (_, { galleryId }) => {
      queryClient.invalidateQueries(galleriesQueryKeys.upsells({ slug: galleryId }))
    },
  })
}

export const useUpsell = ({ slug, galleryId }: GetUpsellParams) =>
  useQuery(galleriesQueryKeys.upsell({ slug, galleryId }), () => galleriesService.getOneUpsell({ slug, galleryId }), {
    enabled: !!slug,
  })

export const useUpdateUpsell = () => {
  const queryClient = useQueryClient()

  return useMutation(galleriesService.updateUpsell, {
    onSuccess: (_, { payload, galleryId }) => {
      queryClient.invalidateQueries(galleriesQueryKeys.upsells({ slug: galleryId || '' }))
      queryClient.invalidateQueries(galleriesQueryKeys.upsell({ slug: payload.id, galleryId: galleryId || '' }))
    },
  })
}

export const useToggleUpsellStatus = () => {
  const queryClient = useQueryClient()

  return useMutation(galleriesService.updateUpsellActiveStatus, {
    onSuccess: (_, { galleryId, slug }) => {
      queryClient.invalidateQueries(galleriesQueryKeys.upsells({ slug: galleryId }))
      queryClient.invalidateQueries(galleriesQueryKeys.upsell({ slug, galleryId }))
    },
  })
}

export const useUpsellImage = ({ slug, galleryId }: GetUpsellImageParams) => {
  return useQuery(galleriesQueryKeys.upsellImage({ slug, galleryId }), () =>
    galleriesService.getUpsellImage({ slug, galleryId })
  )
}

export const useCreateUpsellImage = () => {
  const queryClient = useQueryClient()

  return useMutation(galleriesService.createUpsellImage, {
    onSuccess: (_, { slug, galleryId }) => {
      queryClient.invalidateQueries(galleriesQueryKeys.upsellImage({ slug, galleryId: galleryId || '' }))
    },
  })
}

export const useDeleteUpsellImage = () => {
  const queryClient = useQueryClient()

  return useMutation(galleriesService.deleteUpsellImage, {
    onSuccess: (_, { slug, galleryId }) => {
      queryClient.invalidateQueries(galleriesQueryKeys.upsellImage({ slug, galleryId: galleryId || '' }))
    },
  })
}
