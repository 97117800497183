import { Stack } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useValidationSchema } from 'hooks/useValidationSchema'
import { Artist } from 'services/artists/artists.types'
import { useUpdateArtist } from 'services/artists/artists.hooks'
import { handleSuccess } from 'utils/handleSuccess.utlis'
import { Input } from 'components/input/Input'
import { Textarea } from 'components/textarea/Textarea'
import { Button } from 'components/button/Button'
import { ArtistDetailsFormValues, artistDetailsValidationSchema } from './Artist.utils'

type ArtistDetailFormProps = {
  artist: Artist
}

export const ArtistDetailForm = ({ artist }: ArtistDetailFormProps) => {
  const { t } = useTranslation(['artist', 'common'])
  const { mutate: updateArtist } = useUpdateArtist()
  const schema = useValidationSchema(artistDetailsValidationSchema)
  const defaultValues = useMemo(
    () => ({
      name: artist.name || '',
      description: artist.description || '',
    }),
    [artist]
  )

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<ArtistDetailsFormValues>({
    defaultValues,
    resolver: schema,
  })

  const onSubmit = (values: ArtistDetailsFormValues) => {
    updateArtist(
      { id: artist.id, ...values },
      {
        onSuccess: () => handleSuccess(t('artist:updateSuccessMessage')),
      }
    )
  }

  return (
    <Stack as='form' direction='column' spacing='20px' onSubmit={handleSubmit(onSubmit)}>
      <Input
        error={errors.name}
        label={t('artist:details.name.label')}
        inputProps={{
          placeholder: t('artist:details.name.placeholder'),
          ...register('name'),
        }}
      />
      <Textarea
        error={errors.description}
        label={t('artist:details.description.label')}
        inputProps={{
          placeholder: t('artist:details.description.placeholder'),
          ...register('description'),
        }}
      />
      <Button type='submit' alignSelf='flex-end' ml='8px' isLoading={isSubmitting}>
        {t('common:save')}
      </Button>
    </Stack>
  )
}
