const foundation = {
  dark: '#212121',
  light: '#F6F6F6',
  grey: '#DEDEDE',
  greyDark: '#AAAAAA',
  black: '#000',
  accent: '#B89767',
  red: '#C62323',
  sage: '#EEF3EE',
  green: '#DBE7DB',
  yellow: '#FDFF87',
}

export const semanticColors = {
  bg: {
    default: foundation.sage,
  },

  bgLight: {
    default: foundation.light,
    _dark: foundation.dark,
  },

  bgGrey: {
    default: foundation.grey,
  },

  bgDark: {
    default: foundation.dark,
    _dark: foundation.light,
  },
  bgDarkHover: {
    default: '#353535',
  },

  bgHero: {
    default: '#D8E9ED',
  },

  textLight: {
    default: foundation.light,
    _dark: foundation.dark,
  },
  textMain: {
    default: foundation.dark,
    _dark: foundation.light,
  },
  textAccent: {
    default: foundation.accent,
    _dark: foundation.accent,
  },
  textDescription: {
    default: '#767676',
  },
  textSub: {
    default: foundation.greyDark,
  },
  textPlaceholder: {
    default: '#858585',
    _dark: foundation.accent,
  },

  link: {
    default: 'black',
    _dark: 'white',
  },

  borderLight: {
    default: foundation.black,
    _dark: foundation.light,
  },

  borderGray: {
    default: foundation.greyDark,
  },

  separator: {
    default: foundation.black,
  },

  error: {
    default: foundation.red,
  },

  success: {
    default: foundation.green,
  },
}
