import { ComponentStyleConfig } from '@chakra-ui/react'

export const Modal: ComponentStyleConfig = {
  parts: ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'],
  defaultProps: {
    size: '3xl',
  },
  baseStyle: {
    dialog: {
      borderRadius: 'none',
      p: '40px',
      bgColor: 'bg',
    },
  },
}
