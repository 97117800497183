import { ButtonProps } from '@chakra-ui/react'

import { Button } from 'components/button/Button'
import { IconCopy } from 'components/icons'

type CopyButtonProps = ButtonProps & {
  'aria-label': string
}

export const CopyButton = ({ color = 'textMain', ...props }: CopyButtonProps) => (
  <Button leftIcon={<IconCopy />} variant='ghost' color={color} w='auto' minW='unset' h='auto' p='10px' {...props} />
)
