import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Center, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/button/Button'

type PaginationProps = {
  currentPage: number
  pageCount: number
  setPreviousPage: () => void
  nextEnabled: boolean
  previousEnabled: boolean
  setNextPage: () => void
}

export const Pagination = ({
  currentPage,
  pageCount,
  setPreviousPage,
  nextEnabled,
  previousEnabled,
  setNextPage,
}: PaginationProps) => {
  const { t } = useTranslation('common')

  if (pageCount <= 1) return null

  return (
    <Flex>
      <Button onClick={setPreviousPage} isDisabled={!previousEnabled} variant='ghost' border='1px'>
        <ChevronLeftIcon color={previousEnabled ? 'black' : 'textPlaceholder'} />
      </Button>
      <Center width='53px'>
        <Text textStyle='labelM' color='textMain'>
          {currentPage + 1}
        </Text>
        <Text textStyle='labelM' color='textMain' mx='4px'>
          {t('pagination.of')}
        </Text>
        <Text textStyle='labelM' color='textMain'>
          {pageCount}
        </Text>
      </Center>
      <Button onClick={setNextPage} isDisabled={!nextEnabled} variant='ghost' border='1px'>
        <ChevronRightIcon color={nextEnabled ? 'black' : 'textPlaceholder'} />
      </Button>
    </Flex>
  )
}
