import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Grid, Divider, Text } from '@chakra-ui/react'
import { PageTemplate } from 'components/PageTemplate'
import {
  useCreateGalleryImage,
  useDeleteGalleryImage,
  useGallery,
  useGalleryImages,
  useToggleStatus,
} from 'services/galleries/galleries.hooks'
import { ImageSelector } from 'components/imageSelector/ImageSelector'
import { useCallback, useState } from 'react'
import { QRCodeGeneration } from 'components/QRCodeGeneration/QRCodeGeneration'
import { Button } from 'components/button/Button'
import { ConfirmModal } from 'components/confirmModal/ConfirmModal'
import { handleSuccess } from 'utils/handleSuccess.utlis'
import { getErrorMessage, handleError } from 'utils/handleError.utils'
import { useIsAdmin } from 'hooks/useIsAdmin'
import { GalleryVenuesSection } from './GalleryVenuesSection'
import { GalleryDetailsSection } from './GalleryDetailsSection'
import { GalleryLogoSection } from './GalleryLogoSection'
import { ConfirmationMessage } from './ConfirmationMessage/ConfirmationMessage'

export const Gallery = () => {
  const { t } = useTranslation(['gallery', 'common'])
  const { id } = useParams()
  const { data: gallery } = useGallery(id || '')
  const { isAdmin } = useIsAdmin()
  const { data: galleryImages, isLoading: isImagesLoading } = useGalleryImages(id || '')
  const { mutate: createImage, isLoading: isImagesUploading } = useCreateGalleryImage()
  const { mutate: deleteImage } = useDeleteGalleryImage()
  const { mutate: updateStatus } = useToggleStatus()

  const [isGalleryStatusConfirmationModalOpen, setIsGalleryStatusConfirmationModalOpen] = useState(false)
  const [isImageDeletionWarningModalOpen, setIsImageDeletionWarningModalOpen] = useState(false)

  const uploadGalleryImage = useCallback(
    async (acceptedFiles: File[]) => {
      if (galleryImages && gallery) {
        const position =
          galleryImages.data.length === 0 ? 0 : galleryImages.data[galleryImages.data.length - 1].position + 1
        await createImage({ slug: gallery.data.id, file: acceptedFiles[0], position })
      }
    },
    [gallery, createImage, galleryImages]
  )

  const updateActivityStatus = () => {
    if (!id || !gallery?.data) return

    updateStatus(
      { slug: id, active: !gallery.data.active },
      {
        onSuccess: () => {
          handleSuccess(
            gallery.data.active ? t('gallery:onStatusChange.disabled') : t('gallery:onStatusChange.activated')
          )
        },
        onError: (err) => {
          const errorMessage = getErrorMessage(err)

          if (errorMessage) {
            handleError(errorMessage)
          } else {
            handleError(t('gallery:updateError'))
          }
        },
      }
    )
  }

  if (!gallery) return null

  const onImageDelete = (imageId: string) => {
    if (galleryImages?.data.length === 1 && gallery.data.active) {
      setIsImageDeletionWarningModalOpen(true)
    } else {
      deleteImage({ slug: gallery.data.id, imageId })
    }
  }

  return (
    <>
      <PageTemplate title={t('gallery:heading')}>
        <Grid templateColumns='1fr 1fr' columnGap='60px' py='58px'>
          <div>
            <GalleryDetailsSection gallery={gallery.data} />
            <Divider mt='32px' mb='64px' />
            <GalleryVenuesSection venues={gallery.data.venues} />
          </div>
          <div>
            <ImageSelector
              onDrop={uploadGalleryImage}
              isLoading={isImagesLoading || isImagesUploading}
              images={galleryImages?.data}
              onDelete={onImageDelete}
            />
            <GalleryLogoSection gallery={gallery.data} />
            {isAdmin && (
              <Button mt='30px' onClick={() => setIsGalleryStatusConfirmationModalOpen(true)}>
                {gallery.data.active
                  ? t('gallery:changeGalleryStatus.disable')
                  : t('gallery:changeGalleryStatus.active')}
              </Button>
            )}
            <QRCodeGeneration
              value={`${process.env.REACT_APP_BASE_APP_URL}/galerie/${gallery.data.id}`}
              name={gallery.data.name}
              qrDescription={t('gallery:downloadQR')}
            />
          </div>
        </Grid>
      </PageTemplate>
      <ConfirmModal
        closeButtonAriaLabel={t('gallery:closeConfirmationGalleryModalAriaLabel')}
        isOpen={isGalleryStatusConfirmationModalOpen}
        onClose={() => setIsGalleryStatusConfirmationModalOpen(false)}
        title={t('gallery:activityStatus.title')}
        onConfirm={updateActivityStatus}
      >
        <ConfirmationMessage activeStatus={gallery.data.active} />
      </ConfirmModal>
      <ConfirmModal
        closeButtonAriaLabel={t('common:closeModalButtonAriaLabel')}
        isOpen={isImageDeletionWarningModalOpen}
        onClose={() => setIsImageDeletionWarningModalOpen(false)}
        title={t('gallery:lastImageInformation.title')}
        onConfirm={() => setIsImageDeletionWarningModalOpen(false)}
      >
        <Text>{t('gallery:lastImageInformation.description')}</Text>
      </ConfirmModal>
    </>
  )
}
