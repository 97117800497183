import { inquiriesQueryKeys } from 'services/inquiries/inquiries.hooks'
import { useMutation, useQueryClient } from 'react-query'
import dayjs from 'dayjs'

import { FetchService } from 'services/fetch/fetch.service'
import { GetInquiryResponse, Message } from 'services/inquiries/inquiries.types'
import { useUser } from 'services/auth/auth.hooks'
import { MessagesService } from './messages.services'

const messageService = new MessagesService(FetchService)

export const useSendMessage = () => {
  const queryClient = useQueryClient()
  const { data: user } = useUser({ retry: false })

  return useMutation(messageService.sendOne, {
    onMutate: ({ content, inquiryId }) => {
      const queryKey = inquiriesQueryKeys.one(inquiryId)
      queryClient.cancelQueries(queryKey)
      const previousData = queryClient.getQueryData<GetInquiryResponse>(queryKey)

      if (user) {
        const newMessage: Message = {
          accountId: user.data.id,
          content,
          createdAt: dayjs().toISOString(),
          readAt: '',
        }

        if (previousData) {
          queryClient.setQueryData(queryKey, {
            data: { ...previousData.data, messages: [...previousData.data.messages, newMessage] },
          })
        }
      }
    },
    onError: async (_, { inquiryId }) => {
      queryClient.invalidateQueries(inquiriesQueryKeys.one(inquiryId))
    },
  })
}
