import { ComponentStyleConfig } from '@chakra-ui/react'
import { textStyles } from './Text.theme'

export const FormControl: ComponentStyleConfig = {
  parts: ['helperText'],
  baseStyle: {
    helperText: {
      ...textStyles.labelS,
      color: 'borderGray',
      opacity: 1,
      mt: 0,
    },
  },
}
