import { Flex } from '@chakra-ui/react'
import { Button } from 'components/button/Button'
import { Input } from 'components/input/Input'
import { Select } from 'components/select/Select'
import { Textarea } from 'components/textarea/Textarea'
import { useValidationSchema } from 'hooks/useValidationSchema'
import { debounce } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePaginatedArtworks } from 'services/artworks/artworks.hooks'
import { Gallery, BasicUpsell } from 'services/galleries/galleries.types'
import { useUpdateUpsell } from 'services/galleries/galleries.hooks'
import { DEFAULT_PAGE_SIZE } from 'utils/consts'
import { handleSuccess } from 'utils/handleSuccess.utlis'
import { upsellFormValidationSchema, UpsellFormValues } from './Upsell.utils'

type UpsellDetailsFormProps = {
  upsell: BasicUpsell
  galleryId: Gallery['id']
}

const PAGE_SIZE = DEFAULT_PAGE_SIZE

export const UpsellDetailsSection = ({ upsell, galleryId }: UpsellDetailsFormProps) => {
  const { t } = useTranslation(['upsell', 'common'])

  const [searchInputValue, setSearchInputValue] = useState('')

  const schema = useValidationSchema(upsellFormValidationSchema)
  const { mutate: updateUpsell } = useUpdateUpsell()
  const {
    data: artworksData,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = usePaginatedArtworks({
    gallery_in: galleryId,
    search: searchInputValue,
    limit: PAGE_SIZE,
  })

  const handleOnMenuScrollToBottom = () => {
    if (!isFetching && hasNextPage) fetchNextPage()
  }

  const defaultValues = useMemo(() => {
    return {
      name: upsell.name || '',
      description: upsell.description || '',
      price: upsell.price || 0,
      image: upsell.image,
      category: upsell.category || '',
      manufacturer: upsell.manufacturer || '',
      artworkId: upsell.artworkId || '',
    }
  }, [upsell])
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
  } = useForm<UpsellFormValues>({ defaultValues, resolver: schema })

  const onSubmit = (values: UpsellFormValues) => {
    updateUpsell(
      {
        payload: {
          ...values,
          id: upsell.id,
        },
        galleryId,
      },
      {
        onSuccess: (data) => {
          reset(data.data)
          handleSuccess(t('upsell:updateSuccessMessage'))
        },
      }
    )
  }

  const formattedArtworks = useMemo(
    () => [
      { label: t('upsell:noRelatedArtwork'), value: '' },
      ...(artworksData?.pages
        ? artworksData.pages
            .map((page) =>
              page.data.map(({ name, id }) => {
                return { label: name, value: id }
              })
            )
            .flat()
        : []),
    ],
    [artworksData, t]
  )

  const debouncedInputChange = useCallback(debounce(setSearchInputValue, 500), [])

  return (
    <Flex direction='column' as='form' onSubmit={handleSubmit(onSubmit)}>
      <Input
        mb='20px'
        error={errors.name}
        label={t('upsell:details.name.label')}
        inputProps={{
          placeholder: t('upsell:details.name.placeholder'),
          ...register('name'),
        }}
      />
      <Textarea
        mb='20px'
        label={t('upsell:details.description.label')}
        inputProps={{
          placeholder: t('upsell:details.description.placeholder'),
          ...register('description'),
        }}
      />
      <Input
        mb='20px'
        error={errors.price}
        label={t('upsell:details.price.label')}
        inputProps={{
          placeholder: t('upsell:details.price.placeholder'),
          ...register('price'),
        }}
      />
      <Input
        mb='20px'
        label={t('upsell:details.category.label')}
        inputProps={{
          placeholder: t('upsell:details.category.placeholder'),
          ...register('category'),
        }}
      />
      <Input
        mb='20px'
        label={t('upsell:details.manufacturer.label')}
        inputProps={{
          placeholder: t('upsell:details.manufacturer.placeholder'),
          ...register('manufacturer'),
        }}
      />
      <Controller
        control={control}
        defaultValue=''
        name='artworkId'
        render={({ fieldState: { error }, field: { onChange, value: currInputValue } }) => (
          <Select
            label={t('upsell:relatedArtwork')}
            options={formattedArtworks}
            value={formattedArtworks?.find((el) => el.value === currInputValue)}
            onChange={(newValue) => onChange(newValue?.value)}
            onInputChange={debouncedInputChange}
            placeholder={t('upsell:selectRelatedArtwork')}
            error={error}
            formControlProps={{
              mb: '20px',
            }}
            isLoading={isFetching || isLoading}
            isDisabled={isLoading}
            onMenuScrollToBottom={handleOnMenuScrollToBottom}
          />
        )}
      />
      <Button type='submit' alignSelf='flex-end' ml='8px' isLoading={isSubmitting}>
        {t('common:save')}
      </Button>
    </Flex>
  )
}
