import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

import { REFRESH_TOKEN_ERROR, REQUEST_TIMEOUT } from 'utils/consts'
import { AuthService } from 'services/auth/auth.service'
import { cookies } from 'utils/cookies.utils'
import { createErrorInterceptor, createRequestInterceptor, createResponseInterceptor } from './interceptors'

type CreateAxiosInstanceParams = Partial<AxiosRequestConfig> & {
  token?: string
  handleError?: (error: AxiosError) => PromiseRejectionEvent
  // prefix to append to actual API URL
  urlPrefix?: string
}

export const API_VERSION = 'v1'
export const BASE_API_URL = `${process.env.REACT_APP_API_URL}/${API_VERSION}`
const ADMIN_API_URL = `${BASE_API_URL}/admin`

const createFetchServiceInstance = ({
  baseURL,
  data,
  method,
  timeout,
  url,
  handleError,
  urlPrefix = '',
}: CreateAxiosInstanceParams = {}): AxiosInstance => {
  const options: AxiosRequestConfig = {
    baseURL: (baseURL || ADMIN_API_URL) + urlPrefix,
    data,
    method,
    timeout: timeout || REQUEST_TIMEOUT,
    url,
  }

  const axiosInstance = Axios.create(options)

  const authService = new AuthService(axiosInstance)

  createAuthRefreshInterceptor(
    axiosInstance,
    // eslint-disable-next-line consistent-return
    async (failedRequest: any) => {
      const refreshToken = cookies.get(AuthService.REFRESH_TOKEN_COOKIE_NAME)

      if (refreshToken) {
        try {
          const { accessToken } = await authService.refreshToken(refreshToken)

          // eslint-disable-next-line no-param-reassign
          failedRequest.response.config.headers[AuthService.AUTH_HEADER_NAME] = AuthService.getAuthHeader(accessToken)

          return Promise.resolve() // eslint-disable-line @typescript-eslint/return-await
        } catch (e) {
          authService.removeCookies()

          throw new Error(REFRESH_TOKEN_ERROR)
        }
      }
    },
    {
      pauseInstanceWhileRefreshing: true,
    }
  )

  // Request handling
  axiosInstance.interceptors.request.use(
    createRequestInterceptor(),
    createErrorInterceptor({ handleError, axiosInstance })
  )
  // Response handling
  axiosInstance.interceptors.response.use(
    createResponseInterceptor(),
    createErrorInterceptor({ handleError, axiosInstance })
  )

  return axiosInstance
}

export const FetchService = createFetchServiceInstance()
