import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Flex } from '@chakra-ui/react'

import { Input } from 'components/input/Input'
import { Textarea } from 'components/textarea/Textarea'
import { Button } from 'components/button/Button'
import { Gallery } from 'services/galleries/galleries.types'

import { useValidationSchema } from 'hooks/useValidationSchema'
import { useUpdateGallery } from 'services/galleries/galleries.hooks'
import { getErrorMessage, handleError } from 'utils/handleError.utils'
import { handleSuccess } from 'utils/handleSuccess.utlis'
import { GalleryDetailsFormValues, galleryDetailsValidationSchema } from './Gallery.utils'

type GalleryDetailsSectionProps = {
  gallery: Gallery
}

export const GalleryDetailsSection = ({ gallery }: GalleryDetailsSectionProps) => {
  const { t } = useTranslation(['gallery', 'common'])

  const { mutate: updateGallery } = useUpdateGallery()

  const defaultValues = useMemo(
    () =>
      gallery
        ? {
            name: gallery.name,
            description: gallery.description,
            deliveryPrice: gallery.deliveryPrice,
          }
        : {},
    [gallery]
  )

  const schema = useValidationSchema(galleryDetailsValidationSchema)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<GalleryDetailsFormValues>({ defaultValues, resolver: schema })

  const onSubmit = (values: GalleryDetailsFormValues) => {
    updateGallery(
      { id: gallery.id, ...values },
      {
        onSuccess: () => {
          handleSuccess(t('gallery:updateSuccessMessage'))
        },
        onError: (err) => {
          const errorMessage = getErrorMessage(err)

          if (errorMessage) {
            handleError(errorMessage)
          } else {
            handleError(t('gallery:updateError'))
          }
        },
      }
    )
  }

  return (
    <Flex as='form' direction='column' onSubmit={handleSubmit(onSubmit)}>
      <Input
        id='name'
        mb='20px'
        error={errors.name}
        label={t('gallery:details.name.label')}
        inputProps={{
          placeholder: t('gallery:details.name.placeholder'),
          ...register('name'),
        }}
      />
      <Textarea
        id='description'
        mb='32px'
        label={t('gallery:details.description.label')}
        inputProps={{
          placeholder: t('gallery:details.description.placeholder'),
          ...register('description'),
        }}
      />
      <Input
        id='deliveryPrice'
        mb='20px'
        error={errors.deliveryPrice}
        label={t('gallery:details.deliveryPrice.label')}
        inputProps={{
          placeholder: t('gallery:details.deliveryPrice.placeholder'),
          ...register('deliveryPrice'),
        }}
      />
      <Button type='submit' alignSelf='flex-end' ml='8px' isLoading={isSubmitting}>
        {t('common:save')}
      </Button>
    </Flex>
  )
}
