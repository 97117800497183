import dayjs from 'dayjs'
import { TFunction } from 'react-i18next'
import { object, string, number } from 'yup'
import { Artwork } from 'services/artworks/artworks.types'
import { SelectOption } from 'types/common'

export const activeArtworkDetailsValidationSchema = (t: TFunction) => {
  const currentYear = dayjs().year()

  return object({
    name: string().required(t('validation.required')),
    description: string().required(t('validation.required')),
    year: number()
      .integer(t('validation.number.invalidFormat'))
      .typeError(t('validation.number.invalidFormat'))
      .min(1, ({ min }) => t('validation.number.min', { min }))
      .max(currentYear, ({ max }) => t('validation.number.max', { max })),
    technique: string().required(t('validation.required')),
    dimensions: object({
      width: number()
        .typeError(t('validation.invalidFormat'))
        .moreThan(0, ({ more }) => t('validation.number.moreThan', { more })),
      height: number()
        .typeError(t('validation.invalidFormat'))
        .moreThan(0, ({ more }) => t('validation.number.moreThan', { more })),
    }),
    artMovement: object({
      label: string(),
      value: string(),
    })
      .typeError(t('validation.required'))
      .required(t('validation.required')),
    artworkType: object({
      label: string(),
      value: string(),
    })
      .typeError(t('validation.required'))
      .required(t('validation.required')),
    price: number().typeError(t('validation.invalidFormat')),
    artist: object({
      label: string(),
      value: string(),
    })
      .typeError(t('validation.required'))
      .required(t('validation.required')),
  })
}

export const disabledArtworkDetailsValidationSchema = (t: TFunction) =>
  object({
    name: string().required(t('validation.required')),
  })

export type ArtworkDetailsFormValues = {
  name: string
  description: string
  year: string
  technique: string
  dimensions: {
    width: string
    height: string
  }
  artworkType: SelectOption | null
  artMovement: SelectOption | null
  price: string
  artist: SelectOption | null
  featured: boolean
  recognitionLabel?: string
}

export const getFeaturedValue = (isFeatured: boolean) => {
  if (isFeatured) {
    return dayjs().format()
  }

  return null
}

export const getDefaultValues = (artwork: Partial<Artwork>): ArtworkDetailsFormValues => ({
  name: artwork.name || '',
  description: artwork.description || '',
  year: artwork?.year?.toString() || '',
  technique: artwork.technique || '',
  dimensions: {
    width: artwork?.dimensions?.width.toString() || '',
    height: artwork?.dimensions?.height.toString() || '',
  },
  artworkType:
    artwork.artworkTypeId && artwork.artworkType ? { label: artwork.artworkType, value: artwork.artworkTypeId } : null,
  artMovement:
    artwork.artMovementId && artwork.artMovement ? { label: artwork.artMovement, value: artwork.artMovementId } : null,
  price: artwork?.price?.toString() || '',
  featured: !!artwork.featured,
  artist: artwork.artist ? { label: artwork.artist.name, value: artwork.artist.id } : null,
  recognitionLabel: artwork?.recognitionLabel,
})
