import 'focus-visible/dist/focus-visible'
import { useState } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClientProvider } from 'react-query'
import 'dayjs/locale/pl'
import { Router } from 'routing/Router'
import { theme } from 'theme/theme'
import { createClient } from 'utils/reactQuery.utils'
import dayjs from 'dayjs'

dayjs.locale(process.env.REACT_APP_LOCALE || 'pl')

export const App = () => {
  const [queryClient] = useState(() => createClient())

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <Router />
      </ChakraProvider>
    </QueryClientProvider>
  )
}
