import { Icon, IconProps } from '@chakra-ui/react'

const SvgIconArrow = (props: IconProps) => {
  return (
    // @ts-ignore Needed because of Emotion versions mismatch
    <Icon viewBox='0 0 24 24' height='24px' width='24px' data-testid='SvgIconArrow' {...props}>
      <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='m8 11 4 4 4-4' stroke='currentColor' strokeLinecap='square' strokeLinejoin='round' />
      </svg>
    </Icon>
  )
}

export default SvgIconArrow
