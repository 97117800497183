import { Flex } from '@chakra-ui/react'
import { Button } from 'components/button/Button'
import { Input } from 'components/input/Input'
import { useIsAdmin } from 'hooks/useIsAdmin'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useUpdateArtwork } from 'services/artworks/artworks.hooks'
import { Artwork } from 'services/artworks/artworks.types'
import { handleSuccess } from 'utils/handleSuccess.utlis'
import { getErrorMessage, handleError } from 'utils/handleError.utils'
import { getDefaultValues } from './Artwork.utils'

type RecognitionLabelInputProps = {
  artwork: Partial<Artwork> & { name: Artwork['name']; id: Artwork['id'] }
}

export const RecognitionLabelInput = ({ artwork }: RecognitionLabelInputProps) => {
  const { isAdmin } = useIsAdmin()

  const { t } = useTranslation(['common', 'artwork'])

  const { mutate: updateArtwork } = useUpdateArtwork()
  const defaultValues = getDefaultValues(artwork)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<{ recognitionLabel: string }>({
    defaultValues,
  })

  const onSubmit = ({ recognitionLabel }: { recognitionLabel: string }) => {
    updateArtwork(
      {
        ...artwork,
        artistId: artwork?.artist?.id || '',
        recognitionLabel,
      },
      {
        onSuccess: ({ data }) => {
          handleSuccess(t('artwork:updateSuccessMessage', { name: data.name }))
        },
        onError: (err) => {
          const errorMessage = getErrorMessage(err)

          if (errorMessage) {
            handleError(errorMessage)
          } else {
            handleError(t('artwork:updateError'))
          }
        },
      }
    )
  }

  return (
    <Flex as='form' onSubmit={handleSubmit(onSubmit)} direction='column' mt='64px'>
      <Input
        mb='20px'
        error={errors.recognitionLabel}
        label={t('artwork:details.recognitionLabel.label')}
        inputProps={{
          placeholder: t('artwork:details.recognitionLabel.placeholder'),
          ...register('recognitionLabel'),
        }}
      />
      <Button alignSelf='flex-start' type='submit' isLoading={isSubmitting} disabled={!isAdmin}>
        {t('common:save')}
      </Button>
    </Flex>
  )
}
