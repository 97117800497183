import { useState } from 'react'
import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Venue } from 'services/galleries/galleries.types'
import { formatAddress } from 'utils/string/string.utils'
import { CloseButton } from 'components/CloseButton'
import IconPlus from 'components/icons/IconPlus'
import { useDeleteVenue } from 'services/galleries/galleries.hooks'
import { ConfirmModal } from 'components/confirmModal/ConfirmModal'

type GalleryVenueTileProps = {
  venue: Venue
  onClick: () => void
}

type TileContainerProps = FlexProps

type AddNewVenueProps = {
  onClick: () => void
}

const TileContainer = ({ children, ...props }: TileContainerProps) => (
  <Flex
    p='16px 8px'
    alignItems='center'
    borderTopWidth='1px'
    borderBottomWidth='1px'
    mt='-1px'
    borderColor='separator'
    cursor='pointer'
    {...props}
  >
    {children}
  </Flex>
)

export const AddNewVenue = ({ onClick }: AddNewVenueProps) => {
  return (
    <TileContainer
      h='82px'
      justifyContent='center'
      _hover={{
        '& > svg': {
          transform: 'rotate(90deg)',
        },
      }}
      onClick={onClick}
    >
      <IconPlus transition='transform .2s' />
    </TileContainer>
  )
}

export const GalleryVenueTile = ({ venue, onClick }: GalleryVenueTileProps) => {
  const { t } = useTranslation(['gallery', 'common'])

  const { id: galleryId = '' } = useParams()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = () => setIsModalOpen(false)

  const { mutate: deleteVenue } = useDeleteVenue()

  const { id, name, address } = venue

  const formattedAddress = formatAddress(address)

  return (
    <>
      <TileContainer
        justifyContent='space-between'
        _hover={{
          '& > button': {
            opacity: 1,
          },
        }}
        onClick={onClick}
      >
        <Flex direction='column'>
          <Text textStyle='labelL'>{name}</Text>
          <Text textStyle='labelM'>{formattedAddress}</Text>
        </Flex>
        <CloseButton
          aria-label={t('gallery:venues.deleteVenueAriaLabel')}
          onClick={(e) => {
            e.stopPropagation()
            setIsModalOpen(true)
          }}
          opacity={0}
        />
      </TileContainer>
      <ConfirmModal
        onConfirm={() => {
          deleteVenue(
            { galleryId, venue: { id } },
            {
              onSuccess: () => {
                closeModal()
              },
            }
          )
        }}
        confirmButtonLabel={t('common:ok')}
        closeButtonAriaLabel={t('gallery:venues.closeModalAriaLabel')}
        title={t('gallery:venues.deleteVenueTitle')}
        onClose={closeModal}
        isOpen={isModalOpen}
      >
        <Text textStyle='labelM' mb='48px'>
          {t('gallery:venues.deleteVenueDescription')}
          <strong>{name}</strong>
        </Text>
      </ConfirmModal>
    </>
  )
}
