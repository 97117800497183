import { ComponentStyleConfig } from '@chakra-ui/react'

export const Link: ComponentStyleConfig = {
  baseStyle: {
    textStyle: 'h4',
    borderBottomWidth: '1px',
    borderBottomColor: 'transparent',
    _hover: {
      textDecoration: 'none',
      borderBottomColor: 'textMain',
    },
  },
  variants: {
    primary: {
      color: 'link',
    },
    secondary: {
      _hover: {
        color: 'link',
      },
    },
  },
}
