import i18n from 'locales/i18n'
import { DEFAULT_CURRENCY, DEFAULT_LANG } from 'utils/consts'
import { Price, Address } from 'types/common'
import dayjs from 'dayjs'

export const trimLeadingZeros = (value: string) => value.replace(/^0+(?!$)/, '')

export const formatCurrency = (price: Price, currency: 'PLN' = DEFAULT_CURRENCY) => {
  if (isNaN(price)) {
    return 'N/A'
  }

  return new Intl.NumberFormat(i18n?.language || DEFAULT_LANG, {
    currency,
    style: 'currency',
  }).format(price)
}

export const formatAddress = (address: Address) => {
  const { street, number, apartment, postalCode, city } = address

  return `${street} ${number}${apartment ? `/${apartment}` : ''}, ${postalCode} ${city}`
}

export const formatDate = (dateAsString: string) => {
  const date = new Date(dateAsString)

  return dayjs(date).format('DD/MM/YYYY HH:mm')
}

export const formatTime = (timeInput: string) => {
  let hours: string
  let minutes: string

  if (timeInput.includes(':')) {
    ;[hours, minutes] = timeInput.split(':')
  } else if (timeInput.includes('.')) {
    ;[hours, minutes] = timeInput.split('.')
  } else {
    hours = timeInput.trim().substring(0, 2)
    minutes = timeInput.trim().substring(2, 4)
  }

  hours = parseInt(hours, 10).toString()
  minutes = parseInt(minutes || '0', 10).toString()

  if (hours !== 'NaN' && minutes !== 'NaN') {
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`
  }

  return null
}

export const replaceAll = (str: string, find: string, replace: string) => str.split(find).join(replace)
