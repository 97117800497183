import { useEffect, useMemo, useState } from 'react'
import { Flex, Grid, Box, Text } from '@chakra-ui/react'
import { useTable, usePagination, Column } from 'react-table'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useArtworks } from 'services/artworks/artworks.hooks'
import { BasicArtwork } from 'services/artworks/artworks.types'
import { Pagination } from 'components/pagination/Pagination'
import { DEFAULT_INITIAL_PAGE, DEFAULT_PAGE_SIZE, HEADER_HEIGHT } from 'utils/consts'
import { PriceCell } from 'components/tableCells/PriceCell'
import { LogoCell } from 'components/tableCells/LogoCell'
import { NameCell } from 'components/tableCells/NameCell'
import { ArtworkStatusCell } from './ArtworkStatusCell'

const gridStyles = {
  templateColumns: '80px 1fr 1fr minmax(160px, auto)',
  columnGap: '24px',
  px: '24px',
  alignItems: 'center',
  borderBottomWidth: '1px',
  borderColor: 'black',
}

const PAGE_SIZE = DEFAULT_PAGE_SIZE
const INITIAL_PAGE = DEFAULT_INITIAL_PAGE

export const ArtworksTable = () => {
  const { t } = useTranslation('artworks')
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE)
  const { data: artworksData } = useArtworks({
    offset: currentPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    sort: 'created_at_desc',
  })

  const navigate = useNavigate()

  const columns: Column<BasicArtwork>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'coverPhoto',
        Cell: LogoCell,
      },
      {
        Header: t('headers.name'),
        accessor: 'name',
        Cell: NameCell,
      },
      {
        Header: t('headers.status'),
        accessor: 'active',
        Cell: ArtworkStatusCell,
      },
      {
        Header: t('headers.price'),
        accessor: 'price',
        Cell: PriceCell,
      },
    ],
    [t]
  )

  const artworks = useMemo(() => artworksData?.data || [], [artworksData?.data])
  const total = artworksData?.meta.total || 0

  const pageCount = Math.ceil(total / PAGE_SIZE)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex },
  } = useTable(
    { columns, data: artworks, initialState: { pageIndex: currentPage }, pageCount, manualPagination: true },
    usePagination
  )

  useEffect(() => {
    setCurrentPage(pageIndex)
  }, [pageIndex])

  return (
    <Flex height='100%' direction='column' justifyContent='space-between'>
      <Flex direction='column' {...getTableProps()}>
        <Box position='sticky' top={HEADER_HEIGHT} bgColor='bg'>
          {headerGroups.map((headerGroup) => {
            const headerGroupProps = headerGroup.getHeaderGroupProps()

            return (
              <Grid {...headerGroupProps} key={headerGroupProps.key} {...gridStyles} py='12px'>
                {headerGroup.headers.map((column) => {
                  const headerProps = column.getHeaderProps()

                  return (
                    <Text {...headerProps} key={headerProps.key} textStyle='labelS' textTransform='uppercase'>
                      {column.render('Header')}
                    </Text>
                  )
                })}
              </Grid>
            )
          })}
        </Box>
        <Box {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const rowProps = row.getRowProps()

            return (
              <Grid
                {...rowProps}
                key={rowProps.key}
                {...gridStyles}
                py='32px'
                cursor='pointer'
                onClick={() => {
                  navigate(row.original.id)
                }}
              >
                {row.cells.map((cell) => {
                  const cellProps = cell.getCellProps()

                  return (
                    <Box {...cellProps} key={cellProps.key}>
                      {cell.render('Cell')}
                    </Box>
                  )
                })}
              </Grid>
            )
          })}
        </Box>
      </Flex>
      <Flex justifyContent='flex-end' width='100%' mt='24px'>
        <Pagination
          currentPage={currentPage}
          pageCount={pageCount}
          setPreviousPage={() => setCurrentPage(currentPage - 1)}
          previousEnabled={currentPage >= 1}
          setNextPage={() => setCurrentPage(currentPage + 1)}
          nextEnabled={currentPage + 1 < pageCount}
        />
      </Flex>
    </Flex>
  )
}
