import { Outlet, Route, Routes } from 'react-router-dom'

import { Orders } from 'pages/orders/Orders'
import { Order } from 'pages/order/Order'

export const ORDERS_BASE_ROUTE = '/zamowenia'

export const OrdersRouter = () => (
  <>
    <Routes>
      <Route index element={<Orders />} />
      <Route path=':id' element={<Order />} />
    </Routes>
    <Outlet />
  </>
)
