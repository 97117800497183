import { Center } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export const NoOptionsMessage = () => {
  const { t } = useTranslation(['common'])

  return (
    <Center h='100px' bgColor='bg' zIndex={1}>
      {t('common:noResult')}
    </Center>
  )
}
