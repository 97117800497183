import { CellProps } from 'react-table'
import { Text } from '@chakra-ui/react'
import { BasicOrder } from 'services/orders/orders.types'
import { useTranslation } from 'react-i18next'

export const StatusCell = <D extends object, V extends BasicOrder['status']>({ value }: CellProps<D, V>) => {
  const { t } = useTranslation('orders')

  const getStatusColor = (status: V) => {
    switch (status) {
      case 'paid':
      case 'completed':
      case 'readyForPickup':
      case 'sent':
        return 'green.300'
      case 'canceled':
        return 'error'
      case 'created':
        return 'orange.500'
      default:
        return 'textMain'
    }
  }

  return (
    <Text color={getStatusColor(value)} textStyle='h3'>
      {t(`status.${value}`)}
    </Text>
  )
}
