import { Outlet, Route, Routes } from 'react-router-dom'

import { Accounts } from 'pages/accounts/Accounts'
import { Account } from 'pages/accounts/account/Account'

export const ACCOUNTS_BASE_ROUTE = '/uzytkownicy'

export const AccountsRouter = () => (
  <>
    <Routes>
      <Route index element={<Accounts />} />
      <Route path=':id' element={<Account />} />
    </Routes>
    <Outlet />
  </>
)
