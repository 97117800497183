import { AxiosInstance } from 'axios'
import { CreateMessagePayload } from './messages.types'

export class MessagesService {
  private urlPrefix = '/messages'
  constructor(private fetchService: AxiosInstance) {}

  sendOne = ({ content, inquiryId }: CreateMessagePayload) =>
    this.fetchService.post<void>(this.urlPrefix, {
      content,
      inquiryId,
    })
}
