import { Box, Image } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { RecognitionDatasetEntry } from 'services/artworks/artworks.types'

type ImageWithBoundingBoxProps = {
  src: RecognitionDatasetEntry['imageUrl']
  imageSize: RecognitionDatasetEntry['imageSize']
  boundingBox: RecognitionDatasetEntry['annotations']
}

export const ImageWithBoundingBox = ({ src, imageSize, boundingBox }: ImageWithBoundingBoxProps) => {
  const [boundingBoxRatioWidth, setBoundingBoxRatioWidth] = useState(0)
  const [boundingBoxRatioHeigth, setBoundingBoxRatioHeigth] = useState(0)
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)

  const containerWidth = containerRef.current?.clientWidth
  const containerHeight = containerRef.current?.clientHeight

  useEffect(() => {
    if (containerWidth && containerHeight) {
      setBoundingBoxRatioWidth(containerWidth / imageSize[0].height)
      setBoundingBoxRatioHeigth(containerHeight / imageSize[0].width)
    }
  }, [containerWidth, containerHeight, imageSize, isImageLoaded])

  return (
    <Box pos='relative' maxW='100%' h='auto' sx={{ aspectRatio: 0 }} ref={containerRef}>
      <Image src={src} w='100%' onLoad={() => setIsImageLoaded(true)} />
      {boundingBox.map((box) => {
        const boundingBoxHeight = `${(box.width / imageSize[0].width) * 100}%`
        const boundingBoxWidth = `${(box.height / imageSize[0].height) * 100}%`
        const bouyndingBoxPositionLeft = `${boundingBoxRatioWidth * box.top}px`
        const bouyndingBoxPositionTop = `${boundingBoxRatioHeigth * box.left}px`

        return (
          <Box
            key={boundingBoxHeight + boundingBoxWidth}
            position='absolute'
            right={bouyndingBoxPositionLeft}
            top={bouyndingBoxPositionTop}
            outline='4px solid'
            borderColor='borderDark'
            backgroundColor='bgGrey'
            opacity='0.5'
            height={boundingBoxHeight}
            width={boundingBoxWidth}
          />
        )
      })}
    </Box>
  )
}
