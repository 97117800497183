import { useTranslation } from 'react-i18next'
import { Box, Image } from '@chakra-ui/react'
import { useState } from 'react'
import { Button } from 'components/button/Button'
import { IconX } from 'components/icons'
import { ConfirmModal } from 'components/confirmModal/ConfirmModal'

type PreviewTileProps = {
  previewSrc: string
  onDelete: () => void
}

export const PreviewTile = ({ previewSrc, onDelete }: PreviewTileProps) => {
  const { t } = useTranslation('common')

  const [isModalOpen, setModalOpen] = useState(false)

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <Box position='relative' display='flex'>
      <Image src={previewSrc} objectFit='cover' flexGrow={1} />
      <Box
        position='absolute'
        top={0}
        right={0}
        bottom={0}
        left={0}
        bgColor='bgDark'
        transitionDuration='slow'
        opacity={0}
        _hover={{
          opacity: 0.8,
        }}
      >
        <Button
          variant='ghost'
          color='textLight'
          _hover={{ color: 'textLight' }}
          rightIcon={<IconX />}
          aria-label={t('deleteImage')}
          onClick={() => setModalOpen(true)}
        />
      </Box>
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={closeModal}
        confirmButtonLabel={t('deletePhotos.confirmDelete')}
        onConfirm={onDelete}
        title={t('deletePhotos.confirmDeleteMessage')}
      >
        <Image
          alignSelf='flex-start'
          mb='8px'
          width='120px'
          height='120px'
          src={previewSrc}
          objectFit='cover'
          flexGrow={1}
        />
      </ConfirmModal>
    </Box>
  )
}
