import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import { FieldError } from 'react-hook-form'

import { VisuallyHidden } from 'components/visuallyHidden/VisuallyHidden'

export interface InputComponentProps extends FormControlProps {
  leftIcon?: React.ReactElement
  rightElement?: React.ReactElement
  error?: FieldError
  // Required for a11y reasons, if it's not on the designs use with `hasVisuallyHiddenLabel` prop.
  label: string
  hasVisuallyHiddenLabel?: boolean
  helperText?: string
  inputProps?: ChakraInputProps
  // pass a placeholder in inputProps instead
  placeholder?: never
}

export const Input = forwardRef<HTMLInputElement, InputComponentProps>(
  (
    {
      label,
      helperText,
      isInvalid,
      isDisabled,
      size,
      variant,
      error,
      leftIcon,
      rightElement,
      inputProps,
      hasVisuallyHiddenLabel = false,
      ...rest
    },
    ref
  ) => {
    const invalid = !!error || isInvalid
    const isErrorMessageHidden = error?.type === 'hidden'
    const hasErrorMessage = error?.message && !isErrorMessageHidden

    return (
      <FormControl isInvalid={invalid} isDisabled={isDisabled} position='relative' id={inputProps?.id} {...rest}>
        {label && (
          <VisuallyHidden enabled={hasVisuallyHiddenLabel}>
            <FormLabel variant='textInput' size={size}>
              {label}
            </FormLabel>
          </VisuallyHidden>
        )}
        <InputGroup size={size} variant={variant}>
          {leftIcon && <InputLeftElement pointerEvents='none'>{leftIcon}</InputLeftElement>}
          <ChakraInput ref={ref} {...inputProps} />
          {rightElement && <InputRightElement>{rightElement}</InputRightElement>}
        </InputGroup>
        {(hasErrorMessage || helperText) && (
          <HStack spacing='8px' alignItems='flex-start' mt='8px'>
            {hasErrorMessage && (
              <FormErrorMessage variant='textInput' size={size} flex='1' display='box'>
                {error.message}
              </FormErrorMessage>
            )}
            {helperText && (
              <FormHelperText width='100%' textAlign='right' flex='1'>
                {helperText}
              </FormHelperText>
            )}
          </HStack>
        )}
      </FormControl>
    )
  }
)
