import { useTranslation } from 'react-i18next'
import { PageTemplate } from 'components/PageTemplate'
import { Outlet } from 'react-router-dom'
import { OrdersTable } from './ordersTable/OrdersTable'

export const Orders = () => {
  const { t } = useTranslation('common')

  return (
    <>
      <PageTemplate title={t('navigation.orders')}>
        <OrdersTable />
      </PageTemplate>
      <Outlet />
    </>
  )
}
