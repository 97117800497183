import { Icon, IconProps } from '@chakra-ui/react'

const SvgIconX = (props: IconProps) => {
  return (
    // @ts-ignore Needed because of Emotion versions mismatch
    <Icon viewBox='0 0 24 24' height='24px' width='24px' data-testid='SvgIconX' {...props}>
      <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill='currentColor'
          stroke='currentColor'
          strokeWidth={0.499}
          d='m6.707 5.853 10.94 10.94-.353.353-10.94-10.94z'
        />
        <path fill='currentColor' d='M18 6.206 6.705 17.5 6 16.794 17.293 5.5z' />
      </svg>
    </Icon>
  )
}

export default SvgIconX
