import { Grid, GridProps, Text } from '@chakra-ui/react'

type InfoRowProps = {
  label: string
  value: string
} & GridProps

export const InfoRow = ({ label, value, ...props }: InfoRowProps) => {
  return (
    <Grid gridTemplateColumns='1fr auto' gap='20px' justify {...props}>
      <Text textStyle='textMain' color='textDescription'>
        {label}
      </Text>
      <Text textAlign='right' textStyle='textMain' whiteSpace='pre-wrap'>
        {value}
      </Text>
    </Grid>
  )
}
