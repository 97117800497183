import { Flex, Link, Text } from '@chakra-ui/react'
import { QRCodeCanvas } from 'qrcode.react'
import { replaceAll } from 'utils/string/string.utils'

type QRCodeGenerationProps = {
  name: string
  value: string
  qrDescription: string
}

export const QRCodeGeneration = ({ name, value, qrDescription }: QRCodeGenerationProps) => {
  const canvas = document.getElementById('qr-code') as HTMLCanvasElement
  const pngUrl = canvas?.toDataURL('image/png').replace('image/png', 'image/octet-stream')
  const formattedFileName = `${replaceAll(name, ',', '').split(' ').join('-')}.png`

  return (
    <Link href={pngUrl} isExternal download={formattedFileName}>
      <Flex
        flexDirection='row'
        gap='24px'
        mt='64px'
        alignItems='flex-end'
        justifyContent='space-between'
        width='100%'
        border='1px'
        p='32px'
      >
        <Text textStyle='h3'>{qrDescription}</Text>
        <QRCodeCanvas value={value} id='qr-code' role='qr-code' size={256} />
      </Flex>
    </Link>
  )
}
