import { useMemo } from 'react'
import { Flex, Divider, Grid } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useValidationSchema } from 'hooks/useValidationSchema'
import { getErrorMessage, handleError } from 'utils/handleError.utils'

import { Venue } from 'services/galleries/galleries.types'
import { useCreateVenue, useUpdateVenue } from 'services/galleries/galleries.hooks'

import { Input } from 'components/input/Input'
import { Button } from 'components/button/Button'
import { OpeningHours } from './OpeningHours'
import { VenueFormValues, galleryVenuesValidationSchema, OpeningHoursObj } from './Gallery.utils'

type VenueFormProps = {
  venue: Venue | null
  onSubmitForm?: () => void
}

export const VenueForm = ({ venue, onSubmitForm }: VenueFormProps) => {
  const { t } = useTranslation(['gallery', 'common'])

  const { id: galleryId = '' } = useParams()

  const { mutate: createVenue } = useCreateVenue()
  const { mutate: updateVenue } = useUpdateVenue()

  const defaultValues = useMemo(() => {
    const { name, phoneNumber, email, address, openingHours = [] } = venue || {}

    const openingHoursObj = openingHours.reduce((acc, curr) => {
      acc[curr.day] = curr

      return acc
    }, {} as OpeningHoursObj)

    return {
      name: name || '',
      phoneNumber: phoneNumber || '',
      email: email || '',
      address: {
        street: address?.street || '',
        number: address?.number || '',
        apartment: address?.apartment || '',
        postalCode: address?.postalCode || '',
        city: address?.city || '',
        instructions: address?.instructions || '',
      },
      openingHours: openingHoursObj,
    }
  }, [venue])

  const schema = useValidationSchema(galleryVenuesValidationSchema)

  const formMethods = useForm<VenueFormValues>({ defaultValues, resolver: schema })
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods

  const onSubmit = (values: VenueFormValues) => {
    const venueId = venue?.id
    const { openingHours, ...restValues } = values

    const filteredOpeningHours = Object.values(openingHours).filter((openingDay) => openingDay.from && openingDay.to)

    if (venueId) {
      updateVenue(
        { galleryId, venue: { id: venueId, openingHours: filteredOpeningHours, ...restValues } },
        {
          onSuccess: () => onSubmitForm?.(),
          onError: (err) => {
            const errorMessage = getErrorMessage(err)

            if (errorMessage) {
              handleError(errorMessage)
            } else {
              handleError(t('common:errors.genericError'))
            }
          },
        }
      )
    } else {
      createVenue(
        { galleryId, venue: { openingHours: filteredOpeningHours, ...restValues } },
        {
          onSuccess: () => onSubmitForm?.(),
          onError: (err) => {
            const errorMessage = getErrorMessage(err)

            if (errorMessage) {
              handleError(errorMessage)
            } else {
              handleError(t('gallery:updateError'))
            }
          },
        }
      )
    }
  }

  return (
    <FormProvider {...formMethods}>
      <Flex as='form' onSubmit={handleSubmit(onSubmit)} direction='column'>
        <Input
          mb='20px'
          error={errors.name}
          label={t('gallery:venues.name.label')}
          inputProps={{
            placeholder: t('gallery:venues.name.placeholder'),
            ...register('name'),
          }}
        />
        <Input
          mb='20px'
          error={errors.phoneNumber}
          label={t('gallery:venues.phoneNumber.label')}
          inputProps={{
            placeholder: t('gallery:venues.phoneNumber.placeholder'),
            ...register('phoneNumber'),
          }}
        />
        <Input
          mb='20px'
          error={errors.email}
          label={t('gallery:venues.email.label')}
          inputProps={{
            placeholder: t('gallery:venues.email.placeholder'),
            ...register('email'),
          }}
        />
        <Divider mt='24px' mb='32px' />
        <Grid templateColumns='repeat(4, 1fr)' columnGap='8px'>
          <Input
            gridColumn='1 / span 2'
            mb='20px'
            error={errors.address?.street}
            label={t('gallery:venues.street.label')}
            inputProps={{
              placeholder: t('gallery:venues.street.placeholder'),
              ...register('address.street'),
            }}
          />
          <Input
            mb='20px'
            error={errors.address?.number}
            label={t('gallery:venues.number.label')}
            inputProps={{
              placeholder: t('gallery:venues.number.placeholder'),
              ...register('address.number'),
            }}
          />
          <Input
            mb='20px'
            error={errors.address?.apartment}
            label={t('gallery:venues.apartment.label')}
            inputProps={{
              placeholder: t('gallery:venues.apartment.placeholder'),
              ...register('address.apartment'),
            }}
          />
          <Input
            mb='20px'
            error={errors.address?.postalCode}
            label={t('gallery:venues.postalCode.label')}
            inputProps={{
              placeholder: t('gallery:venues.postalCode.placeholder'),
              ...register('address.postalCode'),
            }}
          />
          <Input
            gridColumn='2 / span 3'
            mb='20px'
            error={errors.address?.city}
            label={t('gallery:venues.city.label')}
            inputProps={{
              placeholder: t('gallery:venues.city.placeholder'),
              ...register('address.city'),
            }}
          />
          <Input
            gridColumn='1 / span 4'
            mb='20px'
            error={errors.address?.instructions}
            label={t('gallery:venues.instructions.label')}
            inputProps={{
              placeholder: t('gallery:venues.instructions.placeholder'),
              ...register('address.instructions'),
            }}
          />
        </Grid>
        <Divider mt='24px' mb='32px' />
        <OpeningHours
          // @ts-ignore Because of react-hook-form weird typing
          error={errors.openingHours && 'message' in errors.openingHours ? errors.openingHours.message : undefined}
        />
        <Divider mt='24px' mb='32px' />
        <Button type='submit' isLoading={isSubmitting}>
          {t('common:save')}
        </Button>
      </Flex>
    </FormProvider>
  )
}
