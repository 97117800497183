import { AxiosInstance } from 'axios'

import { GetAccountsResponse, GetAccountParams, GetAccountResponse, GetAccountsRequestParams } from './accounts.types'

export class AccountsService {
  private urlPrefix = '/accounts'
  constructor(private fetchService: AxiosInstance) {}

  getAll = async (params?: GetAccountsRequestParams) => {
    const { data } = await this.fetchService.get<GetAccountsResponse>(this.urlPrefix, { params })

    return data
  }

  getOne = async (id: GetAccountParams['id']) => {
    const { data } = await this.fetchService.get<GetAccountResponse>(`${this.urlPrefix}/${id}`)

    return data
  }
}
