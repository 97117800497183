import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useValidationSchema } from 'hooks/useValidationSchema'
import { Center, Flex, Spinner } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Input } from 'components/input/Input'
import { Button } from 'components/button/Button'
import { useCreateUpsell, useGalleries } from 'services/galleries/galleries.hooks'
import { useNavigate } from 'react-router-dom'
import { handleSuccess } from 'utils/handleSuccess.utlis'
import { Select } from 'components/select/Select'
import { NoOptionsMessage } from 'components/select/NoOptionsMessage'
import { debounce } from 'lodash'
import { CreateUpsellFormValues, createUpsellValidationSchema, defaultValues } from './CreateUpsellForm.utils'

export const CreateUpsellForm = () => {
  const { t } = useTranslation(['common', 'upsell'])

  const [searchInputValue, setSearchInputValue] = useState('')

  const { mutate: createUpsell } = useCreateUpsell()
  const navigate = useNavigate()
  const schema = useValidationSchema(createUpsellValidationSchema)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CreateUpsellFormValues>({ defaultValues, resolver: schema })

  const { data: galleriesData, isLoading, isFetching } = useGalleries({ search: searchInputValue })

  const onSubmit = (values: CreateUpsellFormValues) => {
    const parentGalleryQueryParam = `?galleryId=${values.galleryId}`

    createUpsell(values, {
      onSuccess: ({ data }) => {
        handleSuccess(t('upsell:createSuccessMessage', { name: data.name }))
        navigate({ pathname: data.id, search: parentGalleryQueryParam })
      },
    })
  }

  const gallerySelectOptions = useMemo(
    () =>
      galleriesData?.data.map(({ name, id }) => {
        return { label: name, value: id }
      }),
    [galleriesData?.data]
  )

  const debouncedInputChange = useCallback(debounce(setSearchInputValue, 500), [])

  if (isLoading)
    return (
      <Center>
        <Spinner size='xl' />
      </Center>
    )

  if (!gallerySelectOptions) return null

  return (
    <Flex as='form' onSubmit={handleSubmit(onSubmit)} direction='column'>
      <Input
        mb='20px'
        error={errors.name}
        label={t('upsell:details.name.label')}
        inputProps={{
          placeholder: t('upsell:details.name.placeholder'),
          ...register('name'),
        }}
      />
      <Controller
        control={control}
        defaultValue=''
        name='galleryId'
        render={({ fieldState: { error }, field: { onChange, value: currInputValue } }) => (
          <Select
            label={t('upsell:relatedGallery')}
            options={gallerySelectOptions}
            value={gallerySelectOptions.find((el) => el.value === currInputValue)}
            onChange={(newValue) => onChange(newValue?.value)}
            onInputChange={debouncedInputChange}
            placeholder={t('upsell:selectRelatedGallery')}
            error={error}
            components={{ NoOptionsMessage }}
            isLoading={isFetching}
            formControlProps={{ mb: '20px' }}
          />
        )}
      />
      <Button type='submit' isLoading={isSubmitting}>
        {t('common:save')}
      </Button>
    </Flex>
  )
}
