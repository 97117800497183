import { Box, Flex } from '@chakra-ui/react'
import { Button } from 'components/button/Button'
import { Textarea } from 'components/textarea/Textarea'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

type ChatInputProps = { onSubmit: (value: string) => void }

export const ChatInput = ({ onSubmit }: ChatInputProps) => {
  const [value, setValue] = useState('')
  const { t } = useTranslation('inquiry')

  const submitMessage = (val: string) => {
    onSubmit(val)
    setValue('')
  }

  return (
    <Box w='100%'>
      <Flex mt='5px' gap='20px' direction='column'>
        <Textarea
          id='message'
          label={t('chat.label')}
          hasVisuallyHiddenLabel
          inputProps={{
            placeholder: t('chat.placeholder'),
            value,
            onChange: (e) => {
              setValue(e.target.value)
            },
          }}
          onKeyPress={(e) => {
            if (!e.shiftKey && e.key === 'Enter') {
              e.preventDefault()

              if (value.trim()) {
                submitMessage(value)
              }
            }
          }}
        />
        <Button
          type='submit'
          alignSelf='flex-end'
          disabled={!value.trim()}
          onClick={() => {
            submitMessage(value)
          }}
        >
          {t('chat.send')}
        </Button>
      </Flex>
    </Box>
  )
}
