import { Outlet } from 'react-router-dom'
import { useUser } from 'services/auth/auth.hooks'

export const RequireAuth = () => {
  const { data: userData, isLoading, isError } = useUser({ retry: false })

  if (isLoading) return null

  if (!userData || isError) {
    if (process.env.REACT_APP_BASE_APP_URL) window.location.href = process.env.REACT_APP_BASE_APP_URL

    return null
  }

  return <Outlet />
}
