import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query'

import { AuthService } from 'services/auth/auth.service'
import { FetchService } from 'services/fetch/fetch.service'

export const authQueryKeys = {
  user: ['user'] as const,
}

const authService = new AuthService(FetchService)

export const useUser = (options: Pick<UseQueryOptions, 'retry'>) =>
  useQuery(authQueryKeys.user, authService.getUser, options)

export const useLogout = () => {
  const queryClient = useQueryClient()

  return useMutation(authService.logout, {
    onSuccess: () => {
      queryClient.clear()
    },
  })
}
