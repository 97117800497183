import { useQuery, UseQueryOptions } from 'react-query'

import { FetchService } from 'services/fetch/fetch.service'
import { AccountsService } from './accounts.service'
import { GetAccountParams, GetAccountsRequestParams, GetAccountsResponse } from './accounts.types'

const accountsService = new AccountsService(FetchService)

export const accountsQueryKeys = {
  all: ['accounts'] as const,
  one: (id: GetAccountParams['id']) => [...accountsQueryKeys.all, id],
  filtered: (params?: GetAccountsRequestParams) => [...accountsQueryKeys.all, params || ''],
}

export const useAccounts = (
  params: GetAccountsRequestParams,
  options: Pick<UseQueryOptions<GetAccountsResponse, GetAccountsRequestParams>, 'enabled' | 'keepPreviousData'> = {
    enabled: true,
    keepPreviousData: true,
  }
) => useQuery(accountsQueryKeys.filtered(params), () => accountsService.getAll(params), options)

export const useAccount = (id: GetAccountParams['id']) =>
  useQuery(accountsQueryKeys.one(id), () => accountsService.getOne(id))
