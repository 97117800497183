export const globalStyles = {
  'html, body, #root': {
    height: '100%',
  },
  ':focus:not(.focus-visible) + span': {
    boxShadow: 'none',
  },
  body: {
    bgColor: 'bg',
    color: 'textMain',
    _dark: {
      bgColor: '#33334d',
    },
  },
}
