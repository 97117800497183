import { AxiosInstance } from 'axios'

import {
  CreateArtworkImagePayload,
  CreateArtworkImageResponse,
  DeleteArtworkImagePayload,
  GetArtworkImagesParams,
  GetArtworkImagesResponse,
  GetArtworkParams,
  GetArtworkResponse,
  GetArtworksRequestParams,
  GetArtworksResponse,
  UpdateArtworkActiveStatus,
  GetPaginatedArtworksRequestParams,
  UpdateArtworkPayload,
  GetArtworkRecognitionDatasetParams,
  GetArtworkRecognitionDatasetResponse,
} from 'services/artworks/artworks.types'
import { CreateArtworkPayload, CreateArtworkResponse } from './artworks.types'

export class ArtworksService {
  private urlPrefix = '/artworks'
  constructor(private fetchService: AxiosInstance) {}

  getAll = async (params?: GetArtworksRequestParams) => {
    const { data } = await this.fetchService.get<GetArtworksResponse>(this.urlPrefix, { params })

    return data
  }

  getAllPaginated = async (params?: GetPaginatedArtworksRequestParams) => {
    const { data } = await this.fetchService.get<GetArtworksResponse>(this.urlPrefix, { params })

    return data
  }

  getOne = async ({ slug }: GetArtworkParams) => {
    const { data } = await this.fetchService.get<GetArtworkResponse>(`${this.urlPrefix}/${slug}`)

    return data
  }

  update = async (payload: UpdateArtworkPayload) => {
    const { data } = await this.fetchService.patch<GetArtworkResponse>(`${this.urlPrefix}/${payload.id}`, payload)

    return data
  }

  create = async (payload: CreateArtworkPayload) => {
    const { data } = await this.fetchService.post<CreateArtworkResponse>(this.urlPrefix, payload)

    return data
  }

  getAllImages = async ({ slug }: GetArtworkImagesParams) => {
    const { data } = await this.fetchService.get<GetArtworkImagesResponse>(`${this.urlPrefix}/${slug}/images`)

    return data
  }

  createImage = async ({ slug, file, position }: CreateArtworkImagePayload) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('position', `${position}`)

    await this.fetchService.post<CreateArtworkImageResponse>(`${this.urlPrefix}/${slug}/images`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  }

  deleteImage = async ({ slug, imageId }: DeleteArtworkImagePayload) => {
    await this.fetchService.delete(`${this.urlPrefix}/${slug}/images/${imageId}`)
  }

  updateActiveStatus = async ({ slug, active }: UpdateArtworkActiveStatus) => {
    await this.fetchService.patch(`${this.urlPrefix}/${slug}`, { active })
  }

  getOneRecognitionDataset = async ({ slug }: GetArtworkRecognitionDatasetParams) => {
    const { data } = await this.fetchService.get<GetArtworkRecognitionDatasetResponse>(
      `${this.urlPrefix}/${slug}/dataset`
    )

    return data
  }
}
