import { Grid } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { PageTemplate } from 'components/PageTemplate'
import { useArtist } from 'services/artists/artists.hooks'
import { ArtistDetailForm } from './ArtistDetailForm'

export const Artist = () => {
  const { t } = useTranslation(['artist', 'common'])
  const { id } = useParams()

  const { data: artistData } = useArtist(id || '')

  if (!artistData) return null

  return (
    <PageTemplate title={t('artist:heading')}>
      <Grid templateColumns='1fr 1fr' columnGap='60px' py='58px'>
        <div>
          <ArtistDetailForm artist={artistData.data} />
        </div>
      </Grid>
    </PageTemplate>
  )
}
