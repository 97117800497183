import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { Layout } from 'components/Layout'
import { RequireAuth } from 'routing/RequireAuth'
import { routes } from 'routing/routing.utils'
import { GalleriesRouter } from 'routing/GalleriesRouter'
import { ArtworksRouter } from 'routing/ArtworksRouter'
import { AccountsRouter } from 'routing/AccountsRouter'
import { InquiriesRouter } from './InquiriesRouter'
import { OrdersRouter } from './OrdersRouter'
import { ArtistsRouter } from './ArtistsRouter'
import { UpsellsRouter } from './UpsellsRouter'

const homeRoute = routes.galleries.path

export const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<RequireAuth />}>
        <Route element={<Layout />}>
          <Route path={`${routes.galleries.path}/*`} element={<GalleriesRouter />} />
          <Route path={`${routes.artworks.path}/*`} element={<ArtworksRouter />} />
          <Route path={`${routes.artists.path}/*`} element={<ArtistsRouter />} />
          <Route path={`${routes.accounts.path}/*`} element={<AccountsRouter />} />
          <Route path={`${routes.inquiries.path}/*`} element={<InquiriesRouter />} />
          <Route path={`${routes.orders.path}/*`} element={<OrdersRouter />} />
          <Route path={`${routes.upsells.path}/*`} element={<UpsellsRouter />} />
          {/* Redirect to index page if path does not exist */}
          <Route path='*' element={<Navigate to={homeRoute} replace />} />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
)
