import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { PageTemplate } from 'components/PageTemplate'

import { AccountsTable } from './accountsTable/AccountsTable'

export const Accounts = () => {
  const { t } = useTranslation('common')

  return (
    <>
      <PageTemplate title={t('navigation.accounts')}>
        <AccountsTable />
      </PageTemplate>
      <Outlet />
    </>
  )
}
