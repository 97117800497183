import { useEffect, useMemo, useState } from 'react'
import { Flex, Grid, Box, Text } from '@chakra-ui/react'
import { useTable, usePagination, Column } from 'react-table'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Pagination } from 'components/pagination/Pagination'
import { DEFAULT_INITIAL_PAGE, DEFAULT_PAGE_SIZE, HEADER_HEIGHT } from 'utils/consts'
import { BasicOrder } from 'services/orders/orders.types'

import { DateCell } from 'components/tableCells/DateCell'
import { PriceCell } from 'components/tableCells/PriceCell'
import { useOrders } from 'services/orders/orders.hooks'
import { StatusCell } from './StatusCell'
import { OrderNumberCell } from './OrderNumberCell'

const gridStyles = {
  templateColumns: 'repeat(4, 1fr)',
  columnGap: '24px',
  px: '24px',
  alignItems: 'center',
  borderBottomWidth: '1px',
  borderColor: 'black',
}

const PAGE_SIZE = DEFAULT_PAGE_SIZE
const INITIAL_PAGE = DEFAULT_INITIAL_PAGE

export const OrdersTable = () => {
  const { t } = useTranslation('orders')
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE)
  const { data: ordersData } = useOrders({
    limit: PAGE_SIZE,
    offset: PAGE_SIZE * currentPage,
    sort: 'created_at_desc',
  })
  const navigate = useNavigate()

  const columns: Column<BasicOrder>[] = useMemo(
    () => [
      {
        Header: t('header.orderNumber'),
        accessor: 'orderNumber',
        Cell: OrderNumberCell,
      },
      {
        Header: t('header.createdAt'),
        accessor: 'createdAt',
        Cell: DateCell,
      },
      {
        Header: t('header.status'),
        accessor: 'status',
        Cell: StatusCell,
      },
      {
        Header: t('header.totalPrice'),
        accessor: 'totalPrice',
        Cell: PriceCell,
      },
    ],
    [t]
  )

  const orders = useMemo(() => ordersData?.data || [], [ordersData?.data])
  const total = ordersData?.meta.total || 0

  const pageCount = Math.ceil(total / PAGE_SIZE)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: orders,
      initialState: { pageIndex: currentPage },
      pageCount,
      manualPagination: true,
    },
    usePagination
  )

  useEffect(() => {
    setCurrentPage(pageIndex)
  }, [pageIndex])

  return (
    <Flex height='100%' direction='column' justifyContent='space-between'>
      <Flex direction='column' {...getTableProps()}>
        <Box position='sticky' top={HEADER_HEIGHT} bgColor='bg'>
          {headerGroups.map((headerGroup) => {
            const headerGroupProps = headerGroup.getHeaderGroupProps()

            return (
              <Grid {...headerGroupProps} key={headerGroupProps.key} {...gridStyles} py='12px'>
                {headerGroup.headers.map((column) => {
                  const headerProps = column.getHeaderProps()

                  return (
                    <Text {...headerProps} key={headerProps.key} textStyle='labelS' textTransform='uppercase'>
                      {column.render('Header')}
                    </Text>
                  )
                })}
              </Grid>
            )
          })}
        </Box>
        <Box {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const rowProps = row.getRowProps()

            return (
              <Grid
                {...rowProps}
                key={rowProps.key}
                {...gridStyles}
                py='32px'
                cursor='pointer'
                onClick={() => {
                  navigate(row.original.id)
                }}
              >
                {row.cells.map((cell) => {
                  const cellProps = cell.getCellProps()

                  return (
                    <Box {...cellProps} key={cellProps.key}>
                      {cell.render('Cell')}
                    </Box>
                  )
                })}
              </Grid>
            )
          })}
        </Box>
      </Flex>
      <Flex justifyContent='flex-end' width='100%' mt='24px'>
        <Pagination
          currentPage={currentPage}
          pageCount={pageCount}
          setPreviousPage={() => setCurrentPage(currentPage - 1)}
          previousEnabled={currentPage >= 1}
          setNextPage={() => setCurrentPage(currentPage + 1)}
          nextEnabled={currentPage + 1 < pageCount}
        />
      </Flex>
    </Flex>
  )
}
