import { useEffect, useMemo, useState } from 'react'
import { Flex, Grid, Box, Text } from '@chakra-ui/react'
import { useTable, usePagination, Column } from 'react-table'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Pagination } from 'components/pagination/Pagination'
import { BasicGallery, BasicUpsell } from 'services/galleries/galleries.types'
import { DEFAULT_PAGE_SIZE, HEADER_HEIGHT } from 'utils/consts'

import { LogoCell } from 'components/tableCells/LogoCell'
import { NameCell } from 'components/tableCells/NameCell'
import { PriceCell } from 'components/tableCells/PriceCell'
import { TextCell } from 'components/tableCells/TextCell'
import { useGalleryUpsells } from 'services/galleries/galleries.hooks'
import { UpsellStatusCell } from './UpsellStatusCell'

const gridStyles = {
  templateColumns: '80px repeat(4, 1fr)',
  columnGap: '24px',
  px: '24px',
  alignItems: 'center',
  borderBottomWidth: '1px',
  borderColor: 'black',
}

const PAGE_SIZE = DEFAULT_PAGE_SIZE
const INITIAL_PAGE = 0

type UpsellsTableProps = {
  galleryId: BasicGallery['id']
}

export const UpsellsTable = ({ galleryId }: UpsellsTableProps) => {
  const { t } = useTranslation('upsells')
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE)

  const { data: upsellsData } = useGalleryUpsells({
    slug: galleryId,
    limit: PAGE_SIZE,
    offset: currentPage * PAGE_SIZE,
    sort: 'created_at_desc',
  })

  const navigate = useNavigate()

  const columns: Column<BasicUpsell>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'image',
        Cell: LogoCell,
      },
      {
        Header: t('headers.name'),
        accessor: 'name',
        Cell: NameCell,
      },
      {
        Header: t('headers.category'),
        accessor: 'category',
        Cell: TextCell,
      },
      {
        Header: t('headers.status'),
        accessor: 'active',
        Cell: UpsellStatusCell,
      },
      {
        Header: t('headers.price'),
        accessor: 'price',
        Cell: PriceCell,
      },
    ],
    [t]
  )

  const upsells = useMemo(() => upsellsData?.data || [], [upsellsData?.data])

  const total = upsellsData?.meta.total || 0

  const pageCount = Math.ceil(total / PAGE_SIZE)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex },
  } = useTable<BasicUpsell>(
    { columns, data: upsells, initialState: { pageIndex: currentPage }, pageCount, manualPagination: true },
    usePagination
  )

  useEffect(() => {
    setCurrentPage(pageIndex)
  }, [pageIndex])

  return (
    <Flex height='100%' direction='column' justifyContent='space-between' flexGrow={1}>
      <Flex direction='column' {...getTableProps()}>
        <Box position='sticky' top={HEADER_HEIGHT} bgColor='bg'>
          {headerGroups.map((headerGroup) => {
            const headerGroupProps = headerGroup.getHeaderGroupProps()

            return (
              <Grid {...headerGroupProps} key={headerGroupProps.key} {...gridStyles} py='12px'>
                {headerGroup.headers.map((column) => {
                  const headerProps = column.getHeaderProps()

                  return (
                    <Text {...headerProps} key={headerProps.key} textStyle='labelS' textTransform='uppercase'>
                      {column.render('Header')}
                    </Text>
                  )
                })}
              </Grid>
            )
          })}
        </Box>
        <Box {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const rowProps = row.getRowProps()

            return (
              <Grid
                {...rowProps}
                key={rowProps.key}
                {...gridStyles}
                py='32px'
                cursor='pointer'
                onClick={() => {
                  navigate({ pathname: row.original.id, search: `?galleryId=${galleryId}` })
                }}
              >
                {row.cells.map((cell) => {
                  const cellProps = cell.getCellProps()

                  return (
                    <Box {...cellProps} key={cellProps.key}>
                      {cell.render('Cell')}
                    </Box>
                  )
                })}
              </Grid>
            )
          })}
        </Box>
      </Flex>
      <Flex justifyContent='flex-end' width='100%' mt='24px'>
        <Pagination
          currentPage={currentPage}
          pageCount={pageCount}
          setPreviousPage={() => setCurrentPage(currentPage - 1)}
          previousEnabled={currentPage >= 1}
          setNextPage={() => setCurrentPage(currentPage + 1)}
          nextEnabled={currentPage + 1 < pageCount}
        />
      </Flex>
    </Flex>
  )
}
