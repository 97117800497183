import { Icon, IconProps } from '@chakra-ui/react'

const SvgCopy = (props: IconProps) => {
  return (
    // @ts-ignore Needed because of Emotion versions mismatch
    <Icon viewBox='0 0 24 24' height='24px' width='24px' data-testid='SvgEnvelope' {...props}>
      <svg width={16} height={16} version='1.1' viewBox='0 0 1200 1200' xmlns='http://www.w3.org/2000/svg'>
        <g>
          <path d='m866.77 0h-827.08v978.46h169.85v-865.84h657.23z' />
          <path d='m333.23 221.54v978.46h827.08v-978.46zm631.39 727.38h-435.7v-101.54h435.69zm0-249.23h-435.7v-101.54h435.69z' />
        </g>
      </svg>
    </Icon>
  )
}

export default SvgCopy
