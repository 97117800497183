import { FormControl, FormControlProps, FormErrorMessage, FormLabel, HStack } from '@chakra-ui/react'
import { VisuallyHidden } from 'components/visuallyHidden/VisuallyHidden'
import { FieldError } from 'react-hook-form'
import {
  Select as ChakraSelect,
  CreatableSelect as ChakraCreatableSelect,
  ChakraStylesConfig,
  Props as ReactSelectProps,
  CreatableProps as CreatableReactSelectProps,
  GroupBase,
} from 'chakra-react-select'
import { NoOptionsMessage } from './NoOptionsMessage'

export type SelectComponentProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = {
  error?: FieldError
  // Required for a11y reasons, if it's not on the designs use with `hasVisuallyHiddenLabel` prop.
  label: string
  hasVisuallyHiddenLabel?: boolean
  placeholder?: string
  formControlProps?: Omit<FormControlProps, 'onChange'>
  isCreatable?: boolean
} & ReactSelectProps<Option, IsMulti, Group> &
  CreatableReactSelectProps<Option, IsMulti, Group>

export const Select = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  label,
  hasVisuallyHiddenLabel = false,
  error,
  size,
  formControlProps = {},
  isCreatable,
  ...selectProps
}: SelectComponentProps<Option, IsMulti, Group>) => {
  const invalid = selectProps.isInvalid || !!error?.message
  const Component = isCreatable ? ChakraCreatableSelect : ChakraSelect

  const chakraStyles: ChakraStylesConfig<Option, IsMulti, Group> = {
    control: (provided) => ({
      ...provided,
      borderRadius: '0px',
      paddingInline: '0px',
      fontSize: '14px',
      height: '48px',
      _disabled: {
        borderColor: 'borderGray',
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'textPlaceholder',
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: 'bg',
      borderRadius: '0px',
      border: '1px solid',
      borderColor: 'borderLight',
      padding: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      transition: 'color 0s',
      backgroundColor: state.isSelected ? 'black' : 'bg',
      _hover: { backgroundColor: 'black', color: 'bg' },
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      marginRight: 0,
    }),
  }

  return (
    <FormControl
      mb='20px'
      isInvalid={invalid}
      isDisabled={selectProps.isDisabled}
      position='relative'
      {...formControlProps}
    >
      {label && (
        <VisuallyHidden enabled={hasVisuallyHiddenLabel}>
          <FormLabel variant='textInput' size={size}>
            {label}
          </FormLabel>
        </VisuallyHidden>
      )}
      <Component
        {...selectProps}
        loadingMessage={() => null}
        chakraStyles={chakraStyles}
        components={{
          NoOptionsMessage,
          IndicatorSeparator: null,
          ...selectProps.components,
        }}
      />
      <HStack spacing='8px' alignItems='flex-start' mt='8px'>
        {error?.message && (
          <FormErrorMessage variant='textInput' size={size} flex='1' display='box'>
            {error.message}
          </FormErrorMessage>
        )}
      </HStack>
    </FormControl>
  )
}
