import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Select } from 'components/select/Select'

import { useValidationSchema } from 'hooks/useValidationSchema'
import { Input } from 'components/input/Input'
import { Button } from 'components/button/Button'
import { handleSuccess } from 'utils/handleSuccess.utlis'
import { useCreateArtwork } from 'services/artworks/artworks.hooks'
import { usePaginatedGalleries } from 'services/galleries/galleries.hooks'
import { debounce } from 'lodash'
import { DEFAULT_PAGE_SIZE } from 'utils/consts'
import { CreateArtworkFormValues, createArtworkValidationSchema, defaultValues } from './CreateArtworkForm.utils'

const PAGE_SIZE = DEFAULT_PAGE_SIZE

export const CreateArtworkForm = () => {
  const { t } = useTranslation(['common', 'artwork'])

  const [searchInputValue, setSearchInputValue] = useState('')

  const { mutate: createArtwork } = useCreateArtwork()
  const {
    data: galleriesData,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = usePaginatedGalleries({
    sort: 'name',
    search: searchInputValue,
    limit: PAGE_SIZE,
  })

  const galleriesOptions = useMemo(
    () =>
      galleriesData?.pages
        .map((page) =>
          page.data.map(({ name, id }) => {
            return { label: name, value: id }
          })
        )
        .flat(),
    [galleriesData?.pages]
  )

  const debouncedInputChange = useCallback(debounce(setSearchInputValue, 500), [])

  const schema = useValidationSchema(createArtworkValidationSchema)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CreateArtworkFormValues>({ defaultValues, resolver: schema })

  const navigate = useNavigate()
  const onSubmit = ({ gallery, name }: CreateArtworkFormValues) => {
    createArtwork(
      { galleryId: gallery?.value || '', name },
      {
        onSuccess: ({ data }) => {
          handleSuccess(t('artwork:createSuccessMessage', { name: data.name }))
          navigate(data.id)
        },
      }
    )
  }

  const handleOnMenuScrollToBottom = () => {
    if (!isFetching && hasNextPage) fetchNextPage()
  }

  return (
    <Flex as='form' onSubmit={handleSubmit(onSubmit)} direction='column'>
      <Input
        mb='20px'
        error={errors.name}
        label={t('artwork:details.name.label')}
        inputProps={{
          placeholder: t('artwork:details.name.placeholder'),
          ...register('name'),
        }}
      />
      <Controller
        control={control}
        name='gallery'
        render={({ fieldState: { error }, field: { onChange, value } }) => (
          <Select
            isDisabled={isLoading || !galleriesOptions}
            isLoading={isFetching || isLoading}
            label={t('artwork:relatedGallery')}
            options={galleriesOptions}
            value={value}
            onChange={onChange}
            onInputChange={debouncedInputChange}
            placeholder={t('artwork:selectRelatedGallery')}
            error={error}
            onMenuScrollToBottom={handleOnMenuScrollToBottom}
            formControlProps={{
              mb: '20px',
            }}
          />
        )}
      />
      <Button type='submit' isLoading={isSubmitting}>
        {t('common:save')}
      </Button>
    </Flex>
  )
}
