import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/button/Button'
import { Modal, ModalProps } from 'components/modal/Modal'

type ConfirmModalProps = {
  onConfirm: () => void
  confirmButtonLabel?: string
} & ModalProps

export const ConfirmModal = ({
  children,
  onConfirm,
  onClose,
  isOpen,
  title,
  confirmButtonLabel,
  closeButtonAriaLabel,
}: ConfirmModalProps) => {
  const { t } = useTranslation('common')

  const confirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      closeButtonAriaLabel={closeButtonAriaLabel || t('confirmModal.closeModalButtonAria')}
    >
      <>
        {children}
        <Button alignSelf='flex-end' minWidth='120px' onClick={confirm}>
          {confirmButtonLabel || t('confirmModal.confirm')}
        </Button>
      </>
    </Modal>
  )
}
