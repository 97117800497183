import { useTranslation } from 'react-i18next'
import { Flex, Text } from '@chakra-ui/react'
import { Address } from 'types/common'
import { InfoRow } from 'components/InfoRow'
import { Account } from 'services/accounts/accounts.types'

type AccountDetailsSectionProps = {
  account: Omit<Account, 'accessToAdminPanel'> & {
    address: Address | null
  }
}

export const AccountDetailsSection = ({ account }: AccountDetailsSectionProps) => {
  const { t } = useTranslation(['account', 'common'])

  const { id, admin, email, name, address } = account

  return (
    <Flex direction='column'>
      <Text textStyle='h3' mb='32px'>
        {t('account:userDetails.heading')}
      </Text>
      <Flex flexDir='column' gap='8px'>
        <InfoRow label={t('account:userDetails.id')} value={id} />
        <InfoRow label={t('account:userDetails.name')} value={name} />
        <InfoRow label={t('account:userDetails.email')} value={email} />
        <InfoRow
          label={t('account:userDetails.admin')}
          value={admin ? t('account:userDetails.hasAccess.true') : t('account:userDetails.hasAccess.false')}
        />
        {address && (
          <InfoRow
            label={t('account:userDetails.address')}
            value={`${address.street} ${address.number}${address.apartment ? `/${address.apartment}` : ''}\n${
              address.postalCode
            }, ${address.city}`}
          />
        )}
      </Flex>
    </Flex>
  )
}
