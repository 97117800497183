import { AxiosInstance } from 'axios'
import { GetOrderParams, GetOrderResponse, GetOrdersRequestParams, GetOrdersResponse } from './orders.types'

export class OrdersService {
  private urlPrefix = '/orders'
  constructor(private fetchService: AxiosInstance) {}

  getAll = async (params?: GetOrdersRequestParams) => {
    const { data } = await this.fetchService.get<GetOrdersResponse>(this.urlPrefix, { params })

    return data
  }
  getOne = async ({ slug }: GetOrderParams) => {
    const { data } = await this.fetchService.get<GetOrderResponse>(`${this.urlPrefix}/${slug}`)

    return data
  }
}
