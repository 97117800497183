import { TFunction } from 'react-i18next'
import { object, string } from 'yup'
import { SelectOption } from 'types/common'

export const createArtworkValidationSchema = (t: TFunction) =>
  object({
    name: string().required(t('validation.required')),
    gallery: object({
      label: string(),
      value: string(),
    })
      .typeError(t('validation.required'))
      .required(t('validation.required')),
  })

export type CreateArtworkFormValues = {
  name: string
  gallery: SelectOption | null
}

export const defaultValues: CreateArtworkFormValues = {
  name: '',
  gallery: null,
}
