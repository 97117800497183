import { ReactNode } from 'react'
import { Box, chakra, Flex, FlexProps, Text } from '@chakra-ui/react'
import { HEADER_HEIGHT, TOP_BAR_HEIGHT } from 'utils/consts'

type PageTemplateProps = {
  title: string
  headerRightElement?: ReactNode
  topBar?: ReactNode
  children: ReactNode
} & FlexProps

export const PageTemplate = ({
  headerRightElement,
  title,
  topBar,
  children,
  as = 'main',
  ...props
}: PageTemplateProps) => (
  <Flex as={as} direction='column' flex='1' overflow='auto' px='64px' pb='64px' {...props}>
    <Box position='sticky' top={0} left={0} right={0} bgColor='bg' zIndex='10'>
      <chakra.header
        height={HEADER_HEIGHT}
        py='28px'
        borderBottomWidth='1px'
        borderBottomColor='separator'
        display='flex'
        justifyContent='space-between'
      >
        <Text as='h1' textStyle='h2'>
          {title}
        </Text>
        {headerRightElement && <Box>{headerRightElement}</Box>}
      </chakra.header>
      {topBar && <Box maxHeight={TOP_BAR_HEIGHT}>{topBar}</Box>}
    </Box>
    <Box flexGrow={1}>{children}</Box>
  </Flex>
)
