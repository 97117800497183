import { Flex } from '@chakra-ui/react'
import { Sidebar } from 'components/Sidebar'
import { Outlet } from 'react-router-dom'

export const Layout = () => (
  <Flex height='100%' maxH='100vh'>
    <Sidebar />
    <Flex className='page-content' height='100%' flex='1'>
      <Outlet />
    </Flex>
  </Flex>
)
