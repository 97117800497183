import { Icon, IconProps } from '@chakra-ui/react'

const SvgIconGallery = (props: IconProps) => {
  return (
    // @ts-ignore Needed because of Emotion versions mismatch
    <Icon viewBox='0 0 24 24' height='24px' width='24px' data-testid='SvgIconGallery' {...props}>
      <svg width={40} height={40} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path stroke='#000' d='M3.5 5.5h6v29h-6zM12.5 5.5h5v29h-5zM20.5 5.5h6v29h-6zM30.5 5.5h6v29h-6z' />
        <path stroke='#000' d='M.5.5h39v39H.5z' />
      </svg>
    </Icon>
  )
}

export default SvgIconGallery
