import { TFunction } from 'react-i18next'
import { Gallery } from 'services/galleries/galleries.types'
import { object, string } from 'yup'

export const createUpsellValidationSchema = (t: TFunction) =>
  object({
    name: string().required(t('validation.required')),
    galleryId: string().required(t('validation.required')),
  })

export type CreateUpsellFormValues = {
  name: string
  galleryId: Gallery['id']
}

export const defaultValues: CreateUpsellFormValues = {
  name: '',
  galleryId: '',
}
