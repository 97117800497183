import { ComponentStyleConfig } from '@chakra-ui/react'
import { textStyles } from './Text.theme'

export const FormLabel: ComponentStyleConfig = {
  defaultProps: {
    variant: 'textInput',
    size: 'lg',
  },
  sizes: {
    lg: {
      marginBottom: '8px',
      fontWeight: 600,
      ...textStyles.labelS,
    },
  },
  variants: {
    textInput: {
      _disabled: {
        color: 'textPlaceholder',
        opacity: 1,
      },
    },
  },
}
