import { AxiosInstance } from 'axios'
import {
  GetInquiriesResponse,
  GetInquiryResponse,
  GetInquiryParams,
  CreateReservationPayload,
  GetInquiriesRequestParams,
} from './inquiries.types'

export class InquiriesService {
  private urlPrefix = '/inquiries'
  constructor(private fetchService: AxiosInstance) {}

  getAll = async (params?: GetInquiriesRequestParams) => {
    const { data } = await this.fetchService.get<GetInquiriesResponse>(this.urlPrefix, { params })

    return data
  }
  getOne = async ({ slug }: GetInquiryParams) => {
    const { data } = await this.fetchService.get<GetInquiryResponse>(`${this.urlPrefix}/${slug}`)

    return data
  }
  createReservation = async ({ reservation, inquiryId }: CreateReservationPayload) => {
    await this.fetchService.post<void>(`${this.urlPrefix}/${inquiryId}/reservations`, {
      reservation,
    })
  }
}
