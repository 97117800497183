import { Modal as ChakraModal, Text, Flex, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { ReactNode } from 'react'
import { CloseButton } from 'components/CloseButton'

export type ModalProps = {
  isCloseable?: boolean
  isOpen: boolean
  onClose: () => void
  title: string
  closeButtonAriaLabel?: string
  children?: ReactNode
}

export const Modal = ({ children, isCloseable = true, isOpen, onClose, title, closeButtonAriaLabel }: ModalProps) => {
  const { t } = useTranslation('common')

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Flex justifyContent='space-between' alignItems='center' mb='32px'>
          <Text textStyle='h3'>{title}</Text>
          {isCloseable && (
            <CloseButton onClick={onClose} aria-label={closeButtonAriaLabel || t('closeModalButtonAriaLabel')} />
          )}
        </Flex>
        <ModalBody p='0' display='flex' flexDirection='column'>
          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  )
}
