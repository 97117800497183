import { Outlet, Route, Routes } from 'react-router-dom'

import { Artworks } from 'pages/artworks/Artworks'
import { Artwork } from 'pages/artwork/Artwork'

export const ARTWORKS_BASE_ROUTE = '/dziela'

export const ArtworksRouter = () => (
  <>
    <Routes>
      <Route index element={<Artworks />} />
      <Route path=':id' element={<Artwork />} />
    </Routes>
    <Outlet />
  </>
)
