import { AxiosInstance } from 'axios'
import {
  CreateArtistPayload,
  GetArtistParams,
  GetArtistResponse,
  GetArtistsRequestParams,
  GetArtistsResponse,
  UpdateArtistPayload,
} from './artists.types'

export class ArtistsService {
  private urlPrefix = '/artists'

  constructor(private fetchService: AxiosInstance) {}

  getAll = async (params?: GetArtistsRequestParams) => {
    const { data } = await this.fetchService.get<GetArtistsResponse>(this.urlPrefix, { params })

    return data
  }

  getOne = async ({ slug }: GetArtistParams) => {
    const { data } = await this.fetchService.get<GetArtistResponse>(`${this.urlPrefix}/${slug}`)

    return data
  }

  create = async (payload: CreateArtistPayload) => {
    const { data } = await this.fetchService.post<GetArtistResponse>(this.urlPrefix, payload)

    return data
  }

  update = async (payload: UpdateArtistPayload) => {
    const { data } = await this.fetchService.patch<GetArtistResponse>(`${this.urlPrefix}/${payload.id}`, payload)

    return data
  }
}
