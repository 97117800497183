import { ComponentStyleConfig } from '@chakra-ui/react'
import { textStyles } from './Text.theme'

export const Textarea: ComponentStyleConfig = {
  defaultProps: {
    variant: 'outline',
    size: 'md',
  },

  baseStyle: {
    transition: 'color .2s',
    color: 'textPlaceholder',
    _focusWithin: {
      color: 'textMain',
    },
    _placeholder: {
      ...textStyles.labelM,
      color: 'textPlaceholder',
    },
  },

  sizes: {
    md: {
      minH: '110px',
      px: '16px',
      borderRadius: 'none',
      ...textStyles.labelM,
    },
  },

  variants: {
    outline: {
      color: 'textMain',
      borderColor: 'borderLight',
      _hover: {
        borderColor: 'borderGray',
      },
      _focus: {
        boxShadow: 'none',
        borderColor: 'textMain',
      },
      _disabled: {
        opacity: 1,
        _invalid: {
          borderColor: 'borderLight',
        },
        _hover: {
          borderColor: 'borderLight',
        },
        borderColor: 'borderLight',
        _placeholder: {
          color: 'borderLight',
        },
      },
      _invalid: {
        borderColor: 'error',
        boxShadow: 'none',
        _focus: {
          borderColor: 'error',
        },
      },
    },
  },
}
