import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { decamelizeKeys, camelizeKeys } from 'humps'

import { AuthService } from 'services/auth/auth.service'
import { cookies } from 'utils/cookies.utils'

export const createRequestInterceptor = () => async (request: AxiosRequestConfig) => {
  const token = cookies.get(AuthService.ACCESS_TOKEN_COOKIE_NAME)

  const enhancedRequest = {
    ...request,
    headers: {
      ...request.headers,
      accept: 'application/json',
    },
  }

  if (token) {
    enhancedRequest.headers = {
      ...enhancedRequest.headers,
      [AuthService.AUTH_HEADER_NAME]: AuthService.getAuthHeader(token),
    }
  }

  if (request.headers && request.headers['content-type'] !== 'multipart/form-data') {
    enhancedRequest.data = decamelizeKeys(request.data)
  }

  return enhancedRequest
}

type CreateErrorInterceptor = {
  handleError?: (error: AxiosError) => PromiseRejectionEvent
  axiosInstance: AxiosInstance
}

export const createErrorInterceptor =
  ({ handleError, axiosInstance }: CreateErrorInterceptor) =>
  (error: AxiosError) => {
    const request = error.config

    // @ts-ignore
    if (request.skipAuthRefresh) {
      return error
    }

    if (error?.response?.status === 401) {
      // let's retry
      return axiosInstance(request)
    }

    handleError?.(error)
    throw error
  }

export const createResponseInterceptor = () => (response: AxiosResponse) => {
  const { data } = response

  if (data instanceof Object && Object.keys(data)) {
    response.data = camelizeKeys(data)
  }

  return response
}
