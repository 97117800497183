import { createStandaloneToast } from '@chakra-ui/react'

import i18n from 'locales/i18n'
import { theme } from 'theme/theme'

export const toast = createStandaloneToast({ theme })

// basic version; will be developed
export const handleSuccess = (successMessage?: string) => {
  toast({
    title: successMessage || i18n.t('common:success.genericError'),
    status: 'success',
    position: 'top',
  })
}
