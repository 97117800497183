import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Text, Box } from '@chakra-ui/react'
import { PageTemplate } from 'components/PageTemplate'
import { Button } from 'components/button/Button'
import { ConfirmModal } from 'components/confirmModal/ConfirmModal'
import { handleSuccess } from 'utils/handleSuccess.utlis'
import { getErrorMessage, handleError } from 'utils/handleError.utils'
import { useParams, useSearchParams } from 'react-router-dom'
import { useUpsell, useToggleUpsellStatus } from 'services/galleries/galleries.hooks'
import { ConfirmationMessage } from './ConfirmationMessage/ConfirmationMessage'
import { UpsellDetailsSection } from './UpsellDetailsSection'
import { UpsellImageSection } from './UpsellImageSection'

export const Upsell = () => {
  const { t } = useTranslation(['upsell', 'common'])

  const [isUpsellStatusConfirmationModalOpen, setIsUpsellStatusConfirmationModalOpen] = useState(false)
  const [isImageDeletionWarningModalOpen, setIsImageDeletionWarningModalOpen] = useState(false)

  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const galleryId = searchParams.get('galleryId') || ''

  const { data: upsell } = useUpsell({ slug: id || '', galleryId })
  const { mutate: updateStatus } = useToggleUpsellStatus()

  const updateActivityStatus = () => {
    if (!id || !upsell?.data) return
    updateStatus(
      { galleryId, slug: id, active: !upsell.data.active },
      {
        onSuccess: () => {
          handleSuccess(upsell.data.active ? t('upsell:onStatusChange.disabled') : t('upsell:onStatusChange.activated'))
        },
        onError: (err) => {
          const errorMessage = getErrorMessage(err)

          if (errorMessage) {
            handleError(errorMessage)
          } else {
            handleError(t('upsell:updateError'))
          }
        },
      }
    )
  }

  if (!upsell) return null

  return (
    <>
      <PageTemplate title={t('upsell:heading')}>
        <Grid templateColumns='1fr 1fr' columnGap='60px' py='58px'>
          <UpsellDetailsSection upsell={upsell.data} galleryId={galleryId} />
          <Box>
            <UpsellImageSection
              upsell={upsell.data}
              showLastImageWarningModal={setIsImageDeletionWarningModalOpen}
              galleryId={galleryId}
            />
            <Button mt='30px' onClick={() => setIsUpsellStatusConfirmationModalOpen(true)}>
              {upsell.data.active
                ? t('upsell:changeUpsellActivityStatus.disable')
                : t('upsell:changeUpsellActivityStatus.active')}
            </Button>
          </Box>
        </Grid>
      </PageTemplate>

      <ConfirmModal
        closeButtonAriaLabel={t('upsell:closeConfirmationUpsellModalAriaLabel')}
        isOpen={isUpsellStatusConfirmationModalOpen}
        onClose={() => setIsUpsellStatusConfirmationModalOpen(false)}
        title={t('upsell:activityStatus.title')}
        onConfirm={updateActivityStatus}
      >
        <ConfirmationMessage activeStatus={upsell.data.active} />
      </ConfirmModal>
      <ConfirmModal
        closeButtonAriaLabel={t('common:closeModalButtonAriaLabel')}
        isOpen={isImageDeletionWarningModalOpen}
        onClose={() => setIsImageDeletionWarningModalOpen(false)}
        title={t('upsell:lastImageInformation.title')}
        onConfirm={() => setIsImageDeletionWarningModalOpen(false)}
      >
        <Text>{t('upsell:lastImageInformation.description')}</Text>
      </ConfirmModal>
    </>
  )
}
