import { Text, Flex, Stack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { InfoRow } from 'components/InfoRow'

type InquirerDetailsSectionProps = {
  inquirerName: string
  phoneNumber: string
}

export const InquirerDetailsSection = ({ inquirerName, phoneNumber }: InquirerDetailsSectionProps) => {
  const { t } = useTranslation('inquiry')

  if (!inquirerName) return null

  return (
    <Flex flexDirection='column'>
      <Text textStyle='h3' mb='32px'>
        {t('inquirer.title')}
      </Text>
      <Stack spacing='8px'>
        <InfoRow label={t('inquirer.name')} value={inquirerName} />
        <InfoRow label={t('inquirer.phoneNumber')} value={phoneNumber} />
      </Stack>
    </Flex>
  )
}
