import { ButtonProps } from '@chakra-ui/react'

import { Button } from 'components/button/Button'
import { IconX } from 'components/icons'

type CloseButtonProps = ButtonProps & {
  'aria-label': string
}

export const CloseButton = ({ color = 'textMain', ...props }: CloseButtonProps) => (
  <Button
    leftIcon={<IconX />}
    variant='ghost'
    color={color}
    w='auto'
    minW='unset'
    h='auto'
    p='10px'
    m='-10px'
    {...props}
  />
)
