import { Stack, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { Reservation } from 'services/inquiries/inquiries.types'
import { DATE_FORMAT, HOURS_MINUTES_FORMAT } from 'utils/consts'
import { formatCurrency } from 'utils/string/string.utils'
import { InfoRow } from 'components/InfoRow'

type CurrentReservationProps = {
  reservation: Reservation
}

export const CurrentReservation = ({ reservation }: CurrentReservationProps) => {
  const { t } = useTranslation('inquiry')

  if (!reservation) return null

  return (
    <>
      <Text textStyle='h3' marginBottom='32px'>
        {t('reservation.currentReservation')}
      </Text>
      <Stack spacing='8px'>
        <InfoRow label={t('reservation.price')} value={formatCurrency(reservation.price)} />
        <InfoRow
          label={t('reservation.validUntil')}
          value={dayjs(reservation.validUntil).format(`${DATE_FORMAT} ${HOURS_MINUTES_FORMAT}`)}
        />
      </Stack>
    </>
  )
}
