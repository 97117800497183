import { useMemo } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { ObjectSchema } from 'yup'

export const useValidationSchema = (schema: (t: TFunction) => ObjectSchema<any, any, any, any>) => {
  const { t } = useTranslation('common')

  return useMemo(() => yupResolver(schema(t)), [t, schema])
}
