import { chakra, Flex, Image, Link as ChakraLink, List, ListItem } from '@chakra-ui/react'
import { isNavbarRoute, routes } from 'routing/routing.utils'
import { useTranslation } from 'react-i18next'
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom'
import { IconArrow } from 'components/icons'
import { useIsAdmin } from 'hooks/useIsAdmin'

const navigationLinks = Object.values(routes).filter(isNavbarRoute)

const NAV_LINK_LEFT_PADDING = '16px'

const SidebarLink = ({ children, to, ...props }: LinkProps) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: `${resolved.pathname}/*` })
  const matchedStyles = { backgroundColor: 'textMain', color: 'textLight' }

  return (
    <ChakraLink
      as={Link}
      to={to}
      borderBottomWidth={0}
      p={`8px ${NAV_LINK_LEFT_PADDING}`}
      display='flex'
      alignItems='center'
      borderRadius='4px'
      _hover={{
        ...matchedStyles,
        '& > svg': { opacity: 1 },
      }}
      {...(match && matchedStyles)}
      {...props}
    >
      {children}
      <IconArrow ml='auto' opacity={0} transform='rotate(-90deg)' />
    </ChakraLink>
  )
}

export const Sidebar = () => {
  const { isAdmin } = useIsAdmin()
  const { t } = useTranslation()

  return (
    <Flex
      as='header'
      direction='column'
      p='24px 8px 34px'
      position='relative'
      h='100%'
      w='192px'
      _after={{
        display: 'block',
        position: 'absolute',
        content: '""',
        top: '8px',
        bottom: '8px',
        right: 0,
        width: '1px',
        bgColor: 'black',
      }}
    >
      <chakra.a href={process.env.REACT_APP_BASE_APP_URL || '/'} mb='104px' pl={NAV_LINK_LEFT_PADDING}>
        <Image src='/logo.svg' />
      </chakra.a>
      <chakra.nav mb='auto'>
        <List>
          {navigationLinks.map((link) => {
            if (link.needAdminAccess && !isAdmin) {
              return null
            }

            return (
              <ListItem key={link.path} mb='8px'>
                <SidebarLink to={link.path}>{t(link.navigationTranslationKey)}</SidebarLink>
              </ListItem>
            )
          })}
        </List>
      </chakra.nav>
    </Flex>
  )
}
