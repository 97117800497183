import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import dayjs from 'dayjs'

import { Price } from 'types/common'
import { useValidationSchema } from 'hooks/useValidationSchema'
import { Input } from 'components/input/Input'
import { Button } from 'components/button/Button'
import { useCreateReservation } from 'services/inquiries/inquiries.hooks'
import { DatePickerInput } from 'components/dateTimePicker/DateTimePicker'
import { ReservationDetailsFormValues, reservationDetailsValidationSchema } from './Reservation.utils'

type CurrentReservationProps = {
  defaultPrice: Price
  inquiryId: string
}

export const CreatingReservation = ({ defaultPrice, inquiryId }: CurrentReservationProps) => {
  const { t } = useTranslation(['inquiry', 'common'])
  const { mutate: createReservation } = useCreateReservation()

  const defaultValues = useMemo(
    () => ({
      price: defaultPrice,
      validUntil: undefined,
    }),
    [defaultPrice]
  )

  const schema = useValidationSchema(reservationDetailsValidationSchema)

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ReservationDetailsFormValues>({ defaultValues, resolver: schema })

  const onSubmit = ({ validUntil, price }: ReservationDetailsFormValues) => {
    validUntil && createReservation({ reservation: { price, validUntil: dayjs(validUntil).format() }, inquiryId })
  }

  const isToday = dayjs().isSame(watch('validUntil'), 'day')

  return (
    <Flex flexDirection='column' gap='32px'>
      <Text textStyle='h3'>{t('inquiry:reservation.create')}</Text>
      <Flex as='form' direction='column' onSubmit={handleSubmit(onSubmit)}>
        <Input
          id='price'
          mb='20px'
          error={errors.price}
          label={t('inquiry:reservation.price')}
          inputProps={{
            ...register('price'),
          }}
        />
        <Controller
          name='validUntil'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <DatePickerInput
                inputProps={{
                  id: 'validUntil',
                  mb: '20px',
                  error: errors.validUntil,
                  label: t('inquiry:reservation.validUntil'),
                  isReadOnly: true,
                }}
                selected={value || null}
                onChange={onChange}
                minDate={new Date()}
                minTime={isToday ? dayjs().add(1, 'hour').toDate() : dayjs().hour(0).minute(0).toDate()}
                maxTime={dayjs().hour(23).toDate()}
              />
            )
          }}
        />
        <Button type='submit' alignSelf='flex-end' ml='8px' isLoading={isSubmitting}>
          {t('common:save')}
        </Button>
      </Flex>
    </Flex>
  )
}
