import { TFunction } from 'react-i18next'
import { BasicUpsell } from 'services/galleries/galleries.types'
import { number, object, string } from 'yup'

export const upsellFormValidationSchema = (t: TFunction) =>
  object({
    name: string().required(t('validation.required')),
    price: number().typeError(t('validation.invalidFormat')).required(t('validation.required')),
  })

export type UpsellFormValues = BasicUpsell
